import React, { Component } from "react";
import ApiAuth from "../auth";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiGlo from "global/ApiGlobal";
import { Link } from "react-router-dom";
import ContainerRightTable from "../components/Page/ContainerRight/ContainerRightTable";
import ShowError from "global/components/ShowError";
import BoxCenter from "./BoxCenter";
class Signup extends HFormUser {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            password: "",
            error: "",
            open: false,
            recaptcha: false,
            loading: true,
        };
    }

    handleChange = name => event => {
        this.setState({ error: "" });
        this.setState({ [name]: event.target.value });
    };
    componentDidMount() {
        this.setState({ loading: false });
    }


    clickSubmit = event => {
        event.preventDefault();


        ApiAuth.signout().then(data => {
            this.props.navigate("/")
            //this.setState({ redirectToReferer: true });
            //window.location.href = '/';
            //apiRuta.toRutaMenu(this,"Salir","/home",null)
            //history.push('/new-location')
            /*
            if (!data.ok) {
                this.setState({ redirectToReferer: false });
            } else {
                this.setState({ redirectToReferer: true });
            }*/
        });

    };


    render() {
        const { name, email, password, error, open, recaptcha } = this.state;
        if (this.state.loading) return (<div>pensando</div>)
        return (
            <BoxCenter titulo="Salir del Sistema">
                <form class>
                    <ShowError
                        msg={this.state.message}
                        err={this.state.error} />
                    <div className="h5 modal-title text-center mb-4">
                        <h4 className="mt-2">
                            <span>Hasta luego, que pase un buen día</span>
                        </h4>
                    </div>
                    <div  className="mb-3">
                        <a onClick={this.clickSubmit}
                             className="btn btn-primary w-100 py-8 mb-4 rounded-2">Salir del Sistema</a>
                    </div>
                </form>

            </BoxCenter>
        );
    }
}

export default WithNavigate(Signup);
