import React, { Component } from "react";

class ProfileFollow extends Component {
    constructor(props) {
        super(props);
        this.data = props
    }
    render() {
        return (<>
                <div className="d-sm-flex align-items-center justify-content-between mt-3 mb-4">
                    <h3 className="mb-3 mb-sm-0 fw-semibold d-flex align-items-center">Followers <span
                        className="badge text-bg-secondary fs-2 rounded-4 py-1 px-2 ms-2">20</span></h3>
                    <form className="position-relative">
                        <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh"
                            placeholder="Search Followers" />
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y text-dark ms-3"></i>
                    </form>
                </div>
                <div className="row">
                    <div className=" col-md-6 col-xl-4">
                        <div className="card">
                            <div className="card-body p-4 d-flex align-items-center gap-6 flex-wrap">
                                <img src="/FrWork/Avatar/user-1.jpg" alt="" className="rounded-circle" width="40"
                                    height="40" />
                                <div>
                                    <h5 className="fw-semibold mb-0">Betty Adams</h5>
                                    <span className="fs-2 d-flex align-items-center"><i
                                        className="ti ti-map-pin text-dark fs-3 me-1"></i>Sint
                                        Maarten</span>
                                </div>
                                <button className="btn btn-outline-primary py-1 px-2 ms-auto">Follow</button>
                            </div>
                        </div>
                    </div>
                    <div className=" col-md-6 col-xl-4">
                        <div className="card">
                            <div className="card-body p-4 d-flex align-items-center gap-6 flex-wrap">
                                <img src="/FrWork/Avatar/user-3.jpg" alt="" className="rounded-circle" width="40"
                                    height="40" />
                                <div>
                                    <h5 className="fw-semibold mb-0">Birdie</h5>
                                    <span className="fs-2 d-flex align-items-center"><i
                                        className="ti ti-map-pin text-dark fs-3 me-1"></i>Algeria</span>
                                </div>
                                <button className="btn btn-primary py-1 px-2 ms-auto">Followed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
    }
}

export default ProfileFollow;
