import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LoginSignOut extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }

    render = () => (<>
        <a className="nav-link user-profile dropdown-toggle"
            data-toggle="dropdown"
            id="navbarDropdown"
            href="#">Sesión
            {/* <i className="far fa-bell"></i>
            <span className="badge badge-warning navbar-badge"></span> */}
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-header">Usuario</span>
            <div className="dropdown-divider"></div>
                <img src="/FotosImg/avatar3.png" alt="" className="img-circle" />
                <Link to="/auth/signin" className="btn btn-raised btn-success btn-flat float-right">
                    <i className="fa fa-key"></i> Iniciar Sesión</Link>
                <Link to="/auth/new-user-solicitar" className="btn btn-raised btn-default float-right btn-flat">
                    <i className="fa fa-user-plus"></i> Registrate</Link>
                <Link to="/auth/forgot-password" className="btn btn-raised btn-default float-right btn-flat">
                    <font size="3" color="red"><b>Olvide mi Clave</b></font>
                </Link>
        </div>
    </>
    )
}
export default LoginSignOut;
/*
              <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                   
                        <li className="dropdown user user-menu">
                            <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
                            <img src="/FotosImg/avatar3.png" className="user-image" alt=""/>
                                <span>Ingresar <i className="caret"></i></span>
                            </a>
                            <ul className="dropdown-menu">
                                <li className="user-header bg-light-blue">
                                    <img src="/FotosImg/avatar3.png" className="img-circle" alt="" />
                                    <p>
                                        Jane Doe - Web Developer
                                        <small>Member since Nov. 2012</small>
                                    </p>
                                </li>
                                <li className="user-body">
                                    <div className="col-xs-12 text-center">
                                         <Link to="/auth/forgot-password" className="xbtn xbtn-raised xbtn-danger"><font size="3" color="red"><b>Olvide mi Clave</b></font></Link>
                                    </div>
                                </li>
                                
                                <li className="user-footer">
                                     <Link to="/auth/new-user-solicitar" className="btn btn-raised btn-default btn-flat"><i className="fa fa-user-plus"></i> Crear</Link>
                                     <Link to="/auth/signin" className="btn btn-raised btn-success btn-flat float-right"><i className="fa fa-key"></i> Login</Link>
                                </li>

                            </ul>
                        </li>

                    </ul>
             </div>
*/