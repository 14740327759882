import React, { Component } from "react";
class BoxCenter extends Component {
    constructor(props) { super(props); this.data = props }
    render() {
        return (
            <div className="login-register-areax mb-60p">
                <div className="container">
                    <div className="container-inner">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 ms-auto me-auto">
                                <div className="login-register-wrapper">
                                    <div className="login-register-tab-list nav">
                                        <a className="active" data-bs-toggle="tab" href="#lg1">
                                            <h4 className="card-title"><b>{this.data.titulo}</b></h4>
                                        </a>
                                        {/* <div className="card-actions cursor-pointer ms-auto d-flex button-group">
                                            <div className="float-right" style={{ marginTop: '20px' }}>
                                                <a href="/" className="text-nowrap logo-img text-center d-block mb-5 w-100">
                                                    <img src="/FrWork/general/logo_empresa.png"
                                                        className="dark-logo"
                                                        alt="Logo-Dark"
                                                        style={{ display: 'none', width: '40px' }} />
                                                    <img src="/FrWork/general/logo_empresa.png"
                                                        className="light-logo"
                                                        alt="Logo-light"
                                                        style={{ display: 'none', width: '40px' }} />
                                                </a>

                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="body-wrapper">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card"> */}

                                    <div className="tab-content">
                                        <div id="lg1" className="tab-pane active">
                                            <div className="login-form-containerx" style={{ padding: '40px 30px 10px 30px',boxShadow: '0 0 6px rgba(0, 0, 0, 0.1)' }}>
                                                <div className="login-register-form">
                                                    {this.props.children}
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default BoxCenter;
