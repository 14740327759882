import { Link, Navigate } from "react-router-dom";
import ApiGlo from "global/ApiGlobal";
import HFormUser from "global/components/Page/Forms/HFormUser";

import React, { Component } from "react";
import ApiAuth from "../auth";
import ShowError from "../components/ShowError";
import './NewUserSolicitar.css'
import BoxCenter from "./BoxCenter";
class NewUserSolicitar extends HFormUser {
    state = {
        data: [],
        loading: false,
        message: "",
        error: ""
    };

    newUser = e => {
        e.preventDefault();
        if (document.getElementById("txUserEmail").value.trim().length == 0) {
            this.setState({ error: "Ingrese Email", loading: false });
            return
        }

        this.setState({ message: "", error: "" });
        var obData = { email: document.getElementById("txUserEmail").value }
        ApiAuth.newUserSolicitar(this, obData).then(data => {
            if (!data.ok) this.setState({ error: data.msg });
            else this.setState({ message: data.msg });
        });
    };

    render() {
        return (<BoxCenter titulo="Crear Cuenta">
            <form class>
                <ShowError
                    msg={this.state.message}
                    err={this.state.error} />
                <div className="mb-3">
                    <div className="position-relative form-group">
                        <input type="text"
                            id="txUserEmail"
                            placeholder="Email del Usuario"
                            className="form-control" />
                    </div>
                </div>



                <div className="d-flex align-items-center justify-content-between">
                    <div className="form-check btn btn-outline-primary">
                        <Link to={ApiGlo.forgotPasswordRoute} className="btn2 btn-raised2 btn-warning2  float-none2 "> Olvide Mi Clave</Link>
                    </div>

                    <Link to={ApiGlo.loginRoute} className="btn btn-outline-primary">
                        Inisio Sesión
                    </Link>
                </div>

                <br />
                <div className="mb-3">
                    <a onClick={this.newUser}
                        className="btn btn-primary w-100 py-8 mb-4 rounded-2">
                        Enviar Solicitud
                    </a>
                </div>
                <div className="mb-3">
                    <font color="blue" >Se enviará un correo de confirmación al mail ingresado
                        <br />El Token podrá ser utilizado solo dentro de los próximos 10 minutos
                    </font>
                </div>
            </form>

        </BoxCenter >
        );
    }
}
export default NewUserSolicitar;
