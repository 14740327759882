import React,{Component} from 'react';
import HBoxMsg  from "./HBoxMsg";
class HTextPatenteBox extends Component{
	constructor(props) {
		super(props);
		this.data=props;
		this.pattern = "^[0-9]{4}-[0-9]{2}-[0-9]{2}$"
    this.state = {
        value: "",
        errors: {},     
      };   
  }
  componentWillUpdate(nextProps, nextState){
  //componentDidUpdate(nextProps, nextState){
    this.data=nextProps;
  }
  /* 
  componentWillMount() {
    this.setState({
      currentColor: this.props.defaultColor,
      value : this.props.value,
      palette: 'rgb',
    });
  }  
  */
  /*
	keyPressFunc(e) {
	   
	    if(e.which == 8) {
	      var val = this.state.value;
	      console.log(val);
	      if(val.length == 3 || val.length == 6) {
	          val = val.slice(0, val.length-1);
	          console.log(val)
	          this.setState({value: val})
	      }
	    }
	  }
*/
  handleChange = name=> event => {
    if (event.target.value.length === 4) {
        event.target.value += '-';
      } else if (event.target.value.length === 7) {
        event.target.value += '-';
    }  	
    let err = {};

    if (event.target.value.length < this.data.minLength) {
      err.msg = "Mínimo "+ this.data.minLength+ " caracteres";
    }
    else
    if (event.target.value.length > this.data.maxLength) {
      err.msg = "Máximo " + this.data.maxLength +" caracteres";
    }
    else{
    	const regp= this.data.pattern || this.pattern
    	// console.log("regp" + regp)
    	const condition = new RegExp(regp);
  		let val= condition.test(event.target.value);
  		if(!val){
  		  err.msg = "Carácteres no válidos ";
  		  }
    }

    this.setState({ value: event.target.value});
    this.setState({ errors: err }, () => {
      if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
        this.setState({ submitted: true });
      }
    });     

  };   
  render =()=>{
	    return (<div className="form-group">
                <label className="col-sm-5 control-label" >{this.data.label}</label>
                <div className="col-sm-8">
                <input type="text" 
                    onChange={this.handleChange("value")} 
                    value={this.state.value|| this.data.value||""}
                    disabled={this.data.disabled|| false}
                    id={this.data.id} 
                    name={this.data.name || this.data.id} 
                    style={this.data.style || {width:"150px"}} 
                    title={this.data.title|| "Patente del Vehiculo"}
                    placeholder={this.data.placeholder|| "YYYY-MM-DD"}
                    pattern={this.data.pattern || this.pattern} 
                    minLength={this.data.minLength|| 8} 
                    maxLength={this.data.maxLength || 10}  
                    className="input-xxlarge required is-invalid " 
                   />
                 <HBoxMsg msg={this.state.errors.msg}/>
                </div>
              </div>         
      );
 	}  
 
}    
export default  HTextPatenteBox;