// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
        visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/global/user/Profile/Profile.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,2BAA2B;IAC3B,kBAAkB;IAClB;kCAC8B;IAC9B,2CAA2C;AAC/C;;AAEA;IACI,UAAU;IACV,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".fade-in-section {\r\n    opacity: 0;\r\n    transform: translateY(20vh);\r\n    visibility: hidden;\r\n    transition: opacity 1200ms ease-out, transform 600ms ease-out,\r\n        visibility 1200ms ease-out;\r\n    will-change: opacity, transform, visibility;\r\n}\r\n\r\n.fade-in-section.is-visible {\r\n    opacity: 1;\r\n    transform: none;\r\n    visibility: visible;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
