import HBoxObj from "./HBoxObj";
import HBoxObjBase from "./HBoxObjBase";
class HTextBoxDir extends HBoxObj {
    render = () => {
        return (<HBoxObjBase
            pattern={this.data.pattern || "^[a-zA-Z0-9 áéíóúÁÉÍÓÚñ.-_,/]+$"}
            minLength={this.data.minLength || 5}
            maxLength={this.data.maxLength || 50}
            {...this.props}
        />
        );
    }

}
export default HTextBoxDir;