// conetxt useStatus ==>  https://kentcdodds.com/blog/how-to-use-react-context-effectively
import React, { useContext } from 'react';
import HeaderPage from "./Header/Moder/HeaderPage";
import HeaderMsg from "./Header/Moder/HeaderMsg";
import HeaderSetting from "./Header/Moder/HeaderSetting";
import HMenuModVer from "global/components/Page/Header/Moder/HMenuVer";
import HMenuModHor from "global/components/Page/Header/Moder/HMenuHor";
import HeaderCart from "global/components/Page/Header/Moder/HeaderCart";
import MainRouterMod from "modMenu/Menu/MainRouterMod";
import UserProvider from "global/context/user/UserProvider";
import HeaderRuta from "global/components/Page/Header/HeaderRuta";
const Container = ({ history }) => {
    // const context=useContext(UserContext)
    // contexto={context}
    // console.log("context:",context)
    return (
        <UserProvider>
            <HMenuModVer />
            <div className="page-wrapper"  style={{ border: '5px' }}> 
                <HeaderMsg />
                <HeaderPage />
                <HMenuModHor />
                <div className="body-wrapper mw-100" style={{ border: '5px' }}>
                    <div className="container-fluid mw-100"  style={{ border: '5px',margin:'0px',padding: '0px' }}>
                            <HeaderRuta />
                            <MainRouterMod style={{ marginTop: '30px' }} />
                    </div>
                </div>
                <HeaderSetting />
                <HeaderCart/>
            </div>
        </UserProvider>
    )
};
export default Container;

