//https://www.npmjs.com/package/react-modal
//npm install react-responsive-modal
//https://blog.bitsrc.io/build-a-simple-modal-component-with-react-16decdc111a6
//https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
//https://www.newline.co/@dmitryrogozhny/how-to-display-modal-dialog-in-react-with-react-modal--dbf46cda
//https://www.c-sharpcorner.com/article/implementation-of-ngx-datatable-using-anguar-8/
//npm install react-bootstrap bootstrap 
//npm install react-native react-native-web react-dom
import React, { Component } from "react";
import HFormUser from "global/components/Page/Forms/HFormUser";
import DataTable from "global/components/Page/DataTable/DataTableMaterial";
import { Alert, Modal, StyleSheet, Text, View } from "react-native";
import apiHelp from "global/utils/funHelp";
//import  DataTable  from "../DataTable/DataTableMaterial";
import ShowLoading from "global/components/ShowLoading";
//import ContainerRightEdit  from "global/components/Page/ContainerRight/ContainerRightEdit";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
class HBoxHelp extends HFormUser {
    state = { modalVisible: false };
    constructor(props) {
        super(props);
        this.data = props;
        this.nroObj = 0;
        this.state = {
            objData: {
                sqlTitulo: "Prueba"
                , letArrTit: []
                , rows: []

            }
            , loading: true
        }
    }

    setModalVisible = (visible) => {
        if (visible) this.readApi([]);
        this.setState({ modalVisible: visible });
    }
    /*
    componentDidMount() {
      if (this.state.modalVisible)this.readApi();
    } */
    readApi = (paramSearch) => {
        let arrPar = { codHelp: this.data.sqlHelp }
        arrPar["qryFiltro"] = paramSearch
        //console.log("help readApi", arrPar)
        this.post("/help/ayuda", arrPar)
            .then(data => {
                //console.log("/help/ayuda",data)
                if (data.ok) {
                    //console.log("Respueta Help",data.obj.helpData)
                    this.setState({ //data: data.obj.rows
                        resObj: data.obj
                        , swError: !data.ok
                        , loading: false
                        , objData: {
                            sqlTitulo: data.obj.helpData.sqltitulo
                            , letArrTit: data.obj.helpTitulo.concat(
                                { id: "bt_Asig", label: "Seleccionar", align: "center", accion: this.btSeleccionar, clsIcono: "far fa-hand-pointer" })
                            , rows: data.obj.rows
                        }
                    });
                }
            })
    }
    btSeleccionar = (objRow, index) => {
        //console.log("objRow:",objRow,index)
        this.setModalVisible(!this.state.modalVisible)
        //console.log("xxxxx:",this.state.objData.rows[index])
        //objRow.valueid = objRow.name
        this.data.onClickHelp(objRow)
    }
    btCancelar = () => {
        this.setModalVisible(false)
    }
    startSearch = () => {
        let paramSearch = apiHelp.getTextSearch()
        //console.log(paramSearch)
        this.readApi(paramSearch)
        //this.setModalVisible(false)
        //const modalDOM = ReactDOM.findDOMNode(this.modal);
        //modalDOM.scrollTop = 0
    }
    /*
    https://reactnative.dev/docs/view
    style={{
      flexDirection: "row",
      height: 100,
      padding: 20
    }}
    style={{ backgroundColor: "blue", flex: 0.3 }}
    */
    //https://stackblitz.com/edit/react-ls1dwp?file=index.js
    render() {
        //console.log("render Help:", this.data.sqlHelp)
        this.nroObj++;
        const { modalVisible } = this.state;
        //if(!this.state.modalVisible)return (<button onClick={() => this.setModalVisible(true)}>?</button>)
        if (!this.state.modalVisible) return (
            //   <div className="input-group-append">
            <button className="btn btn-success"
                onClick={() => this.setModalVisible(true)}
                style={{ cursor: 'pointer', fontSize: '17px' }}>
                <span className="fa fa-pencil">?</span>
            </button>
            //   </div>
        )
        if (this.state.loading) return (<ShowLoading />)
        return (

            <View style={styles.centeredView} >
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => {
                        Alert.alert("Modal has been closed.");
                        this.setModalVisible(!modalVisible);
                    }}
                >
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            {/*
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => this.setModalVisible(!modalVisible)}
              ><Text>Cancelar</Text></Pressable>
              style={{backgroundColor:"red"}}
              */}
                            <div className="col-md-12">
                                <DataTable
                                    id="tbHelpComuna"
                                    titulo="Comunas"
                                    titulos={this.state.objData.letArrTit}
                                    rows={this.state.resObj.rows}
                                    lengthChange={false}

                                    bt1Click={this.btCancelar}
                                    bt1Icon="fa fa-edit"
                                    btSearchClick={this.startSearch}
                                />
                                <Text style={styles.textStyle}>Hide Modal</Text>
                            </div>
                        </View>
                    </View>
                </Modal>


            </View>
        );
    }
}
/*
        <Pressable
          style={[styles.button, styles.buttonOpen]}
          onPress={() => this.setModalVisible(true)}
        >
          <Text style={styles.textStyle}>?</Text>
        </Pressable>
*/
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 0
    },
    modalView: {
        overflow_y: "auto",
        height: "90%",
        width: "70%",
        margin: 10,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 0
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 0,
        textAlign: "center"
    }
});
export default HBoxHelp;