import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiTurnos";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ShowLoading from "global/components/ShowLoading";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";

class FDiaMes extends HFormUser {

    componentDidMount() { this.readTurnosArea() }
    readTurnosArea() {
        this.setState({ loading: true })
        /*       
        var data = {  idKey:this.props.location.state.detKey} 
        this.post(Api.diaMesAno,data)
            .then(data=>{this.setState({dataArea: data.rows
                                    ,data : data
                                    ,loading: false
                                    })})
        */
        //console.log("MesLog",this.props.location.state.detKey)
        if (!this.props.location ||  !this.props.location.state || !this.props.location.state.detKey) {
            this.props.navigation("/")
            return
        }
        this.post(Api.diaMesAno, { idKey: this.props.location.state.detKey })
            .then(data => {
                this.stateHarrys(this, data)
                //this.setState({ dataTurnos: this.state.resObj.obj.rowTurnos })
            })
    }
    turnosHora = () => {
        const data = { idTurno: document.getElementById("cbTurno").value }
        this.post(Api.turnosHora, data)
            .then(data => {
                this.setState({
                    dataTar: data.obj.cbTar
                    , data: data
                })
            })
    }
    actualizar = () => {
        if (!document.getElementById("cbTar")) {
            alert("Seleccione Tarifa")
            return
        }
        var data = {
            idKey: this.props.location.state.detKey
            , cbTur: document.getElementById("cbTurno").value
            , cbTar: document.getElementById("cbTar").value
            , cbMes: "1"
            , cbHor: "-99"
        }
        this.post(Api.diaMesAnoSave, data)
            .then(data => {
                if (data.ok) this.readTurnosArea()//apiRuta.toRutaAdd(this,"Generar","/")
                else alert(data.msg)
            })
    }
    generarApi = (row) => {
        apiRuta.toRutaAdd(this, "Generar", Api.generaMesAnoRoute, { detKey: this.props.location.state.detKey })
    }
    removeFuturo = () => {
        this.post(Api.diaMesAnoElimGo, { detKey: this.props.location.state.detKey })
            .then(data => {
                //this.setState({data: data})
                if (data.ok) this.readTurnosArea()
            })
    }
    btElim = (row) => {
        console.log("eli", row)
        this.post(Api.diaMesAnoElim, { dataElim: row })
            .then(data => {
                //this.setState({data: data})
                if (data.ok) this.readTurnosArea()
            })
        //apiRuta.toRutaAdd(this,"Volver","/")
    }


    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
              { id: "fecha", label: "Fecha", align: "right", swFiltro: false }
            , { id: "fecha_apli", label: "F. Apli", align: "right", swFiltro: false }
            , { id: "hor_ini_dsp", label: "H.Inicio", align: "right", swFiltro: false }
            , { id: "hor_fin_dsp", label: "H.Fin", align: "right" }
            , { id: "hor_cantidad", label: "H.H.", align: "right" }
            , { id: "hor_valor", label: "Valor", align: "right" }
            , { id: "hor_total_dsp", label: "Total", align: "right" }
            , { id: "btElim", label: "Eliminar", align: "center", accion: this.btElim, clsIcono: "fa fa-trash" }
        ]
        //console.log("Render",this.state.resObj)
        return (
            <ContainerRightTable
                tituloContainer={this.state.data && ('Fecha : ' + (this.props.location.state &&  this.props.location.state.detKey.fecha))}
                contexto={this}
                titulos={titulos}
                rows={this.state.resObj.obj && this.state.resObj.obj.rowHor}
                resObj={this.state.resObj}
                // bt1Click={this.actualizar}
                // bt1Icon="fa fa-save"

                // bt2Click={this.generarApi}
                // bt2Icon="fa fa-gears"
                // bt2Label="Generar"

          
                // bt3Click={this.removeFuturo}
                // bt3Label="Futuro"
                // bt3Icon="fa fa-remove"
            >
                <HBoxSelect
                    label="Horario"
                    id="cbTurno"
                    title="Turno"
                    onChange={this.turnosHora}
                    value={this.props.location.state &&  this.props.location.state.detKey.horario.length > 0 && this.props.location.state.detKey.horario[0].idhora}
                    option={this.state.dataTurnos}

                />
                <HBoxSelect
                    label="Tarifa"
                    id="cbTar"
                    title="Tarifa"
                    value={this.props.location.state &&  this.props.location.state.detKey.horario.length > 0 && this.props.location.state.detKey.horario[0].idhora}
                    option={this.state.dataTar}
                />

            </ContainerRightTable>
        );
    }

}
//<HBoxButtonCrud btActualizarClick={() => this.actualizarApi()}/>

export default WithNavigate(FDiaMes);