import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";
import apiRuta from "global/utils/funRuta"
import HFormUser from "global/components/Page/Forms/HFormUser";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextDir from "global/components/Page/InputHtml/HBoxTextDir";
import DataTable from "global/components/Page/DataTable/DataTableMaterial";
import "./Profile.css";


class ProfileSetCel extends HFormUser {
    constructor(props) {
        super(props);
        this.state = { isVisible: false ,reg:null}
    }
    btEdit = (row) => {
        apiRuta.toRutaAdd(this, "Celular", ApiGl.profileEditCelRoute, { detKey: row })
    }
    btAdd = () => {
        apiRuta.toRutaAdd(this, "Celular", ApiGl.profileEditCelRoute, { detKey: {idcel:-1} })
    }
    render() {
        const titCel = [
              { id: "nrocel", label: "N.Celular", style: { width: '70px' } }
            , { id: "cel_tipo", label: "Tipo", style: { width: '120px' } }
            , { id: "bt_prin", label: "Editar", accion: this.btEdit, clsIcono: "fas fa-hand-pointer" }

        ]
        //console.log("Dire:", this.data.regCel,this.state.reg)
        return (<>
            <div className="container rounded bg-white mt-5 mb-5">
                <div className="row">
                    <div className="col-md-3 border-right">
                        <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img className="rounded-circle mt-5" width="150px"
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                            <span className="font-weight-bold">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_nombres') : "samanta@harrys.com"}</span>
                            <span className="text-black-50">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_email') : "samanta@harrys.com"}</span><span> </span></div>
                    </div>
                    <div className="col-md-9 border-right">
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="text-right">Mis Direcciones</h4>
                            </div>
                            <div className="row mt-3">
                                <DataTable key="kk_img"
                                    rows={this.data.regCel ? this.data.regCel : []}
                                    titulo="Direcciones"
                                    titulos={titCel}
                                    bt1Click={this.btAdd}
                                    bt1Icon="fa fa-plus"
                                    filasPorPagina={10}
                                />
                            </div>
                        </div>

                    </div>
                    <div id="mod-direcc" className={"col-md-9 border-right fade-in-sectionX " + (this.state.isVisible ? 'is-visible' : '')}>
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="text-right">Teléfonos</h4>
                            </div>
                            <div className="row mt-3">
                                <HBoxText id="txId"
                                    label="Id"
                                    disabled
                                    minLength="3"
                                    // value={this.state.reg && this.state.reg.iddir}
                                    maxLength="150"
                                />
                                <HBoxTextDir id="txNroCel"
                                    label="Nro. Telefónico"
                                    minLength="3"
                                    required
                                    // value={this.state.reg && this.state.reg.direccion}
                                    maxLength="150"
                                />
                            </div>
                            <div className="mt-5 text-center">
                                <button className="btn btn-primary 
                                    profile-button"
                                    onClick={this.guardar}
                                    type="button">Guardar Teléfono</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
        )
    }
}

export default WithNavigate(ProfileSetCel);
