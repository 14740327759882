import React,{Component} from 'react';

class ShowError extends Component{
	constructor(props) {
     super(props);
     this.data=props;
     //console.log('props.msg' +props.msg)
     //localStorage.removeItem('jwt');
     // NOOOOOOOOOOOOOOOOO   localStorage.clear();
   }
  componentWillUpdate(nextProps, nextState){
    this.data=nextProps;
  }   
 render =()=>{
  if (!this.data.msg  && !this.data.err) return (<></>)
  if (this.data.msg == "OK"  || this.data.err == "OK") return (<></>)
  return (<>  
        <br/>
       <div className="content-wrappe1r">
          <section className="content1">
            <div className="row">
                <div className="col-xs-12">
                    <div className="box">
                      <div className="box-header">
                          <div className="container_table1 box-title1 table-responsive" 
                              style={{marginLeft:'20px'}}>
                                <font color="red"><b><strong>{this.data.msg ||this.data.err} 
                                      </strong></b>
                                </font>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
          </section>      
        </div>
        </>
  	)}  
}    
export default  ShowError;
