import React, { Component } from 'react';


class HeaderPage extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        return (<>

        </>)
    }
}
export default HeaderPage;
