import React, { Component } from 'react';
import HBoxObjBase from "./HBoxObjBase";
class HBoxTextCod extends Component {
    swRutea=true
    constructor(props) {
        super(props);
        this.data = props;
        this.objObjeto = {
            cc_valueid: this.data.valueid
            , value: this.data.value
        }
    }
    
    setObjeto= (objObjeto)=> {
        if (this.swRutea) console.log("setObjeto",objObjeto)
        this.objObjeto = objObjeto
        this.setState({ value: objObjeto.cc_value})
        this.objCodigo.setValue(objObjeto.cc_value)
        //console.log("Hlp objObjetoSet:",objObjeto)
        // this.txIdComuna.setValue(objObjeto.nombre + "/UU")
        //this.data.onClickHelp(objObjeto)
    }
    getObjeto = () => {
        if (this.swRutea) console.log("getObjeto:",this.objObjeto)
        return this.objObjeto
    }
    getId = () => {
        return this.objObjeto.cc_valueid
    }
    // componentWillUpdate(nextProps, nextState) { this.data = nextProps; }

    render = () => {
        //console.log("textCod render:",this.data,this.objObjeto.value,this.objObjeto,this.data)
        return (
            <HBoxObjBase
                {...this.props}
                ref={x => this.objCodigo = x}
                value={this.objObjeto.value}
                size={this.data.size || 10}
                minLength={this.data.minLength || 1}
                maxLength={this.data.maxLength || 400}
                sqlHelp={this.data.sqlHelp}
                onClickHelp={this.data.onClickHelp || this.setObjeto }
            />

        );
    }

}
export default HBoxTextCod;