//https://getbootstrap.com/docs/5.0/components/modal/
const apiMsg = {
    msgFetchErr(stMsg, stTitulo) {
        try {
            if (stTitulo) document.getElementById("msg-modal-head").innerHTML = stTitulo
            if (stMsg) document.getElementById("msg-modal-body").innerHTML = "<h4>" + stMsg + "</h4>"
            $("#primary-header-modal").modal('show');
        } catch (error) {
            console.error(error);
            // Expected output: ReferenceError: nonExistentFunction is not defined
            // (Note: the exact output may be browser-dependent)
        }
    },
    msgOk(stMsg, stTitulo) {
        if (stTitulo) document.getElementById("msg-modal-head").innerHTML = stTitulo
        if (stMsg) document.getElementById("msg-modal-body").innerHTML = "<h4>" + stMsg + "</h4>"
        $("#primary-header-modal").modal('show');
    },
    msgOkCancelXX(stMsg, stTitulo) {
        if (stTitulo) document.getElementById("msg-modal-head").innerHTML = stTitulo
        if (stMsg) document.getElementById("msg-modal-body").innerHTML = "<h4>" + stMsg + "</h4>"
        $("#primary-header-modal").modal('show');
    }
}
export default apiMsg;