import React from 'react';
import apiRuta from "global/utils/funRuta";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import HLink from "global/components/Page/Header/AdminLte/HLinkMenu";
import _menu from 'modMenu/_menu';
import UserContext from 'global/context/user/UserContext';
class HMenu extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
    }
    menuClick = (stTitulo, stPath, objPar) => {
        apiRuta.toRutaFirst(this, stTitulo, stPath, objPar)
    }
    render() {
        return (<>{this.fuMenu(_menu.arMenu)}</>)
    }
    //{this.fuPruebaMenuAero()}

    //sidebar-dark-info bg-lime accent-primary navbar-warning
    fuMenu(lMenu) {
        return (
            <aside className="main-sidebar sidebar-light-primary    elevation-4">
                <a href="/" className="brand-link">
                    <img src="/FrWork/general/logo_empresa_fill.png"
                        alt="AdminLTE Logo"
                        className="brand-image img-circle elevation-3"
                        style={{ opacity: '.8' }} />
                    <span className="brand-text font-weight-light">Harrys</span>
                </a>

                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <img src="/FrWork/adPanel/dist/img/avatar.png"
                                className="img-circle elevation-2"
                                alt="Imágen del Usuario" />
                        </div>
                        <div className="info">
                            <a href="#" className="d-block">Harrys</a>
                        </div>
                    </div>

                    {false && (<div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw"></i>
                                </button>
                            </div>
                        </div>
                    </div>)}

                    <nav className="mt-2 ">
                        <ul className="nav nav-pills nav-sidebar flex-column nav-flatXX nav-compact nav-child-indentXX"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false">
                            {lMenu && lMenu.map((regNiv1, index) => {
                                return (
                                    <li key={"niv1_" + index}
                                        className={"nav-item " + (index == 0 ? "menu-open" : "")}>
                                        <a href="#" className="nav-link bg-primary" >
                                            <i className={"nav-icon " + regNiv1.icon}></i>
                                            <p>{regNiv1.titulo}
                                                <i className="fas fa-angle-left right"></i>
                                            </p>
                                        </a>
                                        {regNiv1.menNiv && (<ul className="nav nav-treeview">
                                            {regNiv1.menNiv.map((regNiv2, index) => {
                                                return (
                                                    <li className="nav-niv2 nav-item" key={"nivoi_" + index}>
                                                        <HLink key={"niv2_" + index}
                                                            tituloArbol={regNiv2.titulo}
                                                            to={regNiv2.ruta}
                                                            btClickSelect={this.menuClick}
                                                            label={regNiv2.titulo}
                                                            icon={regNiv2.icon}
                                                            nivel="2" />
                                                        {regNiv2.menNiv && (
                                                            <ul className="nav nav-treeview">
                                                                {regNiv2.menNiv.map((regNiv3, index) => {
                                                                    return (
                                                                        <li className="nav-niv3 nav-item" key={"niv3li_" + index}>
                                                                            <HLink key={"niv3_" + index}
                                                                                tituloArbol={regNiv3.titulo}
                                                                                to={regNiv3.ruta}
                                                                                btClickSelect={this.menuClick}
                                                                                icon={regNiv3.icon}
                                                                                label={regNiv3.titulo}
                                                                                nivel="3" />
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>)}
                                                    </li>
                                                )
                                            })}
                                        </ul>)}
                                    </li>
                                )
                            })}

                            {false && this.fuPruebaMenu()}
                        </ul>
                    </nav>
                </div>
            </aside>)
    }
    fuPruebaMenuAero() {
        return (
            <aside id="leftsidebar"  className="sidebar">
                <div  className="navbar-brand">
                    <button  className="btn-menu ls-toggle-btn" type="button"><i  className="zmdi zmdi-menu"></i></button>
                    <a href="https://wrraptheme.com/templates/aero/html/index.html">
                        <img src="./__ Aero Bootstrap4 Admin __ Home_files/logo.svg" width="25" alt="Aero" /><span
                             className="m-l-10">Aero</span></a>
                </div>
                <div  className="menu">
                    <div  className="slimScrollDiv"
                        style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: 'calc(100vh - 65px)' }}>
                        <ul  className="list" style={{ overflow: 'hidden', width: 'auto', height: 'calc(100vh - 65px)' }}>
                            <li>
                                <div  className="user-info">
                                    <a  className="image waves-effect waves-block"
                                        href="https://wrraptheme.com/templates/aero/html/profile.html#dark">
                                        <img
                                            src="./__ Aero Bootstrap4 Admin __ Home_files/profile_av.jpg" alt="User" /></a>
                                    <div  className="detail">
                                        <h4>Michael</h4>
                                        <small>Super Admin</small>
                                    </div>
                                </div>
                            </li>
                            <li  className="active open"><a href="https://wrraptheme.com/templates/aero/html/index.html#dark"
                                 className="toggled waves-effect waves-block"><i
                                     className="zmdi zmdi-home"></i><span>Dashboard</span></a></li>
                            <li><a href="https://wrraptheme.com/templates/aero/html/my-profile.html#dark"
                                 className=" waves-effect waves-block"><i  className="zmdi zmdi-account"></i><span>Our
                                    Profile</span></a></li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-apps"></i><span>App</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/mail-inbox.html#dark"
                                         className=" waves-effect waves-block">Email</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/chat.html#dark"
                                         className=" waves-effect waves-block">Chat Apps</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/events.html#dark"
                                         className=" waves-effect waves-block">Calendar</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/contact.html#dark"
                                         className=" waves-effect waves-block">Contact</a></li>
                                </ul>
                            </li>
                            <li> <a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-assignment"></i><span>Projects</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/project-list.html#dark"
                                         className=" waves-effect waves-block">Projects List</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/taskboard.html#dark"
                                         className=" waves-effect waves-block">Taskboard</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/ticket-list.html#dark"
                                         className=" waves-effect waves-block">Ticket List</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/ticket-detail.html#dark"
                                         className=" waves-effect waves-block">Ticket Detail</a></li>
                                </ul>
                            </li>
                            <li> <a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-folder"></i><span>File Manager</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/file-dashboard.html#dark"
                                         className=" waves-effect waves-block">All File</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/file-documents.html#dark"
                                         className=" waves-effect waves-block">Documents</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/file-images.html#dark"
                                         className=" waves-effect waves-block">Images</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/file-media.html#dark"
                                         className=" waves-effect waves-block">Media</a></li>
                                </ul>
                            </li>
                            <li> <a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-blogger"></i><span>Blog</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/blog-dashboard.html#dark"
                                         className=" waves-effect waves-block">Dashboard</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/blog-post.html#dark"
                                         className=" waves-effect waves-block">Blog Post</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/blog-list.html#dark"
                                         className=" waves-effect waves-block">List View</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/blog-grid.html#dark"
                                         className=" waves-effect waves-block">Grid View</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/blog-details.html#dark"
                                         className=" waves-effect waves-block">Blog Details</a></li>
                                </ul>
                            </li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-shopping-cart"></i><span>Ecommerce</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/ec-dashboard.html#dark"
                                         className=" waves-effect waves-block">Dashboard</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/ec-product.html#dark"
                                         className=" waves-effect waves-block">Product</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/ec-product-List.html#dark"
                                         className=" waves-effect waves-block">Product List</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/ec-product-detail.html#dark"
                                         className=" waves-effect waves-block">Product detail</a></li>
                                </ul>
                            </li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-swap-alt"></i><span>Components</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/ui_kit.html#dark"
                                         className=" waves-effect waves-block">Aero UI KIT</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/alerts.html#dark"
                                         className=" waves-effect waves-block">Alerts</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/collapse.html#dark"
                                         className=" waves-effect waves-block">Collapse</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/colors.html#dark"
                                         className=" waves-effect waves-block">Colors</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/dialogs.html#dark"
                                         className=" waves-effect waves-block">Dialogs</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/list-group.html#dark"
                                         className=" waves-effect waves-block">List Group</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/media-object.html#dark"
                                         className=" waves-effect waves-block">Media Object</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/modals.html#dark"
                                         className=" waves-effect waves-block">Modals</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/notifications.html#dark"
                                         className=" waves-effect waves-block">Notifications</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/progressbars.html#dark"
                                         className=" waves-effect waves-block">Progress Bars</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/range-sliders.html#dark"
                                         className=" waves-effect waves-block">Range Sliders</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/sortable-nestable.html#dark"
                                         className=" waves-effect waves-block">Sortable &amp; Nestable</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/tabs.html#dark"
                                         className=" waves-effect waves-block">Tabs</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/waves.html#dark"
                                         className=" waves-effect waves-block">Waves</a></li>
                                </ul>
                            </li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-flower"></i><span>Font Icons</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/icons.html#dark"
                                         className=" waves-effect waves-block">Material Icons</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/icons-themify.html#dark"
                                         className=" waves-effect waves-block">Themify Icons</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/icons-weather.html#dark"
                                         className=" waves-effect waves-block">Weather Icons</a></li>
                                </ul>
                            </li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-assignment"></i><span>Forms</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/basic-form-elements.html#dark"
                                         className=" waves-effect waves-block">Basic Form</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/advanced-form-elements.html#dark"
                                         className=" waves-effect waves-block">Advanced Form</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/form-examples.html#dark"
                                         className=" waves-effect waves-block">Form Examples</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/form-validation.html#dark"
                                         className=" waves-effect waves-block">Form Validation</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/form-wizard.html#dark"
                                         className=" waves-effect waves-block">Form Wizard</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/form-editors.html#dark"
                                         className=" waves-effect waves-block">Editors</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/form-upload.html#dark"
                                         className=" waves-effect waves-block">File Upload</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/form-summernote.html#dark"
                                         className=" waves-effect waves-block">Summernote</a></li>
                                </ul>
                            </li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-grid"></i><span>Tables</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/normal-tables.html#dark"
                                         className=" waves-effect waves-block">Normal Tables</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/jquery-datatable.html#dark"
                                         className=" waves-effect waves-block">Jquery Datatables</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/editable-table.html#dark"
                                         className=" waves-effect waves-block">Editable Tables</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/footable.html#dark"
                                         className=" waves-effect waves-block">Foo Tables</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/table-color.html#dark"
                                         className=" waves-effect waves-block">Tables Color</a></li>
                                </ul>
                            </li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-chart"></i><span>Charts</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/echarts.html#dark"
                                         className=" waves-effect waves-block">E Chart</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/c3.html#dark"
                                         className=" waves-effect waves-block">C3 Chart</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/morris.html#dark"
                                         className=" waves-effect waves-block">Morris</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/flot.html#dark"
                                         className=" waves-effect waves-block">Flot</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/chartjs.html#dark"
                                         className=" waves-effect waves-block">ChartJS</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/sparkline.html#dark"
                                         className=" waves-effect waves-block">Sparkline</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/jquery-knob.html#dark"
                                         className=" waves-effect waves-block">Jquery Knob</a></li>
                                </ul>
                            </li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-delicious"></i><span>Widgets</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/widgets-app.html#dark"
                                         className=" waves-effect waves-block">Apps Widgets</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/widgets-data.html#dark"
                                         className=" waves-effect waves-block">Data Widgets</a></li>
                                </ul>
                            </li>
                            <li><a href="void(0);#dark"  className="menu-toggle waves-effect waves-block"><i
                                 className="zmdi zmdi-lock"></i><span>Authentication</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/sign-in.html#dark"
                                         className=" waves-effect waves-block">Sign In</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/sign-up.html#dark"
                                         className=" waves-effect waves-block">Sign Up</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/forgot-password.html#dark"
                                         className=" waves-effect waves-block">Forgot Password</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/404.html#dark"
                                         className=" waves-effect waves-block">Page 404</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/500.html#dark"
                                         className=" waves-effect waves-block">Page 500</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/page-offline.html#dark"
                                         className=" waves-effect waves-block">Page Offline</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/locked.html#dark"
                                         className=" waves-effect waves-block">Locked Screen</a></li>
                                </ul>
                            </li>
                            <li  className="open_top"><a href="void(0);#dark"
                                 className="menu-toggle waves-effect waves-block"><i  className="zmdi zmdi-copy"></i><span>Sample
                                    Pages</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/blank.html#dark"
                                         className=" waves-effect waves-block">Blank Page</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/image-gallery.html#dark"
                                         className=" waves-effect waves-block">Image Gallery</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/profile.html#dark"
                                         className=" waves-effect waves-block">Profile</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/timeline.html#dark"
                                         className=" waves-effect waves-block">Timeline</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/pricing.html#dark"
                                         className=" waves-effect waves-block">Pricing</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/invoices.html#dark"
                                         className=" waves-effect waves-block">Invoices</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/invoices-list.html#dark"
                                         className=" waves-effect waves-block">Invoices List</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/search-results.html#dark"
                                         className=" waves-effect waves-block">Search Results</a></li>
                                </ul>
                            </li>
                            <li  className="open_top"><a href="void(0);#dark"
                                 className="menu-toggle waves-effect waves-block"><i
                                     className="zmdi zmdi-map"></i><span>Maps</span></a>
                                <ul  className="ml-menu">
                                    <li><a href="https://wrraptheme.com/templates/aero/html/google.html#dark"
                                         className=" waves-effect waves-block">Google Map</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/yandex.html#dark"
                                         className=" waves-effect waves-block">YandexMap</a></li>
                                    <li><a href="https://wrraptheme.com/templates/aero/html/jvectormap.html#dark"
                                         className=" waves-effect waves-block">jVectorMap</a></li>
                                </ul>
                            </li>
                            <li>
                                <div  className="progress-container progress-primary m-t-10">
                                    <span  className="progress-badge">Traffic this Month</span>
                                    <div  className="progress">
                                        <div  className="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="67"
                                            aria-valuemin="0" aria-valuemax="100" style={{ width: '67%' }}>
                                            <span  className="progress-value">67%</span>
                                        </div>
                                    </div>
                                </div>
                                <div  className="progress-container progress-info">
                                    <span  className="progress-badge">Server Load</span>
                                    <div  className="progress">
                                        <div  className="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="86"
                                            aria-valuemin="0" aria-valuemax="100" style={{ width: '86%' }}>
                                            <span  className="progress-value">86%</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div  className="slimScrollBar"
                            style={{ background: 'rgb(238, 238, 238)', width: '1px', position: 'absolute', top: '0px', opacity: '0.4', display: 'block', borderRadius: '3px', zIndex: '99', right: '1px', height: '767.516px' }}>
                        </div>
                        <div  className="slimScrollRail"
                            style={{ width: '1px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '0px', background: 'rgb(51, 51, 51)', opacity: '0.2', zIndex: '90', right: '1px' }}>
                        </div>
                    </div>
                </div>
            </aside>

        )

    }

    fuPruebaMenu() {
        return (
            <li className="nav-item">
                <a href="#" className="nav-link">
                    <i className="nav-icon far fa-plus-square"></i>
                    <p>
                        Extras
                        <i className="fas fa-angle-left right"></i>
                    </p>
                </a>
                <ul className="nav nav-treeview">
                    <li className="nav-item">
                        <a href="#" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>
                                Login & Register v1
                                <i className="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <a href="examples/login.html" className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Login v1</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="examples/register.html" className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Register v1</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="examples/forgot-password.html" className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Forgot Password v1</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="examples/recover-password.html" className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Recover Password v1</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>
                                Login & Register v2
                                <i className="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <a href="examples/login-v2.html" className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Login v2</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="examples/register-v2.html" className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Register v2</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="examples/forgot-password-v2.html" className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Forgot Password v2</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="examples/recover-password-v2.html" className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Recover Password v2</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a href="examples/lockscreen.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Lockscreen</p>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="examples/legacy-user-menu.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Legacy User Menu</p>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="examples/language-menu.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Language Menu</p>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="examples/404.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Error 404</p>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="examples/500.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Error 500</p>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="examples/pace.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Pace</p>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="examples/blank.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Blank Page</p>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="../starter.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Starter Page</p>
                        </a>
                    </li>
                </ul>
            </li>
        )
    }

}
HMenu.contextType = UserContext;
export default WithNavigate(HMenu);

