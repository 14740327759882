// conetxt useStatus ==>  https://kentcdodds.com/blog/how-to-use-react-context-effectively
import React, { useContext } from 'react';
//import PreLoader from "global/components/Page/Header/PreLoaderCircle";
import HeaderPage from "./Header/Aero/HeaderPage";
import HeaderRight from "./Header/Aero/HeaderRight";
import HeaderRightTheme from "./Header/Aero/HeaderRightTheme";
import UserContext from 'global/context/user/UserContext';
import HMenuMod from "global/components/Page/Header/Aero/HMenu";
import Footer from "global/components/Page/Header/Archi/Footer";
import MainRouterMod from "modMenu/Menu/MainRouterMod";
import UserProvider from "global/context/user/UserProvider";
import HeaderRuta from "global/components/Page/Header/HeaderRuta";
const Container = ({ history }) => {
    // const context=useContext(UserContext)
    // contexto={context}
    // console.log("context:",context)
    return (
        <UserProvider>
            <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar fixed-footer">
                <HeaderPage />
                <HeaderRight />
                <HeaderRightTheme />
                <section className="content">
                    <div className="body_scroll">
                        <HeaderRuta />
                        <MainRouterMod style={{ marginTop: '30px' }} />
                    </div>
                </section>
                <HMenuMod />
            </div>
        </UserProvider>
    )
};
export default Container;
//<Footer />

