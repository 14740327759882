import { useNavigate,useParams,useLocation } from 'react-router-dom';

const WithNavigate = Component => props => {
    return <Component {...props} 
                navigate={useNavigate()} 
                params={useParams()}
                location = {useLocation()}
    />
}

// function WithNavigate(props) {
//     let navigate = useNavigate();
//     return <CursosActivos {...props} navigate={navigate} />
// }

export  default WithNavigate

//import {useLocation,useNavigate,useParams} from "react-router-dom";
// const withRouter = WrappedComponent => props => {
//     const params = useParams();
//     // etc... other react-router-dom v6 hooks
  
//     return (
//       <WrappedComponent
//         {...props}
//         params={params}
//         // etc...
//       />
//     );
//   };
//  function withRouter(Component) {
//     function ComponentWithRouterProp(props) {
//       let location = useLocation();
//       let navigate = useNavigate();
//       let params = useParams();
//       return (
//         <Component
//           {...props}
//           router={{ location, navigate, params }}
//         />
//       );
//     }
  
//     return ComponentWithRouterProp;
//   }

// export const withRouter = (Component) =>{
//     const Wrapper = (props) =>{
//         const history = useNavigate();
//         return <Component history={history} {...props}/>
//     } 
//     return Wrapper;
// }

//   export default withRouter;

  

// import { useLocation } from "react-router-dom";

// const withLocation = Component => props => {
//     const location = useLocation();
  
//     return <Component {...props} location={location} />;
//   };


// export default withLocation( MyComponent)

/*

const withNavigateHook = (Component) => {
  //https://forum.codewithmosh.com/t/navigation-in-react-router-v6/11851/3
    return (props) => {
        const navigate = useNavigate();

        return <Component navigate={navigate} {...props} />
    }
}
*/