const turnosRoute = '/free/turnos'
const turnosMis = '/turnos/free/misTurnos'
const turnosInfMes = '/turnos/genTurnosInfMes'
const headerTopNav = '/turnos/free/header/headertopnav'
const diaMesAnoRoute = '/turnos/diaMes'
const generaMesAnoRoute = '/turnos/generarMes'
const diaMesAno = '/turnos/free/diaMes'
const diaMesAnoSave = '/turnos/diaMesSave'
const diaMesAnoElim = '/turnos/diaMesElim'
const diaMesAnoElimGo = '/turnos/diaMesElimGo'
const tipHoraAreaTrab = '/turnos/horaAreaTrabajo'
const tipHoraAreaTrabAct = '/turnos/horaAreaTrabajoAct'
const horarioGenerar = '/turnos/horarioGenerar'
const turnosArea = '/turnos/turnosArea'
const turnosHora = '/turnos/turnosHora'
const cgfModuloRoute = '/config/modulo/'
const cbGrados = '/turnos/free/cbGrados'
const cbCargo = '/turnos/free/cbCargo'
const comboTurnos = '/turnos/free/comboTurnos'
const cbEmpresa = '/turnos/free/cbEmpresa'
const getDatosPersonales = '/turnos/getDatosPersonales'
const getDatosPersonalesSave = '/turnos/getDatosPersonalesSave'
module.exports = {  
	turnosRoute,
	headerTopNav,
	turnosMis,
	diaMesAnoRoute,
	diaMesAno,
	diaMesAnoSave,
	diaMesAnoElim,
	diaMesAnoElimGo,
	tipHoraAreaTrab,
	tipHoraAreaTrabAct,
	horarioGenerar,
	generaMesAnoRoute,
	turnosArea,
	turnosHora,
	turnosInfMes,
	cgfModuloRoute,
	cbGrados,
    cbCargo,
    comboTurnos,
    cbEmpresa,
	getDatosPersonales,
	getDatosPersonalesSave,
}
