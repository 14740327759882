import React from 'react';
//import PreLoader from "./Header/PreLoader";
import HeaderPage from "./Header/AdminLte/HeaderPage";
import HMenuMod from "global/components/Page/Header/AdminLte/HMenu";
import Footer from "./Footer/Footer";
//import ControlSidebar from "./Footer/ControlSidebar";
import MainRouterMod from "modMenu/Menu/MainRouterMod";
import UserProvider from "global/context/user/UserProvider";
//import HMenuMod from "modMenu/Menu/MenuMod";
//import HPanelRight from "./MenuLTE/HMenuPanelRight";


const Container = ({ history }) => (
    <UserProvider>
        <HeaderPage />
        <HMenuMod />
        <div className="content-wrapper">
            <section className="content" key="sec2">
                <div className="card card-info card-outline " style={{ marginLeft: '10px', marginRight: '10px', borderRadius: '23px' }}>
                    <MainRouterMod style={{ marginTop: '30px' }} />
                </div>
            </section>
        </div>
        <Footer />
    </UserProvider>
);
export default Container;
