const loDomNroEmpresa='dom_nro_empresa'


/*  V1
 return fetch(`${process.env.REACT_APP_URL_AUTH}/new-user/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        })
        .then(response => {
            //console.log('forgot password response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
*/        
const signup = user => {
    return fetch(`${process.env.REACT_APP_URL_AUTH}/signup`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};
const signin = "/auth/signin";

const authenticate = (jwt,user, next) => {
    try{
        //if (typeof window !== 'undefined') {
            localStorage.setItem('login_jwt'  , JSON.stringify(jwt));
            localStorage.setItem('login_email',user.email);
            localStorage.setItem('login_role' ,jwt.obj.user.role);
            localStorage.setItem('login_empresa' ,jwt.obj.user.nroempresa);
            localStorage.setItem('login_usuario' ,jwt.obj.user.usuario);
            localStorage.setItem('login_perfil' ,jwt.obj.user.perfil);
            localStorage.setItem('login_nombres' ,jwt.obj.user.nombres);
            localStorage.setItem('login_ap_pat' ,jwt.obj.user.appaterno);
            localStorage.setItem('login_ap_mat' ,jwt.obj.user.apmaterno);
            localStorage.setItem('login_descrip' ,jwt.obj.user.descripcion);
            localStorage.setItem('login_data1' ,jwt.obj.user.data1);
        //}
    }
    catch (e) { /*console.log("authenticate catch:", e) */  }
    next();
};

const setName = (name, next) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('login_role', JSON.stringify(name));
        next();
    }
};

const signout = next => {
    if (typeof window !== 'undefined'){
        //localStorage.removeItem('login_jwt');
        //localStorage.removeItem('login_email');
        //localStorage.removeItem('login_role');
        localStorage.clear();
    } 
    return fetch(`${process.env.REACT_APP_URL_AUTH}/signout`
                , {method: 'POST'})
    .then(response => {return response.json();})
    .catch(err => console.log(err));
};
const setEmpresa = (nroEmpresa) => {
    if (typeof window == 'undefined') {
        return
    }
    localStorage.setItem(loDomNroEmpresa, nroEmpresa);
};
const getEmpresa = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    return localStorage.getItem(loDomNroEmpresa);
};
const isAuthenticated = () => {
    //console.log("isAuthenticated 00")
    if (typeof window == 'undefined') {
        return false;
    }
    var autLocal = JSON.parse(localStorage.getItem('login_jwt'));
    //console.log("isAuthenticated 11",autLocal)
    if (!autLocal) return false;
    //console.log("autLocal=>",autLocal)
    if (!autLocal.ok) return false;
    return autLocal.obj;
};

const forgotPassword_YaNOOOOO = email => {
    //console.log('email: ', email);
    return fetch(`${process.env.REACT_APP_URL_AUTH}/forgot-password/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
            body: JSON.stringify({ email })
        })
        .then(response => {
            //console.log('forgot password response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

const newUser = (form,bodyParam) => {
   return form.post("/auth/new-user/",bodyParam)
};
const newUserSolicitar = (form,bodyParam) => {
   return form.post("/auth/new-user-solicitar/",bodyParam)
};


const resetPassword = resetInfo => {
    // console.log('resetInfo: ', JSON.stringify(resetInfo));
    // Envia 
    // resetInfo:  {"newPassword":"qweqwe2","resetPasswordLink":"jjkljrñklwrjwl"}
    return fetch(`${process.env.REACT_APP_URL_AUTH}/reset-password/`, {
        method: 'POST',
        headers: { Accept: 'application/json',
                    'Content-Type': 'application/json'
                 },
                 body: JSON.stringify(resetInfo)
                })
        .then(response => {
            console.log("response:",response)
            return response.json();
        })
        .catch(err => console.log(err));
};

const socialLogin = user => {
    return fetch(`${process.env.REACT_APP_URL_AUTH}/social-login/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        // credentials: "include", // works only in the same origin
        body: JSON.stringify(user)
    })
        .then(response => {
            //console.log('signin response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

//export default ApiAuth;

module.exports = {
    getEmpresa,
    setEmpresa,
    signin,
    signout,
    isAuthenticated,
    authenticate,
    signup,
    setName,
    forgotPassword_YaNOOOOO,
    newUser,
    newUserSolicitar,
    resetPassword,
    socialLogin,
}
