import React from "react";
import { BrowserRouter } from "react-router-dom";
import Pagina from "Pagina";
import { StrictMode } from 'react';
import { useNavigate } from 'react-router-dom';
const App = () => ( 
                    <BrowserRouter>
                        <Pagina />
                    </BrowserRouter>
                    
                    );

export default App;
