import React, { Component } from 'react';
import reactCSS from 'reactcss'
//https://casesandberg.github.io/react-color/
import { SwatchesPicker } from 'react-color';
import { SketchPicker } from 'react-color';
import { PhotoshopPicker } from 'react-color';
import { ChromePicker } from 'react-color'
class HBoxColor extends Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            oldColor: this.data.color,
            swUpdate: this.data.swUpdate || false,
            background: this.data.color?this.data.color:'white',
            displayColorPicker: false,
        };
    }
    handleChange(color, event) {
        //console.log("handleChange", color.hex)
    }

    handleColorReset() {
        this.setState({ background: this.state.oldColor })
        this.handleClose()
    }
    handleChangeComplete = (color, event) => {
        //console.log("handleChangeComplete", color.hex)
        this.setState({ background: color.hex });
    };
    getColor = () => {
        return this.state.background
    }
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    getValue = () => {
        return this.state.background
    }
    render() {
        return this.renderCuadrado()
    }

    renderCuadrado() {
        //console.log("swUpdate",this.state.swUpdate)
        const styles = reactCSS({
            'default': {
                color: {
                    width: '90px',
                    height: '19px',
                    borderRadius: '2px',
                    //background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
                    background: this.state.background,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                    width: '100px',
                    marginLeft:'15px',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                    width: '100px',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '10px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <div className="form-group row ">
                {this.data.label && <label className="control-label col-md-2" >{this.data.label}</label>}
                <div style={styles.swatch}
                    onClick={this.handleClick}>
                    <div style={styles.color} />
                </div>
                {(this.state.displayColorPicker && this.state.swUpdate)?
                    <div style={styles.popover}>
                        <div style={styles.cover}
                            onClick={this.handleClose} />
                        <PhotoshopPicker
                            id={this.data.id}
                            color={this.state.background}
                            onChangeComplete={this.handleChangeComplete}
                            onAccept={this.handleClose}
                            onCancel={() => this.handleColorReset()}
                            onChange={this.handleChange}
                        />
                    </div> : null}

            </div>
        )
    }

    renderButton = () => {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        //        <ChromePicker />
        //console.log("swUpdate",this.state.swUpdate)
        return (<>
            <button onClick={this.handleClick} style={{ backgroundColor: this.state.background }}>Pick Color</button>
            {(this.state.displayColorPicker && this.state.swUpdate) ? <div style={popover}>
                <div stylex={cover} onClick={this.handleClose} />
                <PhotoshopPicker
                    id={this.data.id}
                    color={this.state.background}
                    onChangeComplete={this.handleChangeComplete}
                    onAccept={this.handleClose}
                    onCancel={() => this.handleColorReset()}
                    onChange={this.handleChange} />
                {/*<SketchPicker
                    color={this.state.background}
                    onChangeComplete={this.handleChangeComplete}
                    onChange={this.handleChange}
                />
                <SwatchesPicker
                    color={this.state.background}
                    onChangeComplete={this.handleChangeComplete}
                    onChange={this.handleChange}
        />*/}
            </div> : null}

        </>
        )
    }
}
//<button onClick={this.handleClick}>Pick Color</button>

export default HBoxColor;