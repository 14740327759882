import React,{Component} from "react";
import { Route  } from "react-router-dom";
import Api  from "../../ApiTurnos";
import HMainRouter  from "global/components/Page/MenuLTE/HMainRouter";


import FTurnos from "modTurnos/FTurnos";
import FDiaMes from "modTurnos/FDiaMes";
import FDiaMesGen from "modTurnos/FDiaMesGen";
import FDatPer from "modTurnos/FDatosCfg";


class MainRouterMod  extends Component{
      constructor(props) {
            super(props);
            this.data=props;
          }      
   render() {
      return (<HMainRouter isNotHome={true}>
                  <Route exact path="/" element={<FTurnos/>} />
                  <Route exact path={Api.diaMesAnoRoute} element={<FDiaMes/>} />
                  <Route exact path={Api.generaMesAnoRoute} element={<FDiaMesGen/>} />
                  <Route exact path={Api.cgfModuloRoute} element={<FDatPer/>} />

                  <Route exact path="/1" render={() => <DatosFTurnos  onComunica={this.data.onComunica}/>}  />
                  <Route exact path={Api.diaMesAnoRoute}  render={() => <DatosFTurnos  onComunica={this.data.onComunica}/>} />
               </HMainRouter>
         );
      }
}
/*
<Route exact path={Api.datosCompra+ "33"} element={<DatosCompra />} />
<Route exact path={Api.datosCompra + "22"} component={DatosCompra} />
*/
export default MainRouterMod;