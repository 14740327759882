import React, { Component } from "react";
import apiRuta from "global/utils/funRuta";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ApiGlo from "global/ApiGlobal";
import ShowLoading from "global/components/ShowLoading";
import ShowError from "global/components/ShowError";
import HFormUser from "global/components/Page/Forms/HFormUser";
class FrConfigSystem extends HFormUser {
    componentDidMount() {this.readApi();}
    readApi = ()=>{
        this.setState({loading: true})
        this.post(ApiGlo.modulosDisponibles)
           .then(data=>{this.stateHarrys(this,data)})    
    }  
    btEdit=(row)=>{
        apiRuta.toRutaAdd(this,"Editar",ApiGlo.configEmpresaRoute,{ detKey: row})
    }
    btCheck=(row)=>{
        this.setState({loading: true})
        this.post(ApiGlo.modulosCheck,{detKey:row})
           .then(data=>{this.stateHarrys(this,data)})    
    }
    render() {
        if (this.state.loading) return (<ShowLoading/>)
        const titulos = [  
              { id:"name" , label:"Id"   ,align:"left"}
            //, { id:"codigo" , label:"Código"    ,align:"left",swFiltro:false}
            , { id:"descripcion" , label:"Nombre"   ,align:"left"}
            , { id:"val_mensual" , label:"Valor"   ,align:"right"}
            , { id:"fultpago" , label:"Ult.Pago"   ,align:"left"}
            , { id:"finicio" , label:"Activo"   ,align:"left"}
            , { id:"ffinaliza" , label:"Vencimiento"   ,align:"left"}
            , { id:"ch_activo" , label:"Selecc"   ,align:"left",accion:this.btCheck}
            , { id:"bt_Edit", label:"Edit",align:"center",accion:this.btEdit ,clsIcono:"fas fa-edit"}
            
         ]
         let total = 0
         if (this.state.resObj && this.state.resObj.obj && this.state.resObj.obj.regTot)
             total = this.state.resObj.obj.regTot[0].total_mes
        return (<>
                <ShowError  msg={this.state.resObj.msg}/>
                 <ContainerRightTable 
                        tituloContainer={'Módulos Habilitados ' + total}
                        titulos={titulos}
                        swBarraHeader={false}
                        resObj={this.state.resObj.obj}
                        contexto={this}
                        bt1Click={this.readApi}
                        bt1Icon="fas fa-sync"
                        >  
                </ContainerRightTable>  
                </>
        );
    }
}
export default FrConfigSystem;
