import React, { Component } from "react";

import apiRuta from "global/utils/funRuta";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ApiGlo from "global/ApiGlobal";
import HFormUser from "global/components/Page/Forms/HFormUser";
class FrConfigUsuario extends HFormUser {
    componentDidMount() {this.readApi();}
    readApi = ()=>{
        this.setState({loading: true})
        this.post(ApiGlo.modulosDisponibles)
           .then(data=>{this.stateHarrys(this,data)})    
    }  
    btEdit=(row)=>{
        apiRuta.toRutaAdd(this,"Editar",ApiGlo.configEmpresaRoute,{ detKey: row})
    }
    render() {
        const titulos = [  
              { id:"name" , label:"Id"   }
            , { id:"codigo" , label:"Código"    ,swFiltro:false}
            , { id:"descripcion" , label:"Nombre"   }
            , { id:"finicia" , label:"Inicio"   }
            , { id:"factivo" , label:"Activo"   }
            , { id:"ffinaliza" , label:"Finaliza"   }
            , { id:"ch_activo" , label:"activo"   }
            , { id:"bt_Edit", label:"Edit",align:"center",accion:this.btEdit ,clsIcono:"fas fa-edit"}
            
         ]    
        return (<ContainerRightTable 
                        tituloContainer='Usuarios'
                        titulos={titulos}
                        swBarraHeader={false}
                        resObj={this.state.resObj}
                        contexto={this}
                        bt1Click={this.readApi}
                        bt1Icon="fas fa-sync"
                        >  
                </ContainerRightTable>  

        );
    }
}
export default FrConfigUsuario;
