import React,{Component} from 'react';
class HBoxMsg extends Component{
	constructor(props) {
		super(props);
		this.data=props;
  }
  componentWillUpdate(nextProps, nextState){
    this.data=nextProps;
  }
  render =()=>{
	    return (<font color="red"><b>
                   { this.data.msg && 
                    <p>{ this.data.msg }</p>
                   }</b>
              </font>      
      );
 	}  
}    
export default  HBoxMsg;