import React, { Component } from 'react';
class Footer extends Component {
	constructor(props) {
		super(props);
		this.data = props;
	}
	render = () => (
		<footer className="main-footer">
			<strong>Copyright &copy; 2005-{new Date().toISOString().substr(0, 4) } <a href="http://www.harrys.cl" target="_blank" rel="noopener noreferrer" >Harrys Inform&aacute;tica</a>.</strong> All rights reserved. 
			<div className="float-right d-none d-sm-inline-block">
				<b>Version</b> 3.0
			</div>
		</footer>
	)
}
export default Footer;
