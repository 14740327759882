import React, { Component } from "react";

class ProfileGallery extends Component {
    constructor(props) {
        super(props);
        this.data = props
    }
    render() {
        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mt-3 mb-4">
                    <h3 className="mb-3 mb-sm-0 fw-semibold d-flex align-items-center">Gallery <span
                        className="badge text-bg-secondary fs-2 rounded-4 py-1 px-2 ms-2">12</span></h3>
                    <form className="position-relative">
                        <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh"
                            placeholder="Search Friends" />
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y text-dark ms-3"></i>
                    </form>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="card hover-img overflow-hidden rounded-2">
                            <div className="card-body p-0">
                                <img src="/FrWork/adModern/assets/images/products/s1.jpg" alt="" className="img-fluid w-100 object-fit-cover"
                                    style={{ height: '220px' }} />
                                <div className="p-4 d-flex align-items-center justify-content-between">
                                    <div>
                                        <h6 className="fw-semibold mb-0 fs-4">Isuava wakceajo fe.jpg</h6>
                                        <span className="text-dark fs-2">Wed, Dec 14, 2023</span>
                                    </div>
                                    <div className="dropdown">
                                        <a className="text-muted fw-semibold d-flex align-items-center p-1"
                                            href="void(0)" role="button" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="ti ti-dots-vertical"></i>
                                        </a>
                                        <ul className="dropdown-menu overflow-hidden">
                                            <li><a className="dropdown-item" href="void(0)">Isuava
                                                wakceajo fe.jpg</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>)
    }
}

export default ProfileGallery;
