import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import Api from "ApiTurnos";
import apiRuta from "global/utils/funRuta"
import ApiGl from "global/ApiGlobal"
import ShowLoading from "global/components/ShowLoading";
import ShowError from "global/components/ShowError";
import Calendar from 'global/components/Page/Calendar/Calendario_01'
import DataTable from "global/components/Page/DataTable/DataTableMaterial"

class FTurnos extends HFormUser {
    pAnno = 0; pMes = 0;

    componentDidMount() {
        this.readMes(this.pAnno, this.pMes, 0)
    }
    readMes = (pAnno, pMes, pSuma) => {
        this.setState({ loading: true });
        this.post(Api.turnosMis, { pAnno: pAnno, pMes: pMes, pSuma: pSuma })
            .then(data => {
                this.stateHarrys(this, data)
                this.pMes = !data.obj?0:data.obj.selMes
                this.pAnno = !data.obj?0:data.obj.selAnno
            })
    }
    genInfMes = (swPlata) => {
        this.post(Api.turnosInfMes, { pAnno: this.pAnno, pMes: this.pMes, pSuma: 0, swPlata: swPlata ? 1 : 0 })
            .then(data => {
                window.open(data.obj.fileHttpNamePdf, '_blank', 'toolbar=0,location=0,menubar=0');
            })
    }
    mesSigClick = () => {
        this.readMes(this.pAnno, this.pMes, 1)
    }
    mesAntClick = () => {
        this.readMes(this.pAnno, this.pMes, -1)
    }
    mesActClick = (pMes) => {
        this.readMes(this.pAnno, pMes, 0)
    }
    dayClick = (row) => {
        //console.log("row", row)
        if (row.horario.length == 0)
            apiRuta.toRutaAdd(this, "Generar", Api.generaMesAnoRoute, { detKey: row })
        else
            apiRuta.toRutaAdd(this, "Detalle", Api.diaMesAnoRoute, { detKey: row })
    }
    render = () => {
        if (this.state.loading) return (<ShowLoading />)
        const titulosDeta = [
            { id: "fecha", label: "Fecha", align: "right" }
            , { id: "hor_cantidad", label: "Horas", align: "right" }
            , { id: "hor_valor_dsp", label: "V.H", align: "right" }
            , { id: "hor_total_dsp", label: "Total", align: "right" }
            , { id: "hor_ini_dsp", label: "H.Ini", align: "left" }
            , { id: "hor_fin_dsp", label: "H.Fin", align: "left" }
        ]
        const titulosInfo = [
            { id: "dia", label: "Dia", align: "right" }
            , { id: "dTurDsp", label: "Hor", align: "right" }
            , { id: "dSemDsp", label: "Día", align: "left" }
            , { id: "cantidad", label: "Horas", align: "right" }
            , { id: "tar_1", label: "Diurno", align: "right" }
            , { id: "tar_2", label: "Extra", align: "right" }
            , { id: "tar_3", label: "Nocturno", align: "right" }
            , { id: "tar_4", label: "Festivo", align: "right" }
            , { id: "total", label: "Total", align: "right" }
            , { id: "hor_ini_dsp", label: "H.Ini", align: "right" }
            , { id: "hor_fin_dsp", label: "H.Fin", align: "right" }
        ]
        const reg= this.state.resObj.obj
        return (<>
            <ShowError msg={this.state.resObj.msg} />
            
            <div className="login-register-area mb-60px" style={{ margin: '0px', padding: '0px' }}>
                <div className="container1">
                    <div className="container-inner1">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 ms-auto me-auto">
                                <div className="login-register-wrapper">
                                    {this.context.dataUser.varUser.idUser == 0
                                        && (<div className="login-register-tab-list nav">
                                            <Link to={ApiGl.loginRoute}
                                                className="btn btn-outline-primary ">Conectarse
                                            </Link>
                                        </div>)}
                                    {(this.context.dataUser.varUser.idUser != 0)
                                        && reg && reg
                                        && (reg && reg.recHor.length == 0)
                                        && (<div className="login-register-tab-list nav">
                                            <Link to={Api.cgfModuloRoute}
                                                className="btn btn-outline-primary ">Configurar Horario
                                            </Link>
                                        </div>)}
                                    <div className="login-register-tab-list nav">
                                        <a className="active" data-bs-toggle="tab" href="#panelCale">
                                            <h4>Mes</h4>
                                        </a>
                                        <a data-bs-toggle="tab" href="#panelInfo">
                                            <h4>Inf.</h4>
                                        </a>
                                        <a data-bs-toggle="tab" href="#panelDeta">
                                            <h4>Det.</h4>
                                        </a>
                                    </div>
                                    <div className="tab-content">
                                        {/******************************* */}
                                        <div id="panelCale" className="tab-pane active" style={{ backgroundColor: 'white' }}>
                                            <Calendar titulo1="Mis Turnos"
                                                dataCalendar={reg && reg.horario}
                                                dataMes={reg && reg.mesAct}
                                                pMes={reg && reg.selMes}
                                                pAnno={reg && reg.selAnno}
                                                dayClick={this.dayClick}
                                                mesSigClick={this.mesSigClick}
                                                mesAntClick={this.mesAntClick}
                                                mesActClick={this.mesActClick}
                                            />
                                        </div>
                                        {/******************************* */}
                                        <div id="panelInfo" className="tab-pane ">
                                            <DataTable
                                                tituloContainer={'Datos Por Día : '}
                                                contexto={this}
                                                titulos={titulosInfo}
                                                rowsPage="10"
                                                resObj={reg && reg.recInf}
                                                rows={reg && reg.recInf.rows}
                                                swBarraHeader={false}
                                                bt1Click={() => { this.genInfMes(false) }}
                                                bt1Icon="fa fa-file-pdf"
                                                bt2Click={() => { this.genInfMes(true) }}
                                                bt2Icon="fa fa-dollar"

                                            />


                                        </div>
                                        {/******************************* */}
                                        <div id="panelDeta" className="tab-pane ">
                                            <DataTable
                                                tituloContainer={'Detalle Por Día : '}
                                                contexto={this}
                                                titulos={titulosDeta}
                                                resObj={reg && reg}
                                                rows={reg && reg.rows}
                                                swBarraHeader={false}

                                            />


                                        </div>
                                        {/******************************* */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }

}
export default WithNavigate(FTurnos);


