const registrateRoute = "/auth/new-user-solicitar"
const registrateFinRoute = "/auth/new-user"
const readLogin = "/auth/readLogin"
const loginRoute = "/auth/signin"
const userTareasRoute = '/admin/user/tareas'
const userMsgRoute = '/admin/user/mensajes'
const singOutRoute = "/auth/signout"
const forgotPasswordRoute='/auth/forgot-password'
const forgotPassword='/auth/forgot-password'


const profileRoute = "/admin/profile"
const profileEditRoute = "/admin/profileEdit"
const profileEditCelRoute = "/admin/profileEditCel"
const profileRead = "/admin/profileRead"
const profileSaveSet = "/admin/profileSaveSetting"
const profileSaveDir = "/admin/profileSaveDirec"
const profileSaveCel = "/admin/profileSaveCelu"


const configRoute = '/admin/config'
const configEmpresaRoute = '/admin/config/empresa'
const modulosDisponibles = '/admin/config/modulos'
const modulosCheck = '/admin/config/moduloscheck'
const empresaCfg = '/admin/config/empresacfg'
const empresaCfgActivar = '/admin/config/empresacfgactivar'

const searchRoute = '/general/searchRoute'
const deseosRoute = '/carrito/deseos'

const headerTopNav = '/global/free/header/headertopnav'
const WWWSecciones = '/global/free/www_secciones'
const categoriaRoute = '/carrito/categoria'
const comprarRoute = '/carrito/checkout'
const comprarFactRoute = '/carrito/datosCompraFact'

module.exports = {
    headerTopNav,
    WWWSecciones,
    categoriaRoute,
    
    //******************************
    registrateRoute,
    registrateFinRoute,
    readLogin,
    loginRoute,
    profileRoute,
    profileEditRoute,
    profileEditCelRoute,
    profileRead,
    profileSaveSet,
    profileSaveDir,
    profileSaveCel,
    forgotPasswordRoute,
    forgotPassword,
    singOutRoute,
    
    searchRoute,
    deseosRoute,
    comprarRoute,
    comprarFactRoute,
    configRoute,
    
    userTareasRoute,
    userMsgRoute,
    configEmpresaRoute,
    modulosDisponibles,
    modulosCheck,
    empresaCfg,
    empresaCfgActivar,
}