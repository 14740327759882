const apiHelp ={

    getTextSearch () {
        let xBox = document.querySelectorAll(".searchHelpHarrys"); 

        let i;
        let paramSearch=[]
        for (i = 0; i < xBox.length; i++) {
            //console.log(xBox[i])
            //xBox[i].style.backgroundColor = "red";
            let stName = ""+ xBox[i].id
            paramSearch.push({"key":stName,"value":xBox[i].value})
        }
//        console.log("paramSearch",paramSearch)
        return paramSearch
    }
}

export default apiHelp;