import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";
import apiRuta from "global/utils/funRuta";
//import HFormUser from "global/components/Page/Forms/HFormUser";
import { Route } from 'react-router-dom'
class LoginSignIn extends Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            redirectToReferer: false,
        };

    }
    clickSalir = event => {
        event.preventDefault();
        //this.setState({ redirectToReferer: true });
        ApiAuth.signout().then(data => {
            //this.setState({ redirectToReferer: true });
            window.location.href = '/';
            //apiRuta.toRutaMenu(this,"Salir","/home",null)
            //history.push('/new-location')
            /*
            if (!data.ok) {
                this.setState({ redirectToReferer: false });
            } else {
                this.setState({ redirectToReferer: true });
            }*/
        });
    }
    clickChgPass = event => {
        event.preventDefault();
        this.setState({ redirectToReferer: true });
        ApiAuth.signout().then(data => {
            if (!data.ok) {
                this.setState({ redirectToReferer: false });
            } else {
                this.setState({ redirectToReferer: true });
            }
        });
    }

    render = () => {
        const nombre = localStorage.getItem('login_role');
        const descripcion = 'Descharrys';
        if (this.state.redirectToReferer) {
            this.setState({ redirectToReferer: false });
            return <Navigate to="/" replace={true} />;
        }
        return (<>
            <a className="nav-link user-profile dropdown-toggle"
                data-toggle="dropdown"
                id="navbarDropdown"
                href="#">Sesión
                {/* <i className="far fa-bell"></i>
                        <span className="badge badge-warning navbar-badge"></span> */}
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-header">{nombre}</span>
                <div className="dropdown-divider"></div>
                <ul>
                    <li className="user-header bg-light-blue">
                        <img src="/FotosImg/avatar3.png" className="img-circle" alt="" />
                        <p>
                            <br /> {descripcion} <br />
                        </p>
                    </li>
                    <li className="user-footer">
                        <button onClick={this.clickSalir} className="btn btn-raised btn-primary">salir</button>
                        <Link to={ApiGl.profileRoute} className="btn btn-raised btn-successbtn-flat float-right ">Mis Datos</Link>
                    </li>
                </ul>
            </div>
        </>

        )
    }
}
export default LoginSignIn;