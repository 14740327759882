import HBoxtObj from "./HBoxObj";
import HBoxObjBase from "./HBoxObjBase";

class HBoxTextMail extends HBoxtObj{
  render =()=>{
	    return (  <HBoxObjBase type="text" 
                    placeholder={this.data.placeholder || "Email" }
                    pattern={this.data.pattern || "^[_A-Za-z0-9-+]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,})$"}
                    patternMsgErr={this.data.patternMsgErr || "Error en Formato del Mail"}
                    {...this.props}
                   />
      );
 	}  
 
}    
export default  HBoxTextMail;