import React, { Component } from "react";
import ApiAuth from "../auth";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ShowError from "../components/ShowError";
import queryString from 'query-string'
import BoxCenter from "./BoxCenter";
import './NewUser.css'
class NewUser extends HFormUser {
    state = {
        data: [],
        loading: false,
        message: "",
        //token:location.search.substring(1),
        error: ""
    };

    newUser = e => {
        e.preventDefault();
        this.setState({ message: "", error: "" });
        if (document.getElementById("txUserClave").value==""){
            this.setState({ error: "Ingrese Clave", loading: false });
            return
        }
        if (document.getElementById("txUserReClave").value==""){
            this.setState({ error: "Ingrese Confirmar Clave", loading: false });
            return
        }
        var obData = {
            email: document.getElementById("txEmail").value
            , token: document.getElementById("txToken").value
            , clave: document.getElementById("txUserClave").value
            , reClave: document.getElementById("txUserReClave").value
        }
        ApiAuth.newUser(this, obData).then(data => {
            if (!data.ok) {
                this.setState({ error: data.msg });
            } else {
                this.setState({ message: data.msg });
            }
        });
    };

    render() {
        let queryParam = queryString.parse(location.search);
        return (
            <BoxCenter titulo="Registrarse">


                <form action="#" method="post">
                    <ShowError
                        msg={this.state.message}
                        err={this.state.error} />
                    <div className="form-group mt-3 mb-3">
                        <textarea
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="Token"
                            name="txToken"
                            id="txToken"
                            rows="7"
                            value={queryParam.token}
                        />
                    </div>
                    <div className="mb-3">
                        <div className="position-relative form-group">
                            <div className="input-group">
                                <input type="text"
                                    id="txEmail"
                                    disabled
                                    value={queryParam.mail}
                                    placeholder="Email del Usuario"
                                    className="form-control" />
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="position-relative form-group">
                            <div className="input-group">
                                <input type="password" id="txUserClave"
                                    placeholder="Ingrese la Clave"
                                    className="form-control"
                                    style={{ width: "70%" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="position-relative form-group">
                            <div className="input-group">
                                <input type="password" id="txUserReClave"
                                    placeholder="Confirme la Clave"
                                    className="form-control"
                                    style={{ width: "70%" }}
                                />
                            </div>
                        </div>
                    </div>                    
                    <div className="mb-3">
                        <a onClick={this.newUser}
                            className="btn btn-primary w-100 py-8 mb-4 rounded-2">
                            Registrarse
                        </a>
                    </div>
                </form>

            </BoxCenter>
        );
    }
}

export default NewUser;
