import React, { Component } from "react";

class ProfileFriends extends Component {
    constructor(props) {
        super(props);
        this.data = props
    }
    render() {
        return (<>
            <div className="d-sm-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="mb-3 mb-sm-0 fw-semibold d-flex align-items-center">Friends <span
                    className="badge text-bg-secondary fs-2 rounded-4 py-1 px-2 ms-2">20</span></h3>
                <form className="position-relative">
                    <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh"
                        placeholder="Search Friends" />
                    <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y text-dark ms-3"></i>
                </form>
            </div>
            <div className="row">
                <div className="col-sm-6 col-lg-4">
                    <div className="card hover-img">
                        <div className="card-body p-4 text-center border-bottom">
                            <img src="/FrWork/Avatar/user-1.jpg" alt="" className="rounded-circle mb-3" width="80"
                                height="80" />
                            <h5 className="fw-semibold mb-0">Betty Adams</h5>
                            <span className="text-dark fs-2">Medical Secretary</span>
                        </div>
                        <ul
                            className="px-2 py-2 bg-light list-unstyled d-flex align-items-center justify-content-center mb-0">
                            <li className="position-relative">
                                <a className="text-primary d-flex align-items-center justify-content-center p-2 fs-5 rounded-circle fw-semibold"
                                    href="void(0)">
                                    <i className="ti ti-brand-facebook"></i>
                                </a>
                            </li>
                            <li className="position-relative">
                                <a className="text-danger d-flex align-items-center justify-content-center p-2 fs-5 rounded-circle fw-semibold "
                                    href="void(0)">
                                    <i className="ti ti-brand-instagram"></i>
                                </a>
                            </li>
                            <li className="position-relative">
                                <a className="text-info d-flex align-items-center justify-content-center p-2 fs-5 rounded-circle fw-semibold "
                                    href="void(0)">
                                    <i className="ti ti-brand-github"></i>
                                </a>
                            </li>
                            <li className="position-relative">
                                <a className="text-secondary d-flex align-items-center justify-content-center p-2 fs-5 rounded-circle fw-semibold "
                                    href="void(0)">
                                    <i className="ti ti-brand-twitter"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </>)
    }
}

export default ProfileFriends;
