import React, { Component } from "react";
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";
import apiRuta from "global/utils/funRuta"
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import DataTable from "global/components/Page/DataTable/DataTableMaterial";
import "./Profile.css";


class ProfileSetDir extends HFormUser {
    constructor(props) {
        super(props);
        this.state = { isVisible: false ,reg:null}
    }
    btEdit = (row) => {
        apiRuta.toRutaAdd(this, "Dirección", ApiGl.profileEditRoute, { detKey: row })
    }
    btAdd = () => {
        apiRuta.toRutaAdd(this, "Dirección", ApiGl.profileEditRoute, { detKey: {iddir:-1} })
    }
    render() {
        const titDir = [
            { id: "direccion", label: "Dirección", style: { width: '70px' } }
            , { id: "nro", label: "Nro.", style: { width: '120px' } }
            , { id: "depto", label: "Depto", style: { width: '120px' } }
            , { id: "codpostal", label: "Cod.Postal", style: { width: '120px' } }
            , { id: "telefono", label: "Teléfono", style: { width: '120px' } }
            , { id: "fax", label: "Fax", style: { width: '120px' } }
            , { id: "dircompleta", label: "Dirección", style: { width: '120px' } }
            , { id: "dirtip_value", label: "Tipo", style: { width: '120px' } }
            , { id: "bt_prin", label: "Editar", accion: this.btEdit, clsIcono: "fas fa-hand-pointer" }

        ]
        //console.log("Dire:", this.data.regDir,this.state.reg)
        return (<>
            <div className="container rounded bg-white mt-5 mb-5">
                <div className="row">
                    <div className="col-md-3 border-right">
                        <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img className="rounded-circle mt-5" width="150px"
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                            <span className="font-weight-bold">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_nombres') : "samanta@harrys.com"}</span>
                            <span className="text-black-50">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_email') : "samanta@harrys.com"}</span><span> </span></div>
                    </div>
                    <div className="col-md-9 border-right">
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="text-right">Mis Direcciones</h4>
                            </div>
                            <div className="row mt-3">
                                <DataTable key="kk_img"
                                    rows={this.data.regDir ? this.data.regDir : []}
                                    titulo="Direcciones"
                                    titulos={titDir}
                                    bt1Click={this.btAdd}
                                    bt1Icon="fa fa-plus"
                                    filasPorPagina={10}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default WithNavigate(ProfileSetDir);

