import React, { Component } from 'react';




//import UserContext from 'global/context/user/UserContext';

class HeaderMsg extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        //modal-sm,modal-lg
        //modal-dialog-scrollable,modal-dialog-centered,modal-fullscreen,modal-fullscreen-sm-down,modal-fullscreen-md-down
        //modal-fullscreen-lg-down,modal-fullscreen-xl-down,modal-fullscreen-xxl-down
        return (
            <div id="primary-header-modal" className="modal fade" tabIndex="-1"
                aria-labelledby="msg-modal-head" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-smX">
                    <div className="modal-content">
                        <div
                            className="modal-header modal-colored-header bg-primary text-white">
                            <h4 className="modal-title" id="msg-modal-head">
                                Modal Heading
                            </h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body" id="msg-modal-body">
                            <h5 className="mt-0">Primary Background</h5>
                            <p>
                                Cras mattis consectetur purus sit amet
                                fermentum. Cras justo odio, dapibus ac facilisis
                                in, egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.
                            </p>
                            <p>
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.
                            </p>
                        </div>
                        <div className="modal-footer" id="msg-modal-footer">
                            <button type="button" className="btn btn-success"
                                data-bs-dismiss="modal">
                                Ok
                            </button>
                            {/* <button type="button"
                                className="btn bg-primary-subtle text-primary font-medium">
                                Save changes
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default HeaderMsg;