import React from 'react';
import CtnSysAero from "./CtnSysAero";
import CtnSysLte from "./CtnSysLte";
import CtnSysModer from "./CtnSysModer";
import CtnSysArchi from "./CtnSysArchi";


const Container = ({ history }) => (<>
    {(!process.env.REACT_APP_FRAMEWORK ) && <CtnSysModer />}
    {(process.env.REACT_APP_FRAMEWORK == "MODER") && <CtnSysModer />}
    {(process.env.REACT_APP_FRAMEWORK == "ALTE") && <CtnSysLte />}
    {(process.env.REACT_APP_FRAMEWORK == "AERO") && <CtnSysAero />}
    {(process.env.REACT_APP_FRAMEWORK == "ARCHI") && <CtnSysArchi />}
</>);
export default Container;
