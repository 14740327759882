import HBoxtObj from "./HBoxObj";
import HBoxObjBase from "./HBoxObjBase";

class HTextNum extends HBoxtObj{
  render =()=>{
	    return (  <HBoxObjBase 
                    type="number" 
                    style={this.data.style } 
                    styleDiv={{width:"150px"}} 
                    pattern={this.data.pattern || "^[0-9]+$"} 
                    minLength={this.data.minLength || 0} 
                    maxLength={this.data.maxLength || 7}  
                    {...this.props}
                   />
      );
 	}  
 
}    
export default  HTextNum;