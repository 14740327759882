import React, { Component } from 'react';
import HBoxMsg from "./HBoxMsg";
import HBoxHelp from "./HBoxHelp";
//https://towardsdatascience.com/passing-data-between-react-components-parent-children-siblings-a64f89e24ecf
/*
<script>
  input.onpaste = function(event) {
    alert("paste: " + event.clipboardData.getData('text/plain'));
    event.preventDefault();
  };

  input.oncut = input.oncopy = function(event) {
    alert(event.type + '-' + document.getSelection());
    event.preventDefault();
  };
</script>
*/

//https://es.reactjs.org/docs/react-component.html
//https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/RegExp
//https://css-tricks.com/demonstrating-reusable-react-components-in-a-form/
//https://es.reactjs.org/docs/forms.html    => checked, select
//https://medium.com/javascript-in-plain-english/creating-a-generic-text-input-component-with-react-886e0cf90016
//https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html

/*
import styled from 'styled-components';
const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  color: palevioletred;
  background: papayawhip;
  border: none;
  border-radius: 3px;
`;
*/
//componentWillMount => Antes que sea montado el Doom
//componentDidMount => Después que sea montado el Doom

// Solo cuando se actualiza props
//componentWillReceiveProps => esperará a recibir nuevas props
//componentWillUpdate => Justo antes del render
//shouldComponentUpdate ==> si retornamos false, cancelamos render
//componentDidUpdate  ==> despues de actualizado
//componentWillUnmount
class HBoxObjBase extends Component {
    swRutea = false
    constructor(props) {
        super(props);
        // this.listRef = React.createRef();
        this.data = props;
        if (this.swRutea) console.log("txdata", this.data)

        this.regp = this.data.pattern || "^[a-zA-Z0-9]+$"
        this.condition = new RegExp(this.regp);
        this.state = {
            value: this.data.value || "",
            errors: {},
        };
        if (this.swRutea) console.log("Contructor id:", this.data.id, this.data.value)
    }
    componentDidMount() {
        if (this.swRutea) console.log("DidMount id:", this.data.id, this.state.value, this.data.value)
        if (this.data.required) this.validaVarlor(this.data.value)
    }
    // componentWillUpdate(nextProps, nextState) { this.data = nextProps; console.log("Base nextPropsValue==>*******************", nextProps, nextState) }
    // setNativeValue(element, value) { if (this.swRutea) console.log("setNativeValue==>*******************", element, value) }
    // componentWillReceiveProps(nextProps) { if (this.swRutea) console.log("componentWillReceiveProps==>*******************", nextProps) }
    // handleSubscriptionChange = dataSource => { if (this.swRutea) console.log("handleSubscriptionChange==>*******************", dataSource) }
    // componentWillMount() { if (this.swRutea) console.log("componentWillMount==>*******************") }
    // shouldComponentUpdate() { if (this.swRutea) console.log("shouldComponentUpdate==>*******************"); return true; }
    // componentDidUpdate(prev_props, prev_state) { if (this.swRutea) console.log("componentDidUpdate==>*******************", prev_props, prev_state) }
    // getSnapshotBeforeUpdate(prevProps, prevState) { if (this.swRutea) console.log("getSnapshotBeforeUpdate==>*******************", prevProps, prevState);return null; }
    onKeyPress = () => event => {
        //const keyCode = event.keyCode || event.which;
        //const keyValue = String.fromCharCode(keyCode);
        //console.log("onKeyPress" + keyCode + ":"+keyValue +":"+ this.regp)
        //if (/\+|-/.test(keyValue))
        //const regp=  this.data.pattern || this.pattern
        //const condition = new RegExp(regp);
        //if (!this.condition.test(keyValue))       event.preventDefault();
    }
    setPattern = (stPattern) => { this.data.pattern = stPattern }
    //setValue = (stValor) => event => {
    setValue = (stValor) => {
        //console.log("stValor setValue *************************:", stValor)
        this.setState({ value: stValor })
        if (this.swRutea) console.log("SetValue Base id:", this.data.id, this.state.value, this.data.value)
        this.validaVarlor(stValor)
    }
    inputValue = (name) => event => {
        if (this.swRutea) console.log("inputValue id:", this.data.id, this.state.value, this.data.value)
    }
    handleChange = (name) => event => {
        if (this.swRutea) console.log("handle id:", this.data.id, this.state.value, this.data.value)
        this.validaVarlor(event.target.value)
    };
    validaVarlor = (valor) => {
        if (this.swRutea) console.log("Valida id:", this.data.id, this.state.value, this.data.value)
        if (!valor) valor = ""
        //console.log("handleChange")
        let err = {};
        if (valor.length < this.data.minLength) {
            if (valor.length != 0 || this.data.required) err.msg = "Mínimo " + this.data.minLength + " caracteres";
        }
        else
            if (valor.length > this.data.maxLength) {
                err.msg = "Máximo " + this.data.maxLength + " caracteres";
            }
            else {
                //const regp=  this.data.pattern || this.pattern
                //const condition = new RegExp(regp);
                let val = this.condition.test(valor);
                //console.log("regp==>" + this.regp + ":" + valor + ":" + val + "::" + this.data.minLength)
                if (!val && this.data.minLength > 0)
                    err.msg = this.data.patternMsgErr || "Error en Formato";
            }
        //console.log("value=>",valor)
        //if (valor != null  && valor != "") 
        this.setState({ value: valor });
        this.setState({ errors: err }, () => {
            if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
                this.setState({ submitted: true });
            }
        });
    }
    //{this.renderTableData(this.data.titulos,this.data.rows,this.data.rqEdit,this.data.rqIns,this.data.rqUpd)}

    render = () => {
        if (this.swRutea) console.log("render id:", this.data.id, " value:", this.state.value, " data:", this.data.value)
        if (this.swRutea) console.log("render propsid:", this.data, " State:", this.state, " data:", this.data)
        //console.log("this.data.visibility",this.data.id,this.data.visibility)
        if (!this.data.visibility && this.data.visibility != null)
            return <input type="text"
                value={this.state.value || this.data.value || ""}
                style={{ visibility: 'hidden' }}
                onChange={this.handleChange("value")}
            />


        // let stCol=""
        // if (this.data.size){
        //     let col = Math.trunc((this.data.size / 8) + .5)
        //     if (col > 10) col = 10
        //     stCol = "col-md-" + col
        // }
        //console.log("Largo:",this.data.label, this.data.maxLength, "   col:", stCol)

        return (
            <div className={this.data.classCol || "form-group"}>
                <label className="labels" >{this.data.label}</label>
                <div >
                    <div className="input-group mb-3" style={this.data.styleDiv || {}}>
                        <input
                            id={this.data.id}
                            type={this.data.type || "text"}
                            // ref={this.listRef}
                            onChange={this.handleChange("value")}
                            input={this.inputValue("value")}
                            onKeyPress={this.onKeyPress()}
                            value={this.state.value}
                            disabled={this.data.disabled || false}
                            name={this.data.name || this.data.id}
                            style={this.data.style}
                            title={this.data.title || this.data.label}
                            placeholder={this.data.placeholder || this.data.title || this.data.label}
                            pattern={this.data.pattern || "^[a-zA-Z0-9 áéíóúÁÉÍÓÚñ.-_,]+$"}
                            size={this.data.size || 10}
                            minLength={this.data.minLength || 0}
                            maxLength={this.data.maxLength || 1}
                            className={" form-control required"}
                        />
                        {this.props.children}
                        {this.data.btIcon && (<div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-envelope"></span>
                            </div>
                        </div>)}

                        {this.data.sqlHelp && (<HBoxHelp
                            sqlHelp={this.data.sqlHelp}
                            onClickHelp={this.data.onClickHelp}
                        />)}
                    </div>
                    <HBoxMsg msg={this.state.errors.msg} />
                </div>
            </div>
        );
    }

}
export default HBoxObjBase;