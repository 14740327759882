import HBoxObj  from "./HBoxObj";
import HBoxObjBase  from "./HBoxObjBase";
class HTextBox extends HBoxObj{
  render =()=>{
	    return (     <HBoxObjBase type="text" 
                    pattern={this.data.pattern || "^[a-zA-Z0-9 áéíóúÁÉÍÓÚñ.-_,]+$"} 
                    // value={this.state.value || this.data.value||""}
                    minLength={this.data.minLength || 0} 
                    maxLength={this.data.maxLength || 50}  
                    {...this.props}
                   />
      );
 	}  
 
}    
export default  HTextBox;