import React, { Component } from 'react';
class HBoxObj extends Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.regp = this.data.pattern || "^[a-zA-Z0-9]+$"
        this.state = {
            value: this.data.value || "",
            errors: {},
        };
    }
}
export default HBoxObj;