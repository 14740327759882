import React from 'react';
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import apiRuta from "global/utils/funRuta";
import ApiGl from "global/ApiGlobal";
import ApiAuth from "global/auth";
import _menu from 'modMenu/_menu';
import HLink from "./HLinkMenu";
class HMenuVer extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
        super.addMenu(_menu)
    }
    menuClick = (stTitulo, stPath, objPar) => {
        apiRuta.toRutaFirst(this, stTitulo, stPath, objPar)
    }
    render() {

        return (<>{this.fuMenu(_menu.arMenu)}</>)
    }
    fuMenu(lMenu) {
        const lsRol = localStorage.getItem('login_role');
        const lsEmail = localStorage.getItem('login_email');
        //const lsDescrip = localStorage.getItem('login_descrip');
        const lsNombre = localStorage.getItem('login_nombres');
        const lsApePat = localStorage.getItem('login_ap_pat');
        const lsApeMat = localStorage.getItem('login_ap_mat');
        const lsNombreCompleto = lsNombre + " " + lsApePat + " " + lsApeMat

        return (
            <aside className="left-sidebar with-vertical">
                <div>
                    <div className="brand-logo d-flex align-items-center justify-content-between">
                        <a href="/"
                            className="text-nowrap logo-img">
                            <img src="/FrWork/adModern/assets/images/logos/dark-logo.svg" className="dark-logo" alt="Logo-Dark" />
                            <img src="/FrWork/adModern/assets/images/logos/light-logo.svg" className="light-logo" alt="Logo-light"
                                style={{ display: 'none' }} />
                        </a>
                        <a
                            className="sidebartoggler ms-auto text-decoration-none fs-5 d-block d-xl-none">
                            <i className="ti ti-x"></i>
                        </a>
                    </div>
                    <nav className="sidebar-nav scroll-sidebar simplebar-scrollable-y" data-simplebar="init">
                        <div className="simplebar-wrapper selected" style={{ margin: '0px -24px' }}>
                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer"></div>
                            </div>
                            <div className="simplebar-mask selected">
                                <div className="simplebar-offset selected" style={{ right: '0px', bottom: '0px' }}>
                                    <div className="simplebar-content-wrapper selected" tabIndex="0" role="region"
                                        aria-label="scrollable content" style={{ height: '100%', overflow: 'hidden scroll' }}>
                                        <div key="juu8712" className="simplebar-content selected" style={{ padding: '0px 24px' }}>
                                            <ul id="sidebarnav" className="in" key="juu8712897">
                                                {lMenu && lMenu.map((regNiv1, index) => {
                                                    return (
                                                        <>
                                                            {!regNiv1.ruta && (
                                                                <li key={"niiuv1_" + index} className="nav-small-cap">
                                                                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                                                    <span className="hide-menu">{regNiv1.titulo}</span>
                                                                </li>
                                                            )}
                                                            {regNiv1.ruta && (
                                                                <HLink key={"niv1_" + index}
                                                                    tituloArbol={regNiv1.titulo}
                                                                    to={regNiv1.ruta}
                                                                    btClickSelect={this.menuClick}
                                                                    icon={regNiv1.icon}
                                                                    label={regNiv1.titulo}
                                                                    nivel="1" />
                                                            )}
                                                                {regNiv1.menNiv && regNiv1.menNiv.map((regNiv2, index) => {
                                                                    return (<li key={"nivLi2_" + index} className="sidebar-item">
                                                                        <HLink key={"niv2_" + index}
                                                                            tituloArbol={regNiv2.titulo}
                                                                            to={regNiv2.ruta}
                                                                            btClickSelect={this.menuClick}
                                                                            icon={regNiv2.icon}
                                                                            label={regNiv2.titulo}
                                                                            nivel="2" />
                                                                        {regNiv2.menNiv && (<ul key={"niv3ul_" + index} aria-expanded="false" className="collapse first-level">
                                                                            {regNiv2.menNiv.map((regNiv3, index) => {
                                                                                return (
                                                                                    <li key={"ni2iu2v1_" + index} className="sidebar-item">
                                                                                        <HLink key={"niv3_" + index}
                                                                                            tituloArbol={regNiv3.titulo}
                                                                                            to={regNiv3.ruta}
                                                                                            btClickSelect={this.menuClick}
                                                                                            icon={regNiv3.icon}
                                                                                            label={regNiv3.titulo}
                                                                                            nivel="3" />
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>)}
                                                                    </li>
                                                                    )
                                                                })
                                                                }
                                                        </>)
                                                })}
                                                {false && this.fuMenuPrueba()}
                                                <div className="XXfixed-profile XXp-3 XXmx-4 mb-2 XXbg-secondary-subtle XXrounded XXmt-3" style={{width:'100%'}}>
                                                    <div className="hstack gap-12"  style={{width:'100%'}}>
                                                        <div className="john-img">
                                                            <img src={ApiAuth.isAuthenticated() ? "/FrWork/Avatar/avatar4.png" : "/FrWork/Avatar/avatar3.png"} className="rounded-circle" width="40" height="40"
                                                                alt="" />
                                                        </div>
                                                        <div className="john-title">
                                                            <h6 className="mb-0 fs-4 fw-semibold">{ApiAuth.isAuthenticated() ? lsNombreCompleto : "Samanta Esteben"}</h6>
                                                            <span className="fs-2">{ApiAuth.isAuthenticated() ? lsRol : "Diseñadora"}</span>
                                                        </div>
                                                        <button className="border-0 bg-transparent text-primary ms-auto" 
                                                        tabIndex="0" 
                                                        onClick={()=>this.props.navigate(ApiGl.singOutRoute)}
                                                        type="button"
                                                            aria-label="logout" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="logout">
                                                            <i className="ti ti-power fs-6"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="simplebar-placeholder" style={{ width: '269px', height: '3400px' }}></div>
                        </div>
                        <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                            <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div>
                        </div>
                        <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                            <div className="simplebar-scrollbar"
                                style={{ height: '180px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div>
                        </div>
                    </nav>
                </div>
            </aside>
        )
    }
    fuMenuPrueba() {
        return (
            <>

                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Home</span>
                </li>
                <li className="sidebar-item selected">
                    <a className="sidebar-link active"
                        href="/"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-aperture"></i>
                        </span>
                        <span className="hide-menu">Modern</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/index2.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-shopping-cart"></i>
                        </span>
                        <span className="hide-menu">eCommerce</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/index3.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-currency-dollar"></i>
                        </span>
                        <span className="hide-menu">NFT</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/index4.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-cpu"></i>
                        </span>
                        <span className="hide-menu">Crypto</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/index5.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-activity-heartbeat"></i>
                        </span>
                        <span className="hide-menu">General</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/index6.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-playlist"></i>
                        </span>
                        <span className="hide-menu">Music</span>
                    </a>
                </li>
                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Apps</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/app-calendar.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-calendar"></i>
                        </span>
                        <span className="hide-menu">Calendar</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/app-kanban.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-layout-kanban"></i>
                        </span>
                        <span className="hide-menu">Kanban</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/app-chat.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-message-dots"></i>
                        </span>
                        <span className="hide-menu">Chat</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/app-email.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-mail"></i>
                        </span>
                        <span className="hide-menu">Email</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/app-notes.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-notes"></i>
                        </span>
                        <span className="hide-menu">Notes</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/app-contact.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-phone"></i>
                        </span>
                        <span className="hide-menu">Contact Table</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/app-contact2.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-list-details"></i>
                        </span>
                        <span className="hide-menu">Contact List</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/app-invoice.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-file-text"></i>
                        </span>
                        <span className="hide-menu">Invoice</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/page-user-profile.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-user-circle"></i>
                        </span>
                        <span className="hide-menu">User Profile</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-chart-donut-3"></i>
                        </span>
                        <span className="hide-menu">Blog</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/blog-posts.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Posts</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/blog-detail.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Details</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-basket"></i>
                        </span>
                        <span className="hide-menu">Ecommerce</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/eco-shop.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Shop</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/eco-shop-detail.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Details</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/eco-product-list.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">List</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/eco-checkout.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Checkout</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">PAGES</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/page-pricing.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-currency-dollar"></i>
                        </span>
                        <span className="hide-menu">Pricing</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/page-faq.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-help"></i>
                        </span>
                        <span className="hide-menu">FAQ</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/page-account-settings.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-user-circle"></i>
                        </span>
                        <span className="hide-menu">Account Setting</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/landingpage/index.html"
                        aria-expanded="false">
                        <span>
                            <i className="ti ti-app-window"></i>
                        </span>
                        <span className="hide-menu">Landing Page</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-layout"></i>
                        </span>
                        <span className="hide-menu">Widgets</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/widgets-cards.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Cards</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/widgets-banners.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Banner</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/widgets-charts.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Charts</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/widgets-feeds.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Feed Widgets</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/widgets-apps.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Apps Widgets</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/widgets-data.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Data Widgets</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">UI</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-layout-grid"></i>
                        </span>
                        <span className="hide-menu">UI Elements</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-accordian.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Accordian</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-badge.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Badge</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-buttons.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Buttons</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-dropdowns.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Dropdowns</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-modals.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Modals</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-tab.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Tab</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-tooltip-popover.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Tooltip &amp; Popover</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-notification.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Notification</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-progressbar.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Progressbar</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-pagination.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Pagination</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-typography.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Typography</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-bootstrap-ui.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Bootstrap UI</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-breadcrumb.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Breadcrumb</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-offcanvas.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Offcanvas</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-lists.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Lists</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-grid.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Grid</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-carousel.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Carousel</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-scrollspy.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Scrollspy</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-spinner.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Spinner</span>
                            </a>
                        </li>
                        <li className="sidebar-item mb-3">
                            <a href="/modernize/dist/main/ui-link.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Link</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-cards"></i>
                        </span>
                        <span className="hide-menu">Cards</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-cards.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Basic Cards</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-card-customs.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Custom Cards</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-card-weather.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Weather Cards</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/ui-card-draggable.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Draggable Cards</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-components"></i>
                        </span>
                        <span className="hide-menu">Component</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/component-sweetalert.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Sweet Alert</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/component-nestable.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Nestable</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/component-noui-slider.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Noui slider</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/component-rating.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Rating</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/component-toastr.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Toastr</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Forms</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-file-text"></i>
                        </span>
                        <span className="hide-menu">Form Elements</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-inputs.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Forms Input</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-input-groups.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Input Groups</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-input-grid.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Input Grid</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-checkbox-radio.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Checkbox &amp; Radios</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-bootstrap-touchspin.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Bootstrap Touchspin</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-bootstrap-switch.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Bootstrap Switch</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-select2.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Select2</span>
                            </a>
                        </li>
                        <li className="sidebar-item mb-3">
                            <a href="/modernize/dist/main/form-dual-listbox.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Dual Listbox</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-qrcode"></i>
                        </span>
                        <span className="hide-menu">Form Addons</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-paginator.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Paginator</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-img-cropper.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Image Cropper</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-dropzone.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Dropzone</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-mask.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Form Mask</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-typeahead.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Form Typehead</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-alert-circle"></i>
                        </span>
                        <span className="hide-menu">Form Validation</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-bootstrap-validation.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Bootstrap Validation</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-custom-validation.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Custom Validation</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-file-pencil"></i>
                        </span>
                        <span className="hide-menu">Form Pickers</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-picker-colorpicker.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Colorpicker</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-picker-datetimepicker.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Datetimepicker</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-picker-bootstrap-rangepicker.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Bootstrap Rangepicker</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-picker-bootstrap-datepicker.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Bootstrap Datepicker</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-picker-material-datepicker.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Material Datepicker</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-dna"></i>
                        </span>
                        <span className="hide-menu">Form Editor</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-editor-ckeditor.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Ck Editor</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-editor-quill.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Quill Editor</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-editor-summernote.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Summernote Editor</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/form-editor-tinymce.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Tinymce Edtor</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-basic.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-archive"></i>
                        </span>
                        <span className="hide-menu">Basic Form</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-vertical.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-box-align-left"></i>
                        </span>
                        <span className="hide-menu">Form Vertical</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-horizontal.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-box-align-bottom"></i>
                        </span>
                        <span className="hide-menu">Form Horizontal</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-actions.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-file-export"></i>
                        </span>
                        <span className="hide-menu">Form Actions</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-row-separator.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-separator-horizontal"></i>
                        </span>
                        <span className="hide-menu">Row Separator</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-bordered.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-border-outer"></i>
                        </span>
                        <span className="hide-menu">Form Bordered</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-detail.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-file-description"></i>
                        </span>
                        <span className="hide-menu">Form Detail</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-striped-row.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-file-analytics"></i>
                        </span>
                        <span className="hide-menu">Striped Rows</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-floating-input.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-file-dots"></i>
                        </span>
                        <span className="hide-menu">Form Floating Input</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-wizard.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-files"></i>
                        </span>
                        <span className="hide-menu">Form Wizard</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/form-repeater.html"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-topology-star-3"></i>
                        </span>
                        <span className="hide-menu">Form Repeater</span>
                    </a>
                </li>
                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Tables</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-layout-sidebar"></i>
                        </span>
                        <span className="hide-menu">Bootstrap Table</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/table-basic.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Basic Table</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/table-dark-basic.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Dark Basic Table</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/table-sizing.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Sizing Table</span>
                            </a>
                        </li>
                        <li className="sidebar-item mb-3">
                            <a href="/modernize/dist/main/table-layout-coloured.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Coloured Table</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-air-conditioning-disabled"></i>
                        </span>
                        <span className="hide-menu">Datatables</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/table-datatable-basic.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Basic Initialisation</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/table-datatable-api.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">API</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/table-datatable-advanced.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Advanced Initialisation</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Charts</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-chart-pie"></i>
                        </span>
                        <span className="hide-menu">Apex Charts</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/chart-apex-line.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Line Chart</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/chart-apex-area.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Area Chart</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/chart-apex-bar.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Bar Chart</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/chart-apex-pie.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Pie Chart</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/chart-apex-radial.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Radial Chart</span>
                            </a>
                        </li>
                        <li className="sidebar-item mb-3">
                            <a href="/modernize/dist/main/chart-apex-radar.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Radar Chart</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Sample Pages</span>
                </li>

                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-file"></i>
                        </span>
                        <span className="hide-menu">Sample Pages</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/pages-animation.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Animation</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/pages-search-result.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Search Result</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/pages-gallery.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Gallery</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/pages-treeview.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Treeview</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/pages-block-ui.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Block-Ui</span>
                            </a>
                        </li>
                        <li className="sidebar-item mb-3">
                            <a href="/modernize/dist/main/pages-session-timeout.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Session Timeout</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Icons</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link sidebar-link"
                        href="/modernize/dist/main/icon-tabler.html"
                        aria-expanded="false">
                        <span className="rounded-3">
                            <i className="ti ti-archive"></i>
                        </span>
                        <span className="hide-menu">Tabler Icon</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link sidebar-link"
                        href="/modernize/dist/main/icon-solar.html"
                        aria-expanded="false">
                        <span className="rounded-3">
                            <i className="ti ti-mood-smile"></i>
                        </span>
                        <span className="hide-menu">Solar Icon</span>
                    </a>
                </li>
                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">AUTH</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link sidebar-link"
                        href="/modernize/dist/main/authentication-error.html"
                        aria-expanded="false">
                        <span className="rounded-3">
                            <i className="ti ti-alert-circle"></i>
                        </span>
                        <span className="hide-menu">Error</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-login"></i>
                        </span>
                        <span className="hide-menu">Login</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/authentication-login.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Side Login</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/authentication-login2.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Boxed Login</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-user-plus"></i>
                        </span>
                        <span className="hide-menu">Register</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/authentication-register.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Side Register</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/authentication-register2.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Boxed Register</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-rotate"></i>
                        </span>
                        <span className="hide-menu">Forgot Password</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/authentication-forgot-password.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Side Forgot Password</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/authentication-forgot-password2.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Boxed Forgot Password</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-zoom-code"></i>
                        </span>
                        <span className="hide-menu">Two Steps</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/authentication-two-steps.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Side Two Steps</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="/modernize/dist/main/authentication-two-steps2.html"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Boxed Two Steps</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link sidebar-link"
                        href="/modernize/dist/main/authentication-maintenance.html"
                        aria-expanded="false">
                        <span className="rounded-3">
                            <i className="ti ti-settings"></i>
                        </span>
                        <span className="hide-menu">Maintenance</span>
                    </a>
                </li>
                <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">OTHER</span>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link has-arrow" href="void(0)"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-box-multiple"></i>
                        </span>
                        <span className="hide-menu">Menu Level</span>
                    </a>
                    <ul aria-expanded="false" className="collapse first-level">
                        <li className="sidebar-item">
                            <a href="/"
                                className="sidebar-link">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Level 1</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link has-arrow" href="void(0)"
                                aria-expanded="false">
                                <div
                                    className="round-16 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-circle"></i>
                                </div>
                                <span className="hide-menu">Level 1.1</span>
                            </a>
                            <ul aria-expanded="false" className="collapse two-level">
                                <li className="sidebar-item">
                                    <a href="/"
                                        className="sidebar-link">
                                        <div
                                            className="round-16 d-flex align-items-center justify-content-center">
                                            <i className="ti ti-circle"></i>
                                        </div>
                                        <span className="hide-menu">Level 2</span>
                                    </a>
                                </li>
                                <li className="sidebar-item">
                                    <a className="sidebar-link has-arrow"
                                        href="void(0)" aria-expanded="false">
                                        <div
                                            className="round-16 d-flex align-items-center justify-content-center">
                                            <i className="ti ti-circle"></i>
                                        </div>
                                        <span className="hide-menu">Level 2.1</span>
                                    </a>
                                    <ul aria-expanded="false" className="collapse three-level">
                                        <li className="sidebar-item">
                                            <a href="/"
                                                className="sidebar-link">
                                                <div
                                                    className="round-16 d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-circle"></i>
                                                </div>
                                                <span className="hide-menu">Level 3</span>
                                            </a>
                                        </li>
                                        <li className="sidebar-item">
                                            <a href="/"
                                                className="sidebar-link">
                                                <div
                                                    className="round-16 d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-circle"></i>
                                                </div>
                                                <span className="hide-menu">Level 3.1</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link link-disabled"
                        href="/modernize/dist/main/index.html#disabled"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-ban"></i>
                        </span>
                        <span className="hide-menu">Disabled</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link"
                        href="/modernize/dist/main/index.html#sub"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-star"></i>
                        </span>
                        <div className="lh-base">
                            <span className="hide-menu">SubCaption</span>
                            <span className="hide-menu fs-2">This is the sutitle</span>
                        </div>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link justify-content-between"
                        href="/modernize/dist/main/index.html#chip"
                        aria-expanded="false">
                        <div className="d-flex align-items-center gap-3">
                            <span className="d-flex">
                                <i className="ti ti-award"></i>
                            </span>
                            <span className="hide-menu">Chip</span>
                        </div>
                        <div className="hide-menu">
                            <span
                                className="badge rounded-circle bg-primary d-flex align-items-center justify-content-center rounded-pill fs-2">9</span>
                        </div>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link justify-content-between"
                        href="/modernize/dist/main/index.html#outlined"
                        aria-expanded="false">
                        <div className="d-flex align-items-center gap-3">
                            <span className="d-flex">
                                <i className="ti ti-mood-smile"></i>
                            </span>
                            <span className="hide-menu">Outlined</span>
                        </div>
                        <span
                            className="hide-menu badge rounded-pill border border-primary text-primary fs-2 py-1 px-2">Outline</span>
                    </a>
                </li>
                <li className="sidebar-item">
                    <a className="sidebar-link" href="https://google.com/"
                        aria-expanded="false">
                        <span className="d-flex">
                            <i className="ti ti-star"></i>
                        </span>
                        <span className="hide-menu">External Link</span>
                    </a>
                </li>



            </>
        )
    }
}
export default WithNavigate(HMenuVer);

