import React,{Component} from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiGl from "global/ApiGlobal";
import { Link } from 'react-router-dom';

class ContainerRigthError extends Component{
	constructor(props) {
     super(props);
     this.data=props;
     //console.log("Data ContainerRigthError:",this.data)
   }
   render =()=>{
	   //console.log("this.props.data Errorxxxx",this.data)
   	//if (this.props.msg==null || this.props.msg==="OK") return "";
   	if (this.props.data == null || this.props.data.msg === undefined || this.props.data.msg==="OK") return ""; 
	   //console.log("this.props.data Error222************",this.props.data)
   	return (<>
		    <div className="container_table box-title table-responsive1" style={{marginLeft:'20px'}}>
		     {  this.props.data.ok ? 
		     	 (<font color="blue" size="5">{this.props.data.msg}</font>)
		     	:(<font color="red" size="5">{this.props.data.msg}</font>)
		     }
		    </div>
		    <div className="container_table box-title table-responsive1" style={{marginLeft:'20px'}}>
			{  this.props.data.ok ? 
				 (<font color="blue" size="5">{this.props.data.msg}</font>)
				:(<font color="red" size="5"><Link to={ApiGl.loginRoute}>Login</Link></font>)
			}
		   </div>
		   </>
		  )
   }
}
export default  WithNavigate(ContainerRigthError);