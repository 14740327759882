import React, { Component } from "react";

class Home extends Component {
    render() {
        return (
            <div className="app-main__inner">
                <section className="content">
                    <div className="container-fluid" >
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary" style={{ height: '300px' }} >
                                    <div className="card-header  text-bg-primary d-flex align-items-center">
                                        <h4 className="card-title"><b>Módulo {process.env.REACT_APP_SITE_TITLE}</b></h4>
                                    </div>
                                    <div className="card-body h-100">
                                        <font size="37" align="center">
                                            <strong><p align="center">Módulo de {process.env.REACT_APP_SITE_TITLE}</p>
                                            </strong>
                                        </font>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

}
export default Home;

