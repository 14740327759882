import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class HLinkMenu extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        let stMargen = ((this.data.nivel - 1) * 20)
        stMargen += "px"
        //stMargen = "0px"
        //console.log("ruta:", this.data.to)
        if ((this.data.to + "").startsWith("http")) {
            //console.log("SI>>>>>>>>>>>>>>",this.data.to)
            //style={{ marginLeft: stMargen }}
            return (
                <a href={this.data.to + ""}
                    target="_blank"
                    className="nav-niv2 nav-link nav-hlink"
                >
                    <i className={"nav-icon " + this.data.icon}></i>
                    <p>
                        {this.data.label}
                    </p>
                    {(!this.data.to) && (<i className="fas fa-angle-left right"></i>)}
                </a>
            )
        }
        //<a className="nav-link" style={{ marginLeft: stMargen }}>
        //<Link className={window.location.pathname === this.data.to ? 'nav-link active nav-link2' : 'nav-link not-active nav-link2'}
        //style={{ textAlign: 'left' ,marginLeft: stMargen }}
        if (1 == 1)
            return (
                <a className="nav-link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.data.btClickSelect(this.data.tituloArbol || this.data.label, this.data.to)}
                    key={this.data.to}
                >
                    <i className={"nav-icon " + this.data.icon}></i>
                    <p>
                        {this.data.label}
                       {(!this.data.to) && (<i className="fas fa-angle-left"></i>)}
                    </p>
                </a>
            );
        return (
            <Link className="nav-niv2 nav-link nav-hlink"
                to={this.data.to}
                onClick={() => this.data.btClickSelect(this.data.tituloArbol || this.data.label, this.data.to)}
                key={this.data.to}
            >
                <i className={"nav-icon " + this.data.icon}></i>
                <p>
                    {this.data.label}
                </p>
                {(!this.data.to) && (<i className="fas fa-angle-left right"></i>)}
            </Link>
        );
    }

}
export default HLinkMenu;