let glNroEmpresa=1002
const apiVar ={
    getNroEmpresa(){return glNroEmpresa },
    setNroEmpresa(nroEmpresa){glNroEmpresa = nroEmpresa},
    getImgPath(path){return `${process.env.REACT_APP_URL_DATA}/`},
    getImgPathWWW(){return `${process.env.REACT_APP_URL_DATA}/${apiVar.getNroEmpresa()}/`},

    getSystem(){return `${process.env.REACT_APP_SITE_SYSTEM}`},
    getUrlApi(){return `${process.env.REACT_APP_URL_API}`},
    getUrlHelp(){return `${process.env.REACT_APP_URL_HELP}`},
    getUrlAuth(){return `${process.env.REACT_APP_URL_AUTH}`},
    getUrlAdmin(){return `${process.env.REACT_APP_URL_ADMIN}`},
}
export default apiVar;