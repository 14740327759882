// conetxt useStatus ==>  https://kentcdodds.com/blog/how-to-use-react-context-effectively
import React from 'react';
import HeaderPage from "./Header/Archi/HeaderPage";
import HMenuMod from "global/components/Page/Header/Archi/HMenu";
import Footer from "global/components/Page/Header/Archi/Footer";
import MainRouterMod from "modMenu/Menu/MainRouterMod";
import UserProvider from "global/context/user/UserProvider";

const Container = ({ history }) => (
    <UserProvider>
        <HeaderPage />
        <div className="app-main">
            <div className="app-sidebar sidebar-shadow">
                <HMenuMod />
            </div>
            {/********************    Content */}
            <div className="app-main__outer">
                <MainRouterMod style={{ marginTop: '30px' }} />
                <Footer />

            </div>
        </div>
    </UserProvider>
);
export default Container;

