import { Link, Navigate } from "react-router-dom";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
//import { useNavigate } from "react-router-dom";
import ApiAuth from "../auth"; //signin, authenticate
import apiRuta from "global/utils/funRuta";
import ApiGlo from "global/ApiGlobal";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ShowError from "global/components/ShowError";
import BoxCenter from "./BoxCenter";
//https://ui.dev/react-router-v4-protected-routes-authentication/
//import SocialLogin from "./SocialLogin";
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
//import ShowError from "../../global/components/ShowError";
//https://www.baulphp.com/3-formas-para-mostrar-y-ocultar-contrasenas/

//MFA2
//https://medium.com/onfrontiers-engineering/two-factor-authentication-flow-with-node-and-react-7cbdf249f13

import React from 'react';
//import CssBaseline from '@material-ui/core/CssBaseline';
// import { withStyles,makeStyles } from '@mui/material/styles';
import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/styles';

// const useStyles = makeStyles(theme => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

class Signin extends HFormUser {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            resObj: null,
            redirectToReferer: false,
            loading: false,
            recaptcha: false,
        };
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    clickSubmit = event => {
        //event.preventDefault();
        //this.setState({ loading: true });
        if (process.env.REACT_APP_SITE_SYSTEM == undefined) {
            //alert("No Existe Systema")
            this.setState({ error: "No Existe System", loading: false });
            return
        }
        if (1 == 2) {
            this.setState({ error: "No2222 Existe Systema", loading: false });
            return
        }
        if (document.getElementById("txUserName").value==""){
            this.setState({ error: "Ingrese usuario", loading: false });
            return
        }
        if (document.getElementById("txUserPassword").value==""){
            this.setState({ error: "Ingrese Clave", loading: false });
            return
        }
        //console.log("process.env.REACT_APP_SITE_SYSTEM",process.env.REACT_APP_SITE_SYSTEM)
        const user = {
            email: document.getElementById("txUserName").value
            , password: document.getElementById("txUserPassword").value
            , system: process.env.REACT_APP_SITE_SYSTEM
        };
        this.post(ApiAuth.signin, user)
            .then(data => {
                if (!data.ok) {
                    this.stateHarrys(this, data)
                } else {
                    apiRuta.toRutaClear(this)
                    this.stateHarrys(this, data)
                    ApiAuth.authenticate(data, user, () => {
                        this.setState({ redirectToReferer: true });
                        this.props.navigate("/")
                    });
                }
            }
            )
    }
    mostrarPassword() {
        if (document.getElementById("txUserPassword").type == "text")
            document.getElementById("txUserPassword").type = "password"
        else
            document.getElementById("txUserPassword").type = "text"
    }
    render() {
        if (this.state.redirectToReferer) return <Navigate to="/" replace={true} />;
        return (
            <BoxCenter titulo="Inicio de Session">
                {false && (<>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-6 mb-2 mb-sm-0">
                            <a className="btn text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8" href="void(0)" role="button">
                                <img src="/FrWork/adModern/assets/images/svgs/google-icon.svg" alt="" className="img-fluid me-2" width="18" height="18" />
                                <span className="flex-shrink-0">google</span>
                            </a>
                        </div>
                        <div className="col-6">
                            <a className="btn text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8" href="void(0)" role="button">
                                <img src="/FrWork/adModern/assets/images/svgs/facebook-icon.svg" alt="" className="img-fluid me-2" width="18" height="18" />
                                <span className="flex-shrink-0">facebook</span>
                            </a>
                        </div>
                    </div>
                    <div className="position-relative text-center my-4">
                        <p className="mb-0 fs-4 px-3 d-inline-block bg-body text-dark z-index-5 position-relative">or sign in with</p>
                        <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
                    </div></>)}
                <br />
                <form>
                    <ShowError
                        msg={this.state.message}
                        err={this.state.error} />
                    <div class="row">

                        <div className="mb-4">
                            <div className="col-md-12">
                                <div className="position-relative form-group">
                                    <div className="input-group mb-3">
                                    <span class="input-group-text px-6" id="basic-addon1"><i class="ti ti-user fs-6"></i></span>
                                        <input type="text"
                                            id="txUserName"
                                            placeholder="Nombre de Usuario"
                                            style={{ width: "70%" }}
                                            className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="mb-3">
                            <div className="col-md-12">
                                <div className="position-relative form-group">
                                    <div className="input-group mb-1">
                                        <span className="input-group-text fa fa-key icon"></span>
                                        <input type="password"
                                            id="txUserPassword"
                                            placeholder="Password"
                                            className="form-control"
                                            style={{ width: "50%" }}
                                        />
                                        <button id="show_password"
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={() => this.mostrarPassword()}
                                        >
                                            <span className="fa fa-eye-slash icon"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="form-check">
                                {/* <Link to={ApiGlo.registrateRoute} className="btn2 btn-raised2 btn-warning2  float-none2 "> Registrate</Link> */}
                            </div>
                            <Link to={ApiGlo.forgotPasswordRoute} className="btn btn-outline-primary">
                                Olvide mi Clave
                            </Link>
                        </div>
                        <br />
                        <div className="mb-3">
                            <a onClick={this.clickSubmit}
                                className="btn btn-primary w-100 py-8 mb-4 rounded-2">
                                Ingresar
                            </a>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <p className="fs-4 mb-0 fw-medium">No tienes Cuenta?</p>
                            <Link to={ApiGlo.registrateRoute}
                                className="text-primary fw-medium ms-2 "> Crear una Cuenta</Link>
                        </div>
                    </div>
                </form>

            </BoxCenter>
        );
    }
}


export default WithNavigate(Signin);
