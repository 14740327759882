import React, { Component } from 'react';
import HFormUser from "global/components/Page/Forms/HFormUser";
import $ from 'jquery';
import './Calendario_01.css'; // 
class HCalendar extends HFormUser {
    meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul"
        , "Ago", "Sep", "Oct", "Nov", "Dic"]
    dias = { lun: "Lun", mar: "Mar", mie: "Mie", jue: "Jue", vie: "Vie", sab: "Sab", dom: "Dom" }
    // nSem=[{sem:1,    dataMes:[{dia:0}
    //                         ,{dia:0}
    //                         ,{dia:1,clName: 'event-date'}
    //                         ,{dia:2,clName: 'event-date'}
    //                         ,{dia:3,clName: 'event-date'}
    //                         ,{dia:4,clName: 'event-date'}
    //                         ,{dia:5,clName: 'active-date'}
    //                         ]
    //      }
    //     ,{sem:2, dataMes:[{dia:22}
    //         ,{dia:6}
    //         ,{dia:9,clName: 'event-date'}
    //         ,{dia:8,clName: 'event-date'}
    //         ,{dia:9,clName: 'event-date'}
    //         ,{dia:10,clName: 'event-date'}
    //         ,{dia:11,clName: 'active-date'}
    //         ]}
    //     ,{sem:3, dataMes:[{dia:21}
    //         ,{dia:12}
    //         ,{dia:13,clName: 'event-date'}
    //         ,{dia:14,clName: 'event-date'}
    //         ,{dia:15,clName: 'event-date'}
    //         ,{dia:16,clName: 'event-date'}
    //         ,{dia:17,clName: 'active-date'}
    //         ]}
    //     ]
    constructor(props) {
        super(props);
        this.data = props;
    }
    //componentDidMount() {                //this.loadJS()    }
    mesPrev = () => {
        this.data.mesAntClick()
    }
    mesNext = () => {
        this.data.mesSigClick()
    }
    mesAct = (pMes) => {
        this.data.mesActClick(pMes)
    }
    mesActual = () => {
        //console.log("this.data",this.data)
        //
        // console.log("this.data22",this.data.dataMes[0])
        // console.log("this.data22",this.data.dataMes[0].dataMes[1])
        //https://codepen.io/Kseso/pen/nreaBV
        // https://reactnative.dev/docs/colors
        // <div className="frame">
        return (
            <tbody className="tbody">
                {this.data.dataMes &&
                    this.data.dataMes.map((strSem, semIdx) => {
                        return (<tr key={semIdx} className='table-row'>
                            {strSem.dataSem.map((strDia, index) => {
                                if (strDia.dia == 0)
                                    return (<td key={(semIdx * index) + index} className='table-date1 nil'></td>)
                                else
                                    return (
                                        <td key={(semIdx * index) + index}
                                            className={'table-date1 ' + strDia.clName}
                                            onClick={() => { this.data.dayClick(strDia) }}
                                            style={strDia.style}
                                        >{strDia.dia}


                                            <div className="grafico" style={{ cursor: "pointer" }}>
                                                <div className="sombra"></div>
                                                {strDia.horario.map((strTur, index) => {
                                                    let stRotPor = "rotate(" + strTur.tot_grados_ini + "deg)"
                                                    let stRotate = "rotate(" + strTur.tot_grados + "deg)"
                                                    let stColor = "rgba(0,0,255,.7)"
                                                    //console.log("strTur.hor_color",strTur.hor_color)
                                                    return (<div id={"porcion" + (index + 1)}
                                                        key={"porcion" + (index + 1)}
                                                        className="recorte"

                                                        style={{ transform: stRotPor }}
                                                    >
                                                        <div className="quesito"
                                                            data-rel="70"
                                                            style={{ backgroundColor: strTur.hor_color, transform: stRotate }}
                                                        >
                                                        </div>
                                                    </div>)
                                                })
                                                }
                                            </div>

                                        </td>)
                            })
                            }
                        </tr>)
                    })
                }
            </tbody>
        )
    }

    render = () => {
        //console.log("dataCalendar",this.data.dataCalendar)
        return (<section className="ftco-section">
            <div className="container">
                {this.data.titulo &&
                    (<div className="row justify-content-center">
                        <div className="col-md-6 text-center mb-5">
                            <h2 className="heading-section">{this.data && this.data.titulo}</h2>
                        </div>
                    </div>)
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="content w-100">
                            <div className="calendar-container">
                                <div className="calendar">
                                    <div className="year-header">
                                        <span className="left-button fa fa-chevron-left"
                                            id="prev"
                                            onClick={this.mesPrev}> </span>
                                        <span className="year" id="label">{this.data.pAnno}/{this.data.pMes}</span>
                                        <span className="right-button fa fa-chevron-right"
                                            id="next"
                                            onClick={this.mesNext}> </span>
                                    </div>
                                    <table className="months-table w-100">
                                        <tbody>
                                            <tr className="months-row">
                                                {this.meses.map((strMes, mesIdx) => {
                                                    let stActivo = "";
                                                    if (mesIdx == this.data.pMes - 1) stActivo = "active-month"
                                                    return (<td key={mesIdx}
                                                        className={"month " + stActivo}
                                                        onClick={() => this.mesAct(mesIdx + 1)}
                                                        style={{ cursor: 'pointer' }}>{strMes}</td>)
                                                })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="days-table w-100">
                                        <thead><tr>
                                            <th className="day" style={{ cursor: 'pointer' }}>{this.dias.lun}</th>
                                            <th className="day" style={{ cursor: 'pointer' }}>{this.dias.mar}</th>
                                            <th className="day" style={{ cursor: 'pointer' }}>{this.dias.mie}</th>
                                            <th className="day" style={{ cursor: 'pointer' }}>{this.dias.jue}</th>
                                            <th className="day" style={{ cursor: 'pointer' }}>{this.dias.vie}</th>
                                            <th className="day" style={{ cursor: 'pointer' }}>{this.dias.sab}</th>
                                            <th className="day" style={{ cursor: 'pointer' }}>{this.dias.dom}</th>
                                        </tr>
                                        </thead>
                                    {this.mesActual()}
                                    </table>
                                    {/* <button className="button" id="add-button">Add Event</button> */}
                                </div>
                            </div>
                            {/*    
                            <div className="events-container" style={{display: "none"}}>
                                <div className="event-card" style={{borderLeft: "10px solid rgb(255, 23, 68)"}}>
                                    <div className="event-name">There are no events planned for December 14.</div>
                                </div>
                            </div>
                            <div className="dialog" id="dialog" style={{display:"block"}}>
                              <h2 className="dialog-header"> Add New Event </h2>
                                <form className="form" id="form">
                                    <div className="form-container" align="center">
                                        <label className="form-label" id="valueFromMyButton" for="name">Event name</label>
                                        <input className="input" type="text" id="name" maxlength="36"/>
                                        <label className="form-label" id="valueFromMyButton" for="count">Number of people to invite</label>
                                        <input className="input" type="number" id="count" min="0" max="1000000" maxlength="7"/>
                                        <input type="button" value="Cancel" className="button" id="cancel-button"/>
                                        <input type="button" value="OK" className="button button-white" id="ok-button"/>
                                    </div>
                                </form>
                            </div>
                                */}
                        </div>
                    </div>
                </div>
            </div>

         

        </section>
        );
    }

    loadJS = () => {
        //alert(1)
        $(function () {
            "use strict";
            $(document).ready(function () {
                var date = new Date();
                $("#dialog").hide(250);
                var today = date.getDate();
                // Set click handlers for DOM elements
                $(".right-button").click({ date: date }, next_year);
                $(".left-button").click({ date: date }, prev_year);
                $(".month").click({ date: date }, month_click);
                //$("#add-button").click({date: date}, new_event);
                // Set current month as active
                $(".months-row").children().eq(date.getMonth()).addClass("active-month");
                init_calendar(date);
                var events = check_events(today, date.getMonth() + 1, date.getFullYear());
                show_events(events, months[date.getMonth()], today);
            });
            //********************************************************************** 
            // Event handler for when the year right-button is clicked
            function next_year(event) {
                //alert( 'next year')
                $("#dialog").hide(250);
                var date = event.data.date;
                var new_year = date.getFullYear() + 1;
                $("year").html(new_year);
                date.setFullYear(new_year);
                init_calendar(date);
            }

            // Event handler for when the year left-button is clicked
            function prev_year(event) {
                //alert( 'prev year')
                $("#dialog").hide(250);
                var date = event.data.date;
                var new_year = date.getFullYear() - 1;
                $("year").html(new_year);
                date.setFullYear(new_year);
                init_calendar(date);
            }
            function init_calendar(date) {
                $(".tbody").empty();
                $(".events-container").empty();
                var calendar_days = $(".tbody");
                var month = date.getMonth();
                var year = date.getFullYear();
                var day_count = days_in_month(month, year);
                var row = $("<tr  className='table-row'></tr>");
                var today = date.getDate();
                // Set date to 1 to find the first day of the month
                date.setDate(1);
                var first_day = date.getDay();
                // 35+firstDay is the number of date elements to be added to the dates table
                // 35 is from (7 days in a week) * (up to 5 rows of dates in a month)
                for (var i = 0; i < 35 + first_day; i++) {
                    // Since some of the elements will be blank, 
                    // need to calculate actual date from index
                    var day = i - first_day + 1;
                    // If it is a sunday, make a new row
                    if (i % 7 === 0) {
                        calendar_days.append(row);
                        row = $("<tr  className='table-row'></tr>");
                    }
                    // if current index isn't a day in this month, make it blank
                    if (i < first_day || day > day_count) {
                        var curr_date = $("<td  className='table-date nil'>" + "</td>");
                        row.append(curr_date);
                    }
                    else {
                        var curr_date = $("<td  className='table-date'>" + day + "</td>");
                        var events = check_events(day, month + 1, year);
                        if (today === day && $(".active-date").length === 0) {
                            curr_date.addClass("active-date");
                            show_events(events, months[month], day);
                        }
                        // If this date has any events, style it with .event-date
                        if (events.length !== 0) {
                            curr_date.addClass("event-date");
                        }
                        // Set onClick handler for clicking a date
                        curr_date.click({ events: events, month: months[month], day: day }, date_click);
                        row.append(curr_date);
                    }
                }
                // Append the last row and set the current year
                calendar_days.append(row);
                $(".year").text(year);
            }
            // Event handler for when a month is clicked
            function month_click(event) {
                $(".events-container").show(250);
                $("#dialog").hide(250);
                var date = event.data.date;
                $(".active-month").removeClass("active-month");
                $(this).addClass("active-month");
                var new_month = $(".month").index(this);
                date.setMonth(new_month);
                init_calendar(date);
            }
            // Get the number of days in a given month/year
            function days_in_month(month, year) {
                var monthStart = new Date(year, month, 1);
                var monthEnd = new Date(year, month + 1, 1);
                return (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
            }
            // Checks if a specific date has any events
            function check_events(day, month, year) {
                var events = [];
                for (var i = 0; i < event_data["events"].length; i++) {
                    var event = event_data["events"][i];
                    if (event["day"] === day &&
                        event["month"] === month &&
                        event["year"] === year) {
                        events.push(event);
                    }
                }
                return events;
            }
            // Display all events of the selected date in card views
            function show_events(events, month, day) {
                // Clear the dates container
                $(".events-container").empty();
                $(".events-container").show(250);
                //console.log(event_data["events"]);
                // If there are no events for this date, notify the user
                if (events.length === 0) {
                    var event_card = $("<div  className='event-card'></div>");
                    var event_name = $("<div  className='event-name'>There are no events planned for " + month + " " + day + ".</div>");
                    $(event_card).css({ "border-left": "10px solid #FF1744" });
                    $(event_card).append(event_name);
                    $(".events-container").append(event_card);
                }
                else {
                    // Go through and add each event as a card to the events container
                    for (var i = 0; i < events.length; i++) {
                        var event_card = $("<div  className='event-card'></div>");
                        var event_name = $("<div  className='event-name'>" + events[i]["occasion"] + ":</div>");
                        var event_count = $("<div  className='event-count'>" + events[i]["invited_count"] + " Invited</div>");
                        if (events[i]["cancelled"] === true) {
                            $(event_card).css({
                                "border-left": "10px solid #FF1744"
                            });
                            event_count = $("<div  className='event-cancelled'>Cancelled</div>");
                        }
                        $(event_card).append(event_name).append(event_count);
                        $(".events-container").append(event_card);
                    }
                }
            }
            // Event handler for when a date is clicked
            function date_click(event) {
                $(".events-container").show(250);
                $("#dialog").hide(250);
                $(".active-date").removeClass("active-date");
                $(this).addClass("active-date");
                show_events(event.data.events, event.data.month, event.data.day);
            };

            // Given data for events in JSON format
            var event_data = {
                "events": [{
                    "occasion": " Repeated Test Event ",
                    "invited_count": 120,
                    "year": 2022,
                    "month": 12,
                    "day": 19,
                    "cancelled": true
                },
                ]
            };

            const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];


        }) // Fin Function principal
    }



}
export default HCalendar;