import React, { Component } from "react";
import apiRuta from "global/utils/funRuta";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ApiGlo from "global/ApiGlobal";
import ShowLoading from "global/components/ShowLoading";
import ShowError from "global/components/ShowError";
import HFormUser from "global/components/Page/Forms/HFormUser";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxButtonCrud from "global/components/Page/InputHtml/HBoxButtonCrud";
import HBoxTextFecha from "global/components/Page/InputHtml/HBoxTextFecha";
class FrConfigEmpresa extends HFormUser {
    pSystem = process.env.REACT_APP_SITE_SYSTEM
    componentDidMount() {this.readApi(); }
    readApi = () => {
        //console.log("detKey",this.props.location.state.detKey)
        try{this.pSystem =this.props.location.state.detKey.codigo}catch{}
        
        this.setState({ loading: true })
        console.log("ApiGlo.empresaCfg",ApiGlo.empresaCfg)
        this.post(ApiGlo.empresaCfg,{systemAct:this.pSystem})
            .then(data => { this.stateHarrys(this, data) })
    }
    btCfg = (row) => {
        apiRuta.toRutaAdd(this, "Editar", ApiGlo.configRoute)
    }
    btActivar = () => {
        this.setState({ loading: true })
        this.post(ApiGlo.empresaCfgActivar,{systemAct:this.pSystem})
                    .then(data => { 
                        apiRuta.toRutaAdd(this,"Home","/")
                        //this.stateHarrys(this, data) 
                    })
    }
    render() {
        if (this.state.loading) return (<ShowLoading/>)
        if (!this.state.resObj.rows || this.state.resObj.rows.length==0)             return (<><ShowError  msg={this.state.resObj.msg}/></>)
            
        return (<>
              <ShowError  msg={this.state.resObj.msg}/>
              <ContainerRightEdit
                        tituloContainer={this.pSystem + " / " + this.state.resObj.rows[0].razon}
                        swBarraHeader={false}
                        resObj={this.state.resObj}
                        contexto={this}
                    >


                        <HBoxTextFecha
                            label="Fecha Ult.Pago" 
                            disabled 
                            value={this.state.resObj.rows[0].fultpago }
                            />
                        <HBoxTextFecha
                            label="Fecha Activa" 
                            disabled 
                            value={this.state.resObj.rows[0].finicio }
                            />
                        <HBoxTextFecha
                            label="Fecha Finaliza" 
                            disabled 
                            value={this.state.resObj.rows[0].ffinaliza }
                            />
                        <HBoxButtonCrud
                                            bt1Label="Config"
                                            bt1Click={this.btCfg}
                                            bt1Icon="fas fa-config"

                                            bt2Label="Activar"
                                            bt2Click={this.btActivar}
                                            bt2Icon="fas fa-check"

                                            />

        </ContainerRightEdit>
        </>

        );
    }
}
export default FrConfigEmpresa;
