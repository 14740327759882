import React, { Component } from 'react';

//import UserContext from 'global/context/user/UserContext';

class HeaderIdioma extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        return (
            <li className="nav-item dropdown">
                <a className="nav-link nav-icon-hover" href="void(0)" id="drop2"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/FrWork/adModern/assets/images/svgs/icon-flag-en.svg" alt="" width="20px"
                        height="20px" className="rounded-circle object-fit-cover round-20" />
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2">
                    <div className="message-body">
                        <a href="void(0)"
                            className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                            <div className="position-relative">
                                <img src="/FrWork/adModern/assets/images/svgs/icon-flag-en.svg" alt=""
                                    width="20px" height="20px"
                                    className="rounded-circle object-fit-cover round-20" />
                            </div>
                            <p className="mb-0 fs-3">English (UK)</p>
                        </a>
                        <a href="void(0)"
                            className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                            <div className="position-relative">
                                <img src="/FrWork/adModern/assets/images/svgs/icon-flag-cn.svg" alt=""
                                    width="20px" height="20px"
                                    className="rounded-circle object-fit-cover round-20" />
                            </div>
                            <p className="mb-0 fs-3">中国人 (Chinese)</p>
                        </a>
                        <a href="void(0)"
                            className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                            <div className="position-relative">
                                <img src="/FrWork/adModern/assets/images/svgs/icon-flag-fr.svg" alt=""
                                    width="20px" height="20px"
                                    className="rounded-circle object-fit-cover round-20" />
                            </div>
                            <p className="mb-0 fs-3">français (French)</p>
                        </a>
                        <a href="void(0)"
                            className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                            <div className="position-relative">
                                <img src="/FrWork/adModern/assets/images/svgs/icon-flag-sa.svg" alt=""
                                    width="20px" height="20px"
                                    className="rounded-circle object-fit-cover round-20" />
                            </div>
                            <p className="mb-0 fs-3">عربي (Arabic)</p>
                        </a>
                    </div>
                </div>
            </li>
        )
    }
}
export default HeaderIdioma;