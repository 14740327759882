import React, { Component } from "react";
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";
import apiRuta from "global/utils/funRuta";
import apiMsg from "global/utils/funMsg";
import HFormUser from "global/components/Page/Forms/HFormUser";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextMail from "global/components/Page/InputHtml/HBoxTextMail";


class ProfileSetting extends HFormUser {

    guardar = () => {
        if (!this.validaForm("formDatos")) return
        var stData = {
            nombres: document.getElementById("txNombres").value
            , paterno: document.getElementById("txApePat").value
            , materno: document.getElementById("txApeMat").value
            , email: document.getElementById("txEmail").value
        }
        this.post(ApiGl.profileSaveSet, stData)
            .then(data => {
                if (data.ok) {
                    apiMsg.msgOk("Registro Gruardado", "Grabar")
                    apiRuta.toRutaBack(this)
                }
            })
    }
    render() {
        return (<>
            <div className="container rounded bg-white mt-5 mb-5">
                <div className="row">
                    <div className="col-md-3 border-right">
                        <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img className="rounded-circle mt-5" width="150px"
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                            <span className="font-weight-bold">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_nombres') : "samanta@harrys.com"}</span>
                            <span className="text-black-50">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_email') : "samanta@harrys.com"}</span><span> </span></div>
                    </div>
                    <HBoxForm id="formDatos">
                        <div className="col-md-9 border-right">
                            <div className="p-3 py-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="text-right">Mis Datos</h4>
                                </div>
                                <div className="row mt-2">
                                    <HBoxText id="txNombres"
                                        classCol="col-md-6"
                                        label="Nombres"
                                        minLength="3"
                                        maxLength="50"
                                        required
                                        value={this.data.obj && this.data.obj.nombre}
                                        placeholder="Nombres" />
                                </div>
                                <div className="row mt-2">

                                    <HBoxText id="txApePat"
                                        classCol="col-md-6"
                                        required
                                        value={this.data.obj && this.data.obj.ape_pat}
                                        label=" Apellido Paterno"
                                        minLength="3"
                                        maxLength="50"
                                        size="10"
                                    />
                                    <HBoxText id="txApeMat"
                                        classCol="col-md-6"
                                        required
                                        value={this.data.obj && this.data.obj.ape_mat}
                                        label=" Apellido Materno"
                                        minLength="3"
                                        maxLength="50"
                                    />

                                </div>
                                <div className="row mt-3">
                                    <HBoxTextMail id="txEmail"
                                        label="Email"
                                        value={this.data.obj && this.data.obj.mail}
                                    />
                                </div>

                                <div className="mt-5 text-center">
                                    <button className="btn btn-primary
                                    profile-button"
                                        onClick={this.guardar}
                                        type="button">Guardar los Datos</button>
                                </div>
                            </div>
                        </div>
                    </HBoxForm>
                </div>
            </div>
        </>
        )
    }
}

export default ProfileSetting;
