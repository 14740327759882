import HBoxtObj from "./HBoxObj";
import HBoxObjBase from "./HBoxObjBase";

class HTextBoxTel extends HBoxtObj{
    render =()=>{
	    return (     <HBoxObjBase
                    pattern={this.data.pattern  || "^[a-zA-Z0-9 áéíóúÁÉÍÓÚñ.-_,]+$"} 
                    minLength={this.data.minLength || 0} 
                    maxLength={this.data.maxLength || 50}  
                    {...this.props}
                   />
      );
 	} 
}    
export default  HTextBoxTel;