//https://es.wikipedia.org/wiki/Organizaci%C3%B3n_territorial_de_Chile
// https://www.universal-tutorial.com/rest-apis/free-rest-api-for-country-state-city     
//https://www.youtube.com/watch?v=g7_qUogFo18
import HBoxtObjCod from "global/components/Page/InputHtml/HBoxTextCod";
import React, { Component } from 'react';
class HTextBoxDirComu extends Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            idRegion:0,
            idProvincia:0,
            idComuna:0
        }
    }
    //componentWillUpdate(nextProps, nextState) { this.data = nextProps; console.log("Comu nextPropsValue==>*******************",nextProps,nextState) }
    // setObjReg = (objObjeto) => {
    //     this.txIdRegion.setObjeto(objObjeto)
    //     this.txIdRegion.setValue(objObjeto.nombre)
    //     console.log("objObjetoSet Region:",this.state.value , this.data.value)
    //  }
    // setObjPro = (objObjeto) => {
    //     this.txIdProvincia.setObjeto(objObjeto)
    //     this.txIdProvincia.setValue(objObjeto.nombre)
    //     console.log("objObjetoSet Provincia:",this.state.value , this.data.value)
    //  }
    //  setObjCom = (objObjeto) => {
    //     this.txIdComuna.setObjeto(objObjeto)
    //     this.txIdComuna.setValue(objObjeto.nombre)
    //     console.log("objObjetoSet Comuna:",this.state.value , this.data.value)
    //  }
     getObjCom = () => {
        return this.txIdComuna.getObjeto()
     }
    render = () => {
         console.log("DirComu render Data:",  this.data)
        return (<>
        <HBoxtObjCod
            label="Región" 
            ref={x => this.txIdRegion = x}
            value={this.state.idRegion}
            minLength={this.data.minLength || 0}
            maxLength={this.data.maxLength || 400}

            sqlHelp="hlp_region"
            // onClickHelp={this.setObjReg}
            // {...this.props}
        />
        <HBoxtObjCod
            label="Provincia" 
            ref={x => this.txIdProvincia = x}
            value={this.state.idProvincia}
            minLength={this.data.minLength || 0}
            maxLength={this.data.maxLength || 400}

            sqlHelp="hlp_provincia"
            // onClickHelp={this.setObjPro}
            // {...this.props}
        />
        <HBoxtObjCod
            ref={x => this.txIdComuna = x}
            value={this.data.value }
            valueid={this.data.valueid }
            minLength={this.data.minLength || 1}
            maxLength={this.data.maxLength || 400}

            sqlHelp="hlp_comuna"
            // onClickHelp={this.setObjCom}
            {...this.props}
        />
        </>
        );
    }

}
export default HTextBoxDirComu;