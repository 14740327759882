import React, { Component } from "react";
import { Router, Route, Routes } from "react-router-dom";
import ApiGlo from "global/ApiGlobal";
import HomeUser from "Home";
import Signin from "global/user/Signin";
import Profile from "global/user/Profile/Profile";
import ProfileSetDirEdit from "global/user/Profile/ProfileSetDirEdit";
import ProfileSetCelEdit from "global/user/Profile/ProfileSetCelEdit";
import Signout from "global/user/Signout";
import ConfigSystem from "global/auth/config/FrConfigSystem";
import ConfigEmpresa from "global/auth/config/FrConfigEmpresa";
import ConfigUsuario from "global/auth/config/FrConfigUsuario";
import ResetPassword from "global/user/ForgotResetPassword";
import ForgotPassword from "global/user/ForgotPassword";
import NewUserSolicitar from "global/user/NewUserSolicitar";
import NewUser from "global/user/NewUser";
import NotFound from "global/components/Pag404NotFound";


class HMainRouter extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render() {
        return (

            <Routes>
                {!this.data.isNotHome && <Route exact path="/" element={<HomeUser />} />}
                <Route exact path={ApiGlo.loginRoute} element={<Signin />} />
                <Route exact path={ApiGlo.profileRoute} element={<Profile/>} />
                <Route exact path={ApiGlo.singOutRoute} element={<Signout />} />
                <Route exact path={ApiGlo.configRoute} element={<ConfigSystem />} />
                <Route exact path={ApiGlo.configEmpresaRoute} element={<ConfigEmpresa />} />
                <Route exact path={ApiGlo.profileRoute} element={<ConfigUsuario />} />
                <Route exact path={ApiGlo.profileEditRoute} element={<ProfileSetDirEdit />} />
                <Route exact path={ApiGlo.profileEditCelRoute} element={<ProfileSetCelEdit />} />
                
                <Route
                    exact
                    path="/auth/reset-password/:resetPasswordToken"
                    element={<ResetPassword />}
                />
                <Route
                    exact
                    path="/auth/reset-password/"
                    element={<ResetPassword />}
                />
                {this.props.children}
                <Route exact path={ApiGlo.forgotPasswordRoute} element={<ForgotPassword />} />
                <Route exact path={ApiGlo.registrateFinRoute} element={<NewUser />} />
                <Route exact path={ApiGlo.registrateRoute} element={<NewUserSolicitar />} />
                <Route exact path="*" element={<NotFound />} />
            </Routes>

        );
    }
}
export default HMainRouter;