import React, { Component } from 'react';
import "./ShowLoading.css"
class ShowLoading extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    //<section className="content">
    render = () => {
        return (
            <div className="body-wrapper mw-100" style={{ border: '5px' }}>
                <div className="container-fluid mw-100" style={{ border: '5px', margin: '0px', padding: '0px' }}>
                    <div className="card card-spinner">
                        <section className="container">
                            <div id="nb-global-spinner" className="spinner">
                                <div className="blob blob-0"></div>
                                <div className="blob blob-1"></div>
                                <div className="blob blob-2"></div>
                                <div className="blob blob-3"></div>
                                <div className="blob blob-4"></div>
                                <div className="blob blob-5"></div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}
//export default  withStyles(useStyles)(ShowLoading);
export default ShowLoading;
