import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiAuth from "../auth";
import BoxCenter from "./BoxCenter";
import { Link, Redirect } from "react-router-dom";
import ShowError from "global/components/ShowError";
import ApiGl from "global/ApiGlobal";

class ForgotResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "sss",
            newPassword2: "sss",
            message: "",
            token:location.search.substring(1),
            error: ""
        };
        //console.log(location.search);
    }

    resetPassword = e => {
        e.preventDefault();
        this.setState({ message: "", error: "" });

        ApiAuth.resetPassword({
            newPassword: document.getElementById("newPassword").value,
            newPassword2: document.getElementById("newPassword2").value,
            resetPasswordLink: this.state.token
        }).then(data => {
            //console.log("dataReset:",data)
            if (!data.ok) {
                this.setState({ error: data.msg, newPassword: "", newPassword2: "" });
                return;
            }
            this.setState({ message: data.msg, newPassword: "", newPassword2: "" });
            this.props.navigate(ApiGl.loginRoute)
        });
    };

    render() {
        return (
            <BoxCenter titulo="Restablecer Contraseña">
                <ShowError
                    msg={this.state.message}
                    err={this.state.error} />
                <div className="form-group mt-3">
                    <textarea
                        type="text"
                        className="form-control"
                        placeholder="Token"
                        name="token"
                        id="token"
                        rows="7"
                        value={this.state.token}
                    />
                </div>                    
                <div className="form-group mt-3">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Ingrese su Nueva Clave"
                        name="newPassword"
                        id="newPassword"
                    />
                </div>
                <div className="form-group mt-3">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Re-Ingrese su Nueva Clave"
                        name="newPassword2"
                        id="newPassword2"
                    />
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <Link to={ApiGl.loginRoute} className="btn btn-raised btn-successbtn-flat float-none btn-outline-primary">
                        Inicio Sessión
                    </Link>
                    <Link to={ApiGl.registrateRoute} className="btn btn-raised btn-successbtn-flat flote-right btn-outline-primary">
                        Registrate
                    </Link>

                </div>
                <br />
                <div className="mb-4">
                    <a onClick={this.resetPassword} className="btn btn-primary w-100 py-8 mb-4 rounded-2">
                        Modificar Contraseña
                    </a>

                    <button className="btn btn-raised btn-successbtn-flat float-right "
                        onClick={this.resetPassword}
                    />
                </div>
            </BoxCenter>
        );
    }
}

export default WithNavigate(ForgotResetPassword);
