import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
class HLinkMenu extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        if ((this.data.to + "").startsWith("http")) {
            return (
                <a href={this.data.to + ""}
                    className="sidebar-link  activexx"
                    target="_blank"
                    aria-expanded="false"
                    style={{ cursor: 'pointer' }}>
                    <span><i className={"ti ti-aperture " + this.data.icon}></i></span>
                    <span>{this.data.label}</span>
                </a>
            )
        }
        if (!this.data.to)
            return (<a className="sidebar-link  has-arrow activexx"
                key={this.data.to}
                href="#"
                style={{ cursor: 'pointer' }}
                aria-expanded="false">
                <span className="d-flex"><i className={"ti ti-aperture " + this.data.icon}></i></span>
                <span className="hide-menu">{this.data.label}</span>
            </a>
            )
        if (this.data.nivel == 2 && this.data.to)
            return (<a className="sidebar-link  activexx"
                onClick={() => this.data.btClickSelect(this.data.tituloArbol || this.data.label, this.data.to)}
                key={this.data.to}
                href="#"
                style={{ cursor: 'pointer' }}
                aria-expanded="false">
                <span className="d-flex"><i className={"ti ti-aperture " + this.data.icon}></i></span>
                <span className="hide-menu">{this.data.label}</span>
            </a>
            )
        if (this.data.to)
            return (<a className="sidebar-link activexx"
                onClick={() => this.data.btClickSelect(this.data.tituloArbol || this.data.label, this.data.to)}
                key={this.data.to}
                href="#"
                style={{ cursor: 'pointer' }}
                aria-expanded="false">
                <div className="round-16 d-flex align-items-center justify-content-center">
                    <i className={"ti ti-aperture " + this.data.icon}></i>
                    <span className="hide-menu">{this.data.label}</span>
                </div>
            </a>
            )
    }

}
//{(!this.data.to) && (<i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>)}

export default HLinkMenu;