import React, { Component } from "react";
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextDirPostal from "global/components/Page/InputHtml/HBoxTextDirPostal";
import HBoxTextDir from "global/components/Page/InputHtml/HBoxTextDir";
import HBoxTextDirComu from "global/components/Page/InputHtml/HBoxTextDirComu";
import HBoxTextDirNro from "global/components/Page/InputHtml/HBoxTextDirNro";
import HBoxTextDirDepto from "global/components/Page/InputHtml/HBoxTextDirDepto";
import HBoxTextTel from "global/components/Page/InputHtml/HBoxTextTel";
import HBoxTextFax from "global/components/Page/InputHtml/HBoxTextFax";
import HBoxtObjCod from "global/components/Page/InputHtml/HBoxTextCod";
import "./Profile.css";


class ProfileSetDirEdit extends HFormUser {
    constructor(props) {
        super(props);
        //console.log("this.props:", this.props)
        this.data = this.props.location.state.detKey
    }
    guardar = () => {
        if (!this.validaForm("formData")) return
        var stData = {
            idDir: document.getElementById("txId").value
            , direcc: document.getElementById("txDirec").value
            , codPostal: document.getElementById("txCodPos").value
            , nroDir: document.getElementById("txNro").value
            , depto: document.getElementById("txDepto").value
            , telefono: document.getElementById("txTel").value
            , fax: document.getElementById("txFax").value
            , idComuna: this.txIdComuna.getObjCom()
            , idTipDir: this.txIdTipo.getId()
        }
        this.post(ApiGl.profileSaveDir, stData)
            .then(data => {
                if (data.ok) {
                    this.props.navigate(ApiGl.profileRoute)
                    //this.setState({ isVisible: false })
                }
            })
    }
    render() {

        //console.log("Dire:", this.data.regDir,this.data)
        return (<>
            <div className="container rounded bg-white mt-5 mb-5">
                <div className="row">
                    <div className="col-md-3 border-right">
                        <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img className="rounded-circle mt-5" width="150px"
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                            <span className="font-weight-bold">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_nombres') : "samanta@harrys.com"}</span>
                            <span className="text-black-50">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_email') : "samanta@harrys.com"}</span><span> </span></div>
                    </div>
                    <div id="mod-direcc" className={"col-md-9 border-right"}>
                        <HBoxForm id="formData">
                            <div className="p-3 py-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="text-right">Dirección</h4>
                                </div>
                                <div className="row mt-3">
                                    <HBoxTextDir id="txDirec"
                                        label=" Dirección"
                                        required
                                        value={this.data && this.data.direccion}
                                        maxLength="150"
                                    />
                                    <HBoxTextDirNro id="txNro"
                                        label="Nro. Casa"
                                        value={this.data && this.data.nro}
                                        required
                                    />
                                    <HBoxTextDirDepto id="txDepto"
                                        label="Nro. Depto"
                                        value={this.data && this.data.depto}
                                    />

                                    <HBoxTextDirPostal id="txCodPos"
                                        label="Código Postal"
                                        value={this.data && this.data.codpostal}
                                    />
                                    <HBoxTextTel id="txTel"
                                        label="Teléfono"
                                        value={this.data && this.data.telefono}
                                    />
                                    <HBoxTextFax id="txFax"
                                        label="Nro.Fax"
                                        value={this.data && this.data.fax}
                                    />
                                    <HBoxTextDirComu
                                        id="txIdComuna"
                                        label="Comuna"
                                        required
                                        ref={x => this.txIdComuna = x}
                                        valueid={this.data && this.data.valueid}
                                        value={this.data && this.data.value}
                                    />
                                    <HBoxtObjCod
                                        id="txIdTipo"
                                        label="Tipo Dirección"
                                        required
                                        ref={x => this.txIdTipo = x}
                                        value={this.data.dirtip_value}
                                        valueid={this.data.dirtip_valueid}
                                        minLength="1"
                                        maxLength="400"

                                        sqlHelp="hlp_dirtip"
                                    // onClickHelp={this.setObjCom}
                                    // {...this.props}
                                    />
                                    <HBoxText id="txId"
                                        visibility={false}
                                        label="Id"
                                        required
                                        disabled
                                        value={this.data && this.data.iddir}
                                    />

                                </div>
                                <div className="mt-5 text-center">
                                    <button className="btn btn-primary 
                                    profile-button"
                                        onClick={this.guardar}
                                        type="button">Guardar Dirección</button>
                                </div>
                            </div>
                        </HBoxForm>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default WithNavigate(ProfileSetDirEdit);
