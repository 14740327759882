import React, { Component } from "react";
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxtObjCod from "global/components/Page/InputHtml/HBoxTextCod";
import HBoxTextTel from "global/components/Page/InputHtml/HBoxTextTel";
import "./Profile.css";


class ProfileSetDirEdit extends HFormUser {
    constructor(props) {
        super(props);
        //console.log("this.props:", this.props)
        this.data = this.props.location.state.detKey
    }
    guardar = () => {
        if (!this.validaForm("formData")) return
        var stData = {
              idCel: document.getElementById("txId").value
            , nroCel: document.getElementById("txCel").value
            , idTipCel: this.txIdTipo.getId()
        }
        this.post(ApiGl.profileSaveCel, stData)
            .then(data => {
                if (data.ok) {
                    this.props.navigate(ApiGl.profileRoute)
                }
            })
    }
    render() {

        //console.log("Dire:", this.data.regDir,this.data)
        return (<>
            <div className="container rounded bg-white mt-5 mb-5">
                <div className="row">
                    <div className="col-md-3 border-right">
                        <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img className="rounded-circle mt-5" width="150px"
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                            <span className="font-weight-bold">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_nombres') : "samanta@harrys.com"}</span>
                            <span className="text-black-50">{ApiAuth.isAuthenticated() ? localStorage.getItem('login_email') : "samanta@harrys.com"}</span><span> </span></div>
                    </div>
                    <div id="mod-direcc" className={"col-md-9 border-right"}>
                        <HBoxForm id="formData">
                            <div className="p-3 py-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="text-right">Celular</h4>
                                </div>
                                <div className="row mt-3">
                                    <HBoxTextTel id="txCel"
                                        label="Teléfono"
                                        value={this.data && this.data.nrocel}
                                    />
                                    <HBoxtObjCod
                                        id="txIdTipo"
                                        label="Tipo Celular"
                                        required
                                        ref={x => this.txIdTipo = x}
                                        value={this.data.cel_tipo}
                                        valueid={this.data.celtip_valueid}
                                        minLength="1"
                                        maxLength="400"

                                        sqlHelp="hlp_celtip"
                                    />
                                    <HBoxText id="txId"
                                        visibility={false}
                                        label="Id"
                                        required
                                        disabled
                                        value={this.data && this.data.idcel}
                                    />

                                </div>
                                <div className="mt-5 text-center">
                                    <button className="btn btn-primary 
                                    profile-button"
                                        onClick={this.guardar}
                                        type="button">Guardar Celular</button>
                                </div>
                            </div>
                        </HBoxForm>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default WithNavigate(ProfileSetDirEdit);
