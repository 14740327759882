import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import HFormUser from "global/components/Page/Forms/HFormUser";
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";


//import UserContext from 'global/context/user/UserContext';

class HeaderUser extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        //console.log("Form Headre",this)
        const lsRol = localStorage.getItem('login_role');
        const lsEmail = localStorage.getItem('login_email');
        //const lsDescrip = localStorage.getItem('login_descrip');
        const lsNombre = localStorage.getItem('login_nombres');
        const lsApePat = localStorage.getItem('login_ap_pat');
        const lsApeMat = localStorage.getItem('login_ap_mat');
        const lsNombreCompleto = lsNombre + " " + lsApePat + " " + lsApeMat
        return (
            <li className="nav-item dropdown">
                <a className="nav-link pe-0"  id="drop1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="d-flex align-items-center">
                        <div className="user-profile-img">
                            <img src={ApiAuth.isAuthenticated() ? "/FrWork/Avatar/avatar4.png" : "/FrWork/Avatar/avatar3.png"} className="rounded-circle"
                                width="35" height="35" alt="" />
                        </div>
                    </div>
                </a>

                <div className="dropdown-menu XXcontent-dd dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop1">
                    <div XclassName="profile-dropdown position-relative" Xdata-simplebar="init">
                        <div className="Xsimplebar-wrapper" style={{ margin: '0px' }}>
                            <div className="Xsimplebar-height-auto-observer-wrapper">
                                <div className="Xsimplebar-height-auto-observer"></div>
                            </div>
                            <div className="Xsimplebar-mask">
                                <div className="Ximplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                                    <div className="Xsimplebar-content-wrapper" tabIndex="0"
                                        role="region" aria-label="scrollable content"
                                        style={{ height: 'auto', overflow: 'hidden' }}>
                                        <div className="Xsimplebar-content" style={{ padding: '0px' }}>
                                            <div className="py-3 px-7 pb-0">
                                                <h5 className="mb-0 fs-5 fw-semibold">Perfil Usuario
                                                </h5>
                                            </div>
                                            <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                                                <img src={ApiAuth.isAuthenticated() ? "/FrWork/Avatar/avatar4.png" : "/FrWork/Avatar/avatar3.png"}
                                                    className="rounded-circle" width="80"
                                                    height="80" alt="" />
                                                <div className="ms-3">
                                                    <h5 className="mb-1 fs-3">{ApiAuth.isAuthenticated() ? lsNombreCompleto : "Samanta Esteben"}</h5>
                                                    <span className="mb-1 d-block">{ApiAuth.isAuthenticated() ? lsRol : "Diseñadora"}</span>
                                                    {/* <span className="mb-1 d-block">{this.context.dataUser.varUser.idUser}</span> */}
                                                    <p
                                                        className="mb-0 d-flex align-items-center gap-2">
                                                        <i className="ti ti-mail fs-4"></i>
                                                        {ApiAuth.isAuthenticated() ? lsEmail : "samanta@harrys.com"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="message-body">
                                                <Link to={ApiGl.profileRoute} className="py-8 px-7 d-flex Xalign-items-center">
                                                    <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                        <img src="/FrWork/adModern/assets/images/svgs/icon-account.svg"
                                                            alt="" width="24" height="24" />
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3">
                                                        <h6 className="mb-1 fs-3 fw-semibold lh-base">
                                                            Mis Datos
                                                        </h6>
                                                        <span className="fs-2 d-block text-body-secondary">
                                                            Configuración Cuenta
                                                        </span>
                                                    </div>
                                                </Link>
                                                <Link to={ApiGl.usuarioMsgRoute} className="py-8 px-7 d-flex Xalign-items-center">
                                                    <span
                                                        className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                        <img src="/FrWork/adModern/assets/images/svgs/icon-inbox.svg"
                                                            alt="" width="24" height="24" />
                                                    </span>
                                                    <div
                                                        className="w-75 d-inline-block v-middle ps-3">
                                                        <h6 className="mb-1 fs-3 fw-semibold lh-base">
                                                            Mis Mensajes
                                                        </h6>
                                                        <span className="fs-2 d-block text-body-secondary">
                                                            Mensajes
                                                        </span>
                                                    </div>
                                                </Link>
                                                <Link to={ApiGl.usuarioTareasRoute} className="py-8 px-7 d-flex Xalign-items-center">
                                                    <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                        <img src="/FrWork/adModern/assets/images/svgs/icon-tasks.svg"
                                                            alt="" width="24" height="24" />
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3">
                                                        <h6 className="mb-1 fs-3 fw-semibold lh-base">
                                                            Mis Tareas
                                                        </h6>
                                                        <span className="fs-2 d-block text-body-secondary">
                                                            Mis Tareas Pendientes
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="d-grid py-4 px-7 pt-8">
                                                <div className="upgrade-plan bg-primary-subtle position-relative overflow-hidden rounded-4 p-4 mb-9">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h5 className="fs-4 mb-3 w-50 fw-semibold">
                                                                Acceso</h5>
                                                            <button
                                                                className="btn btn-primary">Actualización</button>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="m-n4 unlimited-img">
                                                                <img src="/FrWork/adModern/assets/images/unlimited-bg.png"
                                                                    alt="" className="w-100" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {ApiAuth.isAuthenticated() && (<Link to={ApiGl.singOutRoute}
                                                    className="btn btn-outline-primary ">Salir
                                                </Link>)}
                                                {!ApiAuth.isAuthenticated() && (<Link to={ApiGl.loginRoute}
                                                    className="btn btn-outline-primary ">Iniciar Sessión
                                                </Link>)}
                                                {!ApiAuth.isAuthenticated() && (<Link to={ApiGl.registrateRoute}
                                                    className="btn btn-outline-primary ">Registrarse
                                                </Link>)}                                                
                                                <Link to={ApiGl.forgotPasswordRoute}
                                                    className="btn btn-outline-primary ">Recuperar Clave
                                                </Link>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Xsimplebar-placeholder" style={{ width: '0px', height: '0px' }}>
                            </div>
                        </div>
                        <div className="Xsimplebar-track simplebar-horizontal"
                            style={{ visibility: 'hidden' }}>
                            <div className="Xsimplebar-scrollbar" style={{ width: '0px', display: 'none' }}>
                            </div>
                        </div>
                        <div className="Xsimplebar-track simplebar-vertical"
                            style={{ visibility: 'hidden' }}>
                            <div className="Xsimplebar-scrollbar"
                                style={{ height: '0px', display: 'none' }}></div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}
export default HeaderUser;                                