import React, { Component } from "react";
class ProfileShow extends Component {
    constructor(props) {
        super(props);
        this.data = props
    }
    render() {
        return (<>
            <div className="row">
                <div className="col-lg-4">
                    <div className="card shadow-none border">
                        <div className="card-body">
                            <h4 className="fw-semibold mb-3">Introduction</h4>
                            <p>Hello, I am Jonathan Deo. I love making websites and graphics. Lorem
                                ipsum dolor sit amet,
                                consectetur adipiscing elit.</p>
                            <ul className="list-unstyled mb-0">
                                <li className="d-flex align-items-center gap-6 flex-wrap mb-4">
                                    <i className="ti ti-briefcase text-dark fs-6"></i>
                                    <h6 className="fs-4 fw-semibold mb-0">Sir, P P Institute Of Science</h6>
                                </li>
                                <li className="d-flex align-items-center gap-6 flex-wrap mb-4">
                                    <i className="ti ti-mail text-dark fs-6"></i>
                                    <h6 className="fs-4 fw-semibold mb-0">xyzjonathan@gmail.com</h6>
                                </li>
                                <li className="d-flex align-items-center gap-6 flex-wrap mb-4">
                                    <i className="ti ti-device-desktop text-dark fs-6"></i>
                                    <h6 className="fs-4 fw-semibold mb-0">www.xyz.com</h6>
                                </li>
                                <li className="d-flex align-items-center gap-6 flex-wrap mb-2">
                                    <i className="ti ti-map-pin text-dark fs-6"></i>
                                    <h6 className="fs-4 fw-semibold mb-0">Newyork, USA - 100001</h6>
                                </li>
                            </ul>
                        </div>

                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">About Me</h3>
                            </div>
                            <div className="card-body">
                                <strong><i className="fas fa-book mr-1"></i> Education</strong>

                                <p className="text-muted">
                                    B.S. in Computer Science from the University of Tennessee at Knoxville
                                </p>

                                <hr />

                                <strong><i className="fas fa-map-marker-alt mr-1"></i> Location</strong>

                                <p className="text-muted">Malibu, California</p>

                                <hr />

                                <strong><i className="fas fa-pencil-alt mr-1"></i> Skills</strong>

                                <p className="text-muted">
                                    <span className="tag tag-danger">UI Design</span>
                                    <span className="tag tag-success">Coding</span>
                                    <span className="tag tag-info">Javascript</span>
                                    <span className="tag tag-warning">PHP</span>
                                    <span className="tag tag-primary">Node.js</span>
                                </p>

                                <hr />

                                <strong><i className="far fa-file-alt mr-1"></i> Notes</strong>

                                <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum enim neque.</p>
                            </div>

                        </div>
                    </div>
                    <div className="card shadow-none border">
                        <div className="card-body">
                            <h4 className="fw-semibold mb-3">Avatar</h4>
                            <div className="row">
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-1.jpg" alt="" className="rounded-2 img-fluid mb-9" />
                                </div>
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-2.jpg" alt="" className="rounded-2 img-fluid mb-9" />
                                </div>
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-3.jpg" alt="" className="rounded-2 img-fluid mb-9" />
                                </div>
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-4.jpg" alt="" className="rounded-2 img-fluid mb-9" />
                                </div>
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-5.jpg" alt="" className="rounded-2 img-fluid mb-9" />
                                </div>
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-6.jpg" alt="" className="rounded-2 img-fluid mb-9" />
                                </div>
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-7.jpg" alt="" className="rounded-2 img-fluid mb-6" />
                                </div>
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-8.jpg" alt="" className="rounded-2 img-fluid mb-6" />
                                </div>
                                <div className="col-4">
                                    <img src="/FrWork/Avatar/user-1.jpg" alt="" className="rounded-2 img-fluid mb-6" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}

export default ProfileShow;
