import Api  from "../ApiTurnos";

const _menu =
{
    name: 'Dashboard',
    arMenu: [
        {
            titulo: "Productos", icon: "fa fa-bug"
            , menNiv: [
                //{ titulo: "diaMesAnoRoute", ruta: Api.diaMesAnoRoute, icon: "fa fa-bug" }
                //  { titulo: "generaMesAnoRoute", ruta: Api.generaMesAnoRoute, icon: "fa fa-bug" }
                , { titulo: "datosPersonalesRoute", ruta: Api.cgfModuloRoute, icon: "fa fa-bug" }
                , { titulo: "Home", ruta: "/", icon: "fa fa-bug" }

            ]
        }
    ]
}

export default _menu
