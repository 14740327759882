import React, { Component } from "react";
import Api from "../ApiTurnos";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ShowLoading from "global/components/ShowLoading";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import HBoxTextNum from "global/components/Page/InputHtml/HBoxTextNum";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";

class FDatosCfg extends HFormUser {

    componentDidMount() {
        //this.readEmpresa();
        this.readApi();
    }
    readApi() {
        this.setState({ loading: true });
        this.post(Api.getDatosPersonales)
            .then(data => {
                this.setState({
                      cbCargos: !data.obj?[]:data.obj.regCargos
                    , cbGrados: !data.obj?[]:data.obj.regGrados
                    , cbContratos: !data.obj?[]:data.obj.regContra
                    , cbEmpresa: !data.obj?[]:data.obj.regEmpresa
                })
                this.stateHarrys(this, data)
                
            })
    }
    readEmpresa = () => {
        this.post(Api.cbEmpresa)
            .then(data => {
                this.setState({ cbEmpresa: data.rows })
            })
    }

    combos = () => {
        this.post(Api.comboTurnos, { nroEmp: document.getElementById("cbEmpresa").value })
            .then(data => {
                this.setState({
                    cbCargos: data.obj.regCargos
                    , cbGrados: data.obj.regGrados
                    , cbContratos: data.obj.regContra
                })
            })
    }
    usuSave = () => {
        super.validaForm("FormCfg")
        var data = {
            cbEmpresa: document.getElementById("cbEmpresa").value
            , cbGrado: document.getElementById("cbGrado").value
            , cbCargo: document.getElementById("cbCargo").value
            , cbContra: document.getElementById("cbContra").value
            , valHor: document.getElementById("txValHor").value
        }
        this.post(Api.getDatosPersonalesSave, data)
            .then(data => { if (!data.ok) alert(data.msg) })
    }
    render() {
        if (this.state.loading) return (<ShowLoading />)
        const reg = (this.state.resObj.obj && this.state.resObj.obj.recUsu  && this.state.resObj.obj.recUsu[0]) || null
        return (
            <ContainerRightEdit
                tituloContainer={'Fecha : '}
                contexto={this}
                resObj={this.state.resObj}


                bt1Click={this.usuSave}
                bt1Icon="fa fa-save"
                bt1Class="btn-success"
            >
                <HBoxForm id="FormCfg">
                    <HBoxSelect
                        label="Empresa"
                        id="cbEmpresa"
                        required
                        onChange={this.combos}
                        value={reg && reg.nro_empresa}
                        option={this.state.cbEmpresa}
                    />
                    <HBoxSelect
                        label="Grado"
                        id="cbGrado"
                        required
                        value={reg && reg.idgrado}
                        option={this.state.cbGrados}
                    />
                    <HBoxSelect
                        label="Cargo"
                        id="cbCargo"
                        required
                        value={reg && reg.idcargo}
                        option={this.state.cbCargos}
                    />
                    <HBoxSelect
                        label="Calidad Contractual"
                        id="cbContra"
                        required
                        value={reg && reg.idcontra}
                        option={this.state.cbContratos}
                    />
                    <HBoxTextNum
                        label="Valor Hora"
                        id="txValHor"
                        minLength="3"
                        maxLength="10"
                        value={reg && reg.valor_hora}
                    />
                </HBoxForm>
            </ContainerRightEdit>
        );
    }

}
//<HBoxButtonCrud btActualizarClick={() => this.actualizarApi()}/>

export default WithNavigate(FDatosCfg);