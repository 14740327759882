import React from 'react';
import ContainerRightError from "./ContainerRightError";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HBoxButtonCrud from "global/components/Page/InputHtml/HBoxButtonCrud";
import HFormUser from "global/components/Page/Forms/HFormUser";

class ContainerRigthEdit extends HFormUser {
    render = () => {
        return (
            <div className='card'>
                <div className="card-header text-bg-primary d-flex align-items-center">
                    <h3 className="card-title"><b>{this.data.tituloContainer}</b></h3>
                    {(!this.data.titulo) && (<div className="card-actions cursor-pointer ms-auto d-flex button-group">
                        <div className="float-right ">
                            <HBoxButtonCrud {...this.props} />
                        </div>
                    </div>)}
                </div>
                <div className="card-body collapse show" style={{ borderRadius: '23px' }}>
                    <ContainerRightError data={this.data.resObj} />
                    <div className="container_table1 box-title21 table1-responsive" stylex={{ width: '100%', borderRadius: '23px' }}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
   
}
export default WithNavigate(ContainerRigthEdit);