import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
class HLinkMenu extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        if ((this.data.to + "").startsWith("http")) {
            return (
                <a href={this.data.to + ""} target="_blank"                >
                    <i className={"metismenu-icon " + this.data.icon}></i>
                    <p>{this.data.label}</p>
                    {(!this.data.to) && (<i className="fas fa-angle-left right"></i>)}
                </a>
            )
        }
        return (
            <a style={{ cursor: 'pointer' }}
                onClick={() => this.data.btClickSelect(this.data.tituloArbol || this.data.label, this.data.to)}
                key={this.data.to}
            >
                <i className={"metismenu-icon " + this.data.icon}></i>
                {this.data.label}
                {(!this.data.to) && (<i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>)}

            </a>
        );
    }

}
export default HLinkMenu;