import React, { Component } from 'react';
import DataTable from "global/components/Page/DataTable/DataTableMaterial";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";

class ContainerRightTable extends HFormUser {


    // UNSAFE_componentWillUpdate(nextProps, nextState) {
    //     this.data = nextProps;
    // }
    render = () => {
        let arr_rows = []
        if (this.data.resObj) arr_rows = this.data.resObj.rows
        if (this.data.rows) arr_rows = this.data.rows
        let stIdTable = "tbHarrys_" + (new Date()).getSeconds() + "_" + (new Date()).getMinutes()
        if (this.data.id) stIdTable = this.data.id


        return (<ContainerRightEdit key="iuyu86" {...this.props} swButton={true} >
            {this.props.children}
            <DataTable 
                {...this.props}
                id={stIdTable}
                rows={arr_rows}
                // titulo={this.data.tituloContainer}  No Pasar el Título así no despliega los botonos
                busSearch={this.data.resObj && (this.data.resObj.busSearch || [])}
            />
        </ContainerRightEdit>
        )
    }
}
export default WithNavigate(ContainerRightTable);
