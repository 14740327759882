import React,{Component} from 'react';
import ContainerSystem from "./global/components/Page/CtnSys";
//let ContainerSystem = await import ("./global/components/Page/ContainerSystemAero").then((ContainerSystem)=>{console.log("Cargo  Aero",process.env.REACT_APP_FRAMEWORK)}) ;
// let ContainerSystem = await import ("./global/components/Page/ContainerSystemAero")
// if (!process.env.REACT_APP_FRAMEWORK || process.env.REACT_APP_FRAMEWORK == "AERO"){
//     console.log("Aeror***********************")
// }

class Pagina extends Component{
	constructor(props) {
     super(props);
     this.data=props;
   }
   render =()=>(<ContainerSystem/>)
}    
        
export default  Pagina;

