//https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--page
//npm uninstall react-data-table-component
import React, { Component } from 'react';
import DataTableBase from "./DataTableBase";
//https://datatables.net/download/
/* ConSw solo
  DataTableNaterial
  ContainerRight
*/  
class DataTableHarys extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        return (<DataTableBase {...this.props} key="DataLte7" />)
    }
}
export default DataTableHarys;
