import React, { Component } from "react";

class ProfilePost extends Component {
    constructor(props) {
        super(props);
        this.data = props
    }
    render() {
        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mt-3 mb-4">
                    <h3 className="mb-3 mb-sm-0 fw-semibold d-flex align-items-center">Followers <span
                        className="badge text-bg-secondary fs-2 rounded-4 py-1 px-2 ms-2">20</span></h3>
                    <form className="position-relative">
                        <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh"
                            placeholder="Search Post" />
                        <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y text-dark ms-3"></i>
                    </form>
                </div>


                <div className="col-lg-12">
                    <div className="card shadow-none border">
                        <div className="card-body">
                            <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="Leave a comment here"
                                    id="floatingTextarea2" style={{ height: '137px' }}></textarea>
                                <label htmlFor="floatingTextarea2">Share your thoughts</label>
                            </div>
                            <div className="d-flex align-items-center gap-6 flex-wrap">
                                <a className="d-flex align-items-center justify-content-center text-bg-primary p-2 fs-4 rounded-circle"
                                    href="void(0)">
                                    <i className="ti ti-photo"></i>
                                </a>
                                <a href="void(0)" className="text-dark pe-3 py-2">Photo /
                                    Video</a>
                                <a href="void(0)" className="d-flex align-items-center gap-2">
                                    <div
                                        className="d-flex align-items-center justify-content-center text-bg-secondary p-2 fs-4 rounded-circle">
                                        <i className="ti ti-notebook"></i>
                                    </div>
                                    <span className="text-dark">Article</span>
                                </a>
                                <button className="btn btn-primary ms-auto">Post</button>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body border-bottom">
                            <div className="d-flex align-items-center gap-6 flex-wrap">
                                <img src="/FrWork/Avatar/user-1.jpg" alt="" className="rounded-circle" width="40"
                                    height="40" />
                                <h6 className="fw-semibold mb-0 fs-4">Mathew Anderson</h6>
                                <span className="fs-2"><span
                                    className="p-1 text-bg-light rounded-circle d-inline-block"></span>
                                    15 min
                                    ago</span>
                            </div>
                            <p className="text-dark my-3">
                                Nu kek vuzkibsu mooruno ejepogojo uzjon gag fa ezik disan he nah. Wij wo
                                pevhij tumbug rohsa
                                ahpi ujisapse lo vap labkez eddu suk.
                            </p>
                            <img src="/FrWork/adModern/assets/images/products/s1.jpg" alt=""
                                className="img-fluid rounded-4 w-100 object-fit-cover" style={{ height: '360px' }} />
                            <div className="d-flex align-items-center my-3">
                                <div className="d-flex align-items-center gap-2">
                                    <a className="d-flex align-items-center justify-content-center text-bg-primary p-2 fs-4 rounded-circle"
                                        href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                        data-bs-title="Like">
                                        <i className="ti ti-thumb-up"></i>
                                    </a>
                                    <span className="text-dark fw-semibold">67</span>
                                </div>
                                <div className="d-flex align-items-center gap-2 ms-4">
                                    <a className="d-flex align-items-center justify-content-center text-bg-secondary p-2 fs-4 rounded-circle"
                                        href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                        data-bs-title="Comment">
                                        <i className="ti ti-message-2"></i>
                                    </a>
                                    <span className="text-dark fw-semibold">2</span>
                                </div>
                                <a className="text-dark ms-auto d-flex align-items-center justify-content-center bg-transparent p-2 fs-4 rounded-circle"
                                    href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                    data-bs-title="Share">
                                    <i className="ti ti-share"></i>
                                </a>
                            </div>
                            <div className="position-relative">
                                <div className="p-4 rounded-2 text-bg-light mb-3">
                                    <div className="d-flex align-items-center gap-6 flex-wrap">
                                        <img src="/FrWork/Avatar/user-3.jpg" alt="" className="rounded-circle"
                                            width="33" height="33" />
                                        <h6 className="fw-semibold mb-0 fs-4">Deran Mac</h6>
                                        <span className="fs-2"><span
                                            className="p-1 text-bg-muted rounded-circle d-inline-block"></span>
                                            8 min
                                            ago</span>
                                    </div>
                                    <p className="my-3">Lufo zizrap iwofapsuk pusar luc jodawbac zi op
                                        uvezojroj duwage vuhzoc ja
                                        vawdud le furhez siva
                                        fikavu ineloh. Zot afokoge si mucuve hoikpaf adzuk zileuda
                                        falohfek zoije fuka udune lub
                                        annajor gazo
                                        conis sufur gu.
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center gap-2">
                                            <a className="d-flex align-items-center justify-content-center text-bg-primary p-2 fs-4 rounded-circle"
                                                href="void(0)" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-title="Like">
                                                <i className="ti ti-thumb-up"></i>
                                            </a>
                                            <span className="text-dark fw-semibold">55</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 ms-4">
                                            <a className="d-flex align-items-center justify-content-center text-bg-secondary p-2 fs-4 rounded-circle"
                                                href="void(0)" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-title="Reply">
                                                <i className="ti ti-arrow-back-up"></i>
                                            </a>
                                            <span className="text-dark fw-semibold">0</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 rounded-2 text-bg-light mb-3">
                                    <div className="d-flex align-items-center gap-6 flex-wrap">
                                        <img src="/FrWork/Avatar/user-4.jpg" alt="" className="rounded-circle"
                                            width="33" height="33" />
                                        <h6 className="fw-semibold mb-0 fs-4">Jonathan Bg</h6>
                                        <span className="fs-2"><span
                                            className="p-1 text-bg-muted rounded-circle d-inline-block"></span>
                                            5 min
                                            ago</span>
                                    </div>
                                    <p className="my-3">
                                        Zumankeg ba lah lew ipep tino tugjekoj hosih fazjid wotmila
                                        durmuri buf hi sigapolu joit
                                        ebmi joge vo.
                                        Horemo vogo hat na ejednu sarta afaamraz zi cunidce peroido
                                        suvan podene igneve.
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center gap-2">
                                            <a className="d-flex align-items-center justify-content-center text-bg-primary p-2 fs-4 rounded-circle"
                                                href="void(0)" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-title="Like">
                                                <i className="ti ti-thumb-up"></i>
                                            </a>
                                            <span className="text-dark fw-semibold">68</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 ms-4">
                                            <a className="d-flex align-items-center justify-content-center text-bg-secondary p-2 fs-4 rounded-circle"
                                                href="void(0)" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-title="Reply">
                                                <i className="ti ti-arrow-back-up"></i>
                                            </a>
                                            <span className="text-dark fw-semibold">1</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 rounded-2 text-bg-light ms-7">
                                    <div className="d-flex align-items-center gap-6 flex-wrap">
                                        <img src="/FrWork/Avatar/user-5.jpg" alt="" className="rounded-circle"
                                            width="40" height="40" />
                                        <h6 className="fw-semibold mb-0 fs-4">Carry minati</h6>
                                        <span className="fs-2"><span
                                            className="p-1 text-bg-muted rounded-circle d-inline-block"></span>
                                            just
                                            now</span>
                                    </div>
                                    <p className="my-3">
                                        Olte ni somvukab ugura ovaobeco hakgoc miha peztajo tawosu
                                        udbacas kismakin hi. Dej
                                        zetfamu cevufi sokbid bud mun soimeuha pokahram vehurpar keecris
                                        pepab voegmud
                                        zundafhef hej pe.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-6 flex-wrap p-3">
                            <img src="/FrWork/Avatar/user-1.jpg" alt="" className="rounded-circle" width="33"
                                height="33" />
                            <input type="text" className="form-control py-8" id="exampleInputtext"
                                aria-describedby="textHelp" placeholder="Comment" />
                            <button className="btn btn-primary">Comment</button>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body border-bottom">
                            <div className="d-flex align-items-center gap-6 flex-wrap">
                                <img src="/FrWork/Avatar/user-5.jpg" alt="" className="rounded-circle" width="40"
                                    height="40" />
                                <h6 className="fw-semibold mb-0 fs-4">Carry Minati</h6>
                                <span className="fs-2"><span
                                    className="p-1 text-bg-light rounded-circle d-inline-block"></span>
                                    now</span>
                            </div>
                            <p className="text-dark my-3">
                                Pucnus taw set babu lasufot lawdebuw nem ig bopnub notavfe pe ranlu
                                dijsan liwfekaj lo az. Dom
                                giat gu
                                sehiosi bikelu lo eb uwrerej bih woppoawi wijdiola iknem hih suzega
                                gojmev kir rigoj.
                            </p>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center gap-2">
                                    <a className="d-flex align-items-center justify-content-center text-bg-primary p-2 fs-4 rounded-circle"
                                        href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                        data-bs-title="Like">
                                        <i className="ti ti-thumb-up"></i>
                                    </a>
                                    <span className="text-dark fw-semibold">1</span>
                                </div>
                                <div className="d-flex align-items-center gap-2 ms-4">
                                    <a className="d-flex align-items-center justify-content-center text-bg-secondary p-2 fs-4 rounded-circle"
                                        href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                        data-bs-title="Comment">
                                        <i className="ti ti-message-2"></i>
                                    </a>
                                    <span className="text-dark fw-semibold">0</span>
                                </div>
                                <a className="text-dark ms-auto d-flex align-items-center justify-content-center bg-transparent p-2 fs-4 rounded-circle"
                                    href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                    data-bs-title="Share">
                                    <i className="ti ti-share"></i>
                                </a>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-6 flex-wrap p-3">
                            <img src="/FrWork/Avatar/user-5.jpg" alt="" className="rounded-circle" width="33"
                                height="33" />
                            <input type="text" className="form-control py-8" id="exampleInputtext1"
                                aria-describedby="textHelp" placeholder="Comment" />
                            <button className="btn btn-primary">Comment</button>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body border-bottom">
                            <div className="d-flex align-items-center gap-6 flex-wrap">
                                <img src="/FrWork/Avatar/user-2.jpg" alt="" className="rounded-circle" width="40"
                                    height="40" />
                                <h6 className="fw-semibold mb-0 fs-4">Genelia Desouza</h6>
                                <span className="fs-2"><span
                                    className="p-1 text-bg-light rounded-circle d-inline-block"></span>
                                    15 min
                                    ago</span>
                            </div>
                            <p className="text-dark my-3">
                                Faco kiswuoti mucurvi juokomo fobgi aze huweik zazjofefa kuujer talmoc
                                li niczot lohejbo vozev
                                zi huto. Ju
                                tupma uwujate bevolkoh hob munuap lirec zak ja li hotlanu pigtunu.
                            </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <img src="/FrWork/adModern/assets/images/products/s2.jpg" alt=""
                                        className="img-fluid rounded-4 mb-3 mb-sm-0" />
                                </div>
                                <div className="col-sm-6">
                                    <img src="/FrWork/adModern/assets/images/products/s4.jpg" alt="" className="img-fluid rounded-4" />
                                </div>
                            </div>
                            <div className="d-flex align-items-center my-3">
                                <div className="d-flex align-items-center gap-2">
                                    <a className="text-dark d-flex align-items-center justify-content-center bg-light p-2 fs-4 rounded-circle"
                                        href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                        data-bs-title="Like">
                                        <i className="ti ti-thumb-up"></i>
                                    </a>
                                    <span className="text-dark fw-semibold">320</span>
                                </div>
                                <div className="d-flex align-items-center gap-2 ms-4">
                                    <a className="d-flex align-items-center justify-content-center text-bg-secondary p-2 fs-4 rounded-circle"
                                        href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                        data-bs-title="Comment">
                                        <i className="ti ti-message-2"></i>
                                    </a>
                                    <span className="text-dark fw-semibold">1</span>
                                </div>
                                <a className="text-dark ms-auto d-flex align-items-center justify-content-center bg-transparent p-2 fs-4 rounded-circle"
                                    href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                    data-bs-title="Share">
                                    <i className="ti ti-share"></i>
                                </a>
                            </div>
                            <div className="p-4 rounded-2 text-bg-light">
                                <div className="d-flex align-items-center gap-6 flex-wrap">
                                    <img src="/FrWork/Avatar/user-3.jpg" alt="" className="rounded-circle" width="33"
                                        height="33" />
                                    <h6 className="fw-semibold mb-0 fs-4">Ritesh Deshmukh</h6>
                                    <span className="fs-2"><span
                                        className="p-1 text-bg-muted rounded-circle d-inline-block"></span>
                                        15 min
                                        ago</span>
                                </div>
                                <p className="my-3">
                                    Hintib cojno riv ze heb cipcep fij wo tufinpu bephekdab infule
                                    pajnaji. Jiran goetimip muovo
                                    go en
                                    gaga zeljomim hozlu lezuvi ehkapod dec bifoom hag dootasac odo
                                    luvgit ti ella.
                                </p>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center gap-2">
                                        <a className="d-flex align-items-center justify-content-center text-bg-primary p-2 fs-4 rounded-circle"
                                            href="void(0)" data-bs-toggle="tooltip"
                                            data-bs-placement="top" data-bs-title="Like">
                                            <i className="ti ti-thumb-up"></i>
                                        </a>
                                        <span className="text-dark fw-semibold">65</span>
                                    </div>
                                    <div className="d-flex align-items-center gap-2 ms-4">
                                        <a className="d-flex align-items-center justify-content-center text-bg-secondary p-2 fs-4 rounded-circle"
                                            href="void(0)" data-bs-toggle="tooltip"
                                            data-bs-placement="top" data-bs-title="Reply">
                                            <i className="ti ti-arrow-back-up"></i>
                                        </a>
                                        <span className="text-dark fw-semibold">0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-6 flex-wrap p-3">
                            <img src="/FrWork/Avatar/user-2.jpg" alt="" className="rounded-circle" width="33"
                                height="33" />
                            <input type="text" className="form-control py-8" id="exampleInputtext2"
                                aria-describedby="textHelp" placeholder="Comment" />
                            <button className="btn btn-primary">Comment</button>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body border-bottom">
                            <div className="d-flex align-items-center gap-6 flex-wrap">
                                <img src="/FrWork/Avatar/user-1.jpg" alt="" className="rounded-circle" width="40"
                                    height="40" />
                                <h6 className="fw-semibold mb-0 fs-4">Mathew Anderson</h6>
                                <span className="fs-2"><span
                                    className="p-1 text-bg-light rounded-circle d-inline-block"></span>
                                    15 min
                                    ago</span>
                            </div>
                            <p className="text-dark my-3">
                                Faco kiswuoti mucurvi juokomo fobgi aze huweik zazjofefa kuujer talmoc
                                li niczot lohejbo vozev
                                zi huto. Ju
                                tupma uwujate bevolkoh hob munuap lirec zak ja li hotlanu pigtunu.
                            </p>
                            <iframe className="rounded-4 border border-2 mb-3" width="100%" style={{ height: '300px' }}
                                src="https://www.youtube.com/embed/aX9d_ddbtvs?si=UrAa8pxDKFd9Hx0H"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center gap-2">
                                    <a className="d-flex align-items-center justify-content-center text-bg-primary p-2 fs-4 rounded-circle"
                                        href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                        data-bs-title="Like">
                                        <i className="ti ti-thumb-up"></i>
                                    </a>
                                    <span className="text-dark fw-semibold">129</span>
                                </div>
                                <div className="d-flex align-items-center gap-2 ms-4">
                                    <a className="d-flex align-items-center justify-content-center text-bg-secondary p-2 fs-4 rounded-circle"
                                        href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                        data-bs-title="Comment">
                                        <i className="ti ti-message-2"></i>
                                    </a>
                                    <span className="text-dark fw-semibold">0</span>
                                </div>
                                <a className="text-dark ms-auto d-flex align-items-center justify-content-center bg-transparent p-2 fs-4 rounded-circle"
                                    href="void(0)" data-bs-toggle="tooltip" data-bs-placement="top"
                                    data-bs-title="Share">
                                    <i className="ti ti-share"></i>
                                </a>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-6 flex-wrap p-3">
                            <img src="/FrWork/Avatar/user-1.jpg" alt="" className="rounded-circle" width="33"
                                height="33" />
                            <input type="text" className="form-control py-8" id="exampleInputtext3"
                                aria-describedby="textHelp" placeholder="Comment" />
                            <button className="btn btn-primary">Comment</button>
                        </div>
                    </div>
                </div>



                </>)
    }
}

export default ProfilePost;
