//https://www.escuelafrontend.com/context-para-el-manejo-de-estado
//https://es.legacy.reactjs.org/docs/context.html
//https://es.reactjs.org/docs/context.html

// Funciona
//  https://platzi.com/blog/tutorial-como-acceder-a-los-datos-de-tu-aplicacion-con-react-context-api/?gclid=Cj0KCQiAjc2QBhDgARIsAMc3SqSZGKHSivpoHOJlmt2WLoYTll3E2wc3ok8wpWTuZ1Xun-Xljv3P21waAgFYEALw_wcB&gclsrc=aw.ds
import { createContext } from "react";
const UserContext = createContext({})
// const UserContext = createContext({data:null,toggleButton: () => {alert("toggleButton")},});
UserContext.displayName = 'ContextHarrys';
//export const UserProvider = UserContext.Provider
//export const UserConsumer = UserContext.Consumer
export default UserContext