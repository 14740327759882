import React, { Component } from 'react';
//import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiGl from "global/ApiGlobal";


class HeaderPagePadre extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    //componentDidMount() { this.readLogin(); }
    readLogin() {
        this.post(ApiGl.readLogin)
            .then(data => { this.stateHarrys(this, data) })
    }
    render = () => {}
}
export default HeaderPagePadre;
