import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ShowError from "global/components/ShowError";
import ApiGlo from "global/ApiGlobal";
import ApiAuth from "../auth";
import BoxCenter from "./BoxCenter";
class ForgotPassword extends HFormUser {
    state = {
        message: "",
        error: ""
    };

    forgotPassword = e => {
        e.preventDefault();
        this.setState({ message: "", error: "" });
        const data = { email: document.getElementById("email").value }
        this.post(ApiGlo.forgotPassword, data)
            .then(data => {
                //console.log("respuesta",data)
                if (!data.ok) {
                    this.setState({ error: data.msg });
                } else {
                    this.setState({ message: data.msg });
                    //apiRuta.toRutaClear(this) 
                    /*
                    ApiAuth.authenticate(data,user, () => {
                        this.setState({ redirectToReferer: true});
                      });
                  */
                }
            }
            )
    };

    render() {
        return (<BoxCenter titulo="Recuperar Clave">
            <form>
                <ShowError
                    msg={this.state.message}
                    err={this.state.error} />
                <div className="mt-4">
                    <div className="col-md-12">
                        <div className="position-relative form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Ingrese su Email"
                                id="email"
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4">

                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div  className="form-check">
                        <Link to={ApiGlo.loginRoute} className="btn btn-raised   float-none2 btn-outline-primary">
                            Inisio Sesión
                        </Link>
                    </div>

                    <Link to={ApiGlo.registrateRoute} className="btn btn-raised   float-none2 btn-outline-primary">
                        No tienes Cuenta? /Registrate
                    </Link>
                </div>
                <br />
                <div className="mb-4">
                    <a onClick={this.forgotPassword} className="btn btn-primary w-100 py-8 mb-4 rounded-2">
                        Enviar enlace de restablecimiento de contraseña
                    </a>
                </div>
            </form>


        </BoxCenter >
        );
    }
}
export default ForgotPassword;
