import { Component } from 'react';
import UserContext from 'global/context/user/UserContext';
import ApiRuta from "global/utils/funRuta";
import ApiGl from "global/ApiGlobal";

class HFormUser extends Component {
    swRutea=false
    //static contextType = UserContext;
    constructor(props) {
        super(props);
        //this.userVar = this.context;
        this.data = props;
        this.state = {
            data: [],
            resObj: [],
            loading: true,
            loadGr: true,
            swError: false,
        };
    }
    // componentDidMount() {this.userVar = this.context;}
    // componentDidUpdate() {this.userVar = this.context;}
    // componentWillUnmount() {this.userVar = this.context;}
    // render() {this.userVar = this.context;}

    addMenu(_menu) {
        if(1==1) return
        _menu.arMenu.push({
            titulo: "Sessión"
            , icon: "fa fa-bug"
            , menNiv: [
                {
                    titulo: "Cerrar Session"
                    , icon: "fa fa-bug"
                    , ruta: ApiGl.singOutRoute
                }
            ]
        }
            , {
                titulo: "FrameWork"
                , icon: "fa fa-bug"
                , menNiv: [
                    { titulo: "AdminLte", ruta: "/index_AdLte.html", icon: "fa fa-bug" }
                    , { titulo: "Architec", ruta: "/index_AdArchi.html", icon: "fa fa-bug" }
                    , { titulo: "Aero", ruta: "/index.html", icon: "fa fa-bug" }
                ]
            })
    }
    //componentDidMount() {this.usuarioVar = this.context; }
    reDirect = (objData) => {
        //console.log("objData",objData)
        if (objData.reDirect) ApiRuta.toRutaAdd(this, "", objData.reDirect, null)
    }
    saltarRutaContext = (form, iLin) => {
        //console.log("saltacontext:",form)
        ApiRuta.toRutaPop(form, iLin)
    }
    saltarRuta = (iLin) => {
        //console.log("salta:",this.data,this.data.contexto)
        ApiRuta.toRutaPop(this.data.contexto, iLin)
    }
    saltarVolverContext = (form) => {
        //console.log("Volver:",this.data,this.data.contexto)
        ApiRuta.toRutaBack(form)
    }
    saltarVolver = () => {
        //console.log("Volver:",this.data,this.data.contexto)
        ApiRuta.toRutaBack(this.data.contexto)
    }
    msgFormError() {
        alert("Corriga los Errores")
    }
    validaForm(stIdForm) {
        if (!this.revisaCampos(stIdForm)) {
            this.msgFormError()
            return false
        }
        return true
    }
    upLoadImg64(stFileObj, path, data) { return this.context.dataUser.upLoadImg64(stFileObj, path, data) }
    upLoadFile (stFileObj, path, data) { return this.context.dataUser.upLoadFile (stFileObj, path, data) }
    post(stUrl, pParam) { return this.context.dataUser.post(stUrl, pParam) }
    stateHarrys(form,pData) { return this.context.dataUser.stateHarrys(form, pData) }
    //myContext() { this.usuarioVar = this.context }
    revisaCampos(stIdForm) {
        let swValido=true
        let stFormName = "forms-registro"
        //console.log("validaForm")
        let dataErr = [];
        //"#data--json > input"
        if (stIdForm) stFormName = stIdForm
        //const formElem = document.querySelectorAll("");
        const form = document.getElementById(stFormName)
        if (!form) {
            alert("No Existe Formulario : " + stFormName)
            return false
        }
        const formInput = form.querySelectorAll("input");
        for (let i = 0; i < formInput.length; i++) {
            const objEle = formInput[i]
            const key = objEle.getAttribute("id");
            if (!objEle.disabled && key != null) {
                //console.log("obj",key,objEle)
                const keyValid = objEle.getAttribute("valid");
                const keyPat = objEle.getAttribute("pattern");
                const keyVal = objEle.getAttribute("value");
                const keyReq = objEle.getAttribute("required");
                const keyMin = objEle.getAttribute("minLength");
                const keyMax = objEle.getAttribute("maxLength");
                if (this.swRutea ) console.log("keyText:", key, keyValid, keyPat, keyVal, keyReq,keyMin,keyMax)
                if ((keyVal + "").trim() == "") {
                    if (keyMin > 0) {
                        swValido =  false;
                        dataErr.push({campo:key,msg:"Error, Campo Vacío"})
                    }
                }
                else {
                    if (keyPat != null) {
                        this.condition = new RegExp(keyPat);
                        let valTest = this.condition.test(keyVal);
                        if (!valTest) {
                            dataErr.push({campo:key,msg:"RegExp",regexp:keyPat})
                            swValido =  false;
                        }   
                    }
                    if ((keyVal + "").length < keyMin) {
                        dataErr.push({campo:key,msg:"Cantidad de letras menor al requerido"})
                        swValido= false
                    }

                    //const elem = formInput[i];
                    //data[`${key}`] = elem.value;
                }
            }
        }
        const formSel = form.querySelectorAll("select");
        for (let i = 0; i < formSel.length; i++) {
            const objEle = formSel[i]
            const key = objEle.getAttribute("id");
            const keyReq = objEle.getAttribute("required");
            if (this.swRutea )console.log("keySel:", key, objEle, objEle.required, keyReq, objEle.selectedIndex, objEle.value)
            if (objEle.required && objEle.value == -1){
                dataErr.push({campo:key,msg:"Error, Seleecione un ítem"})
                swValido= false
            } 
        }

        if (this.swRutea)console.log("errores",dataErr)
        return swValido;
    }
    btVolver = () => {ApiRuta.toRutaBack(this)}
}
HFormUser.contextType = UserContext;
export default HFormUser;