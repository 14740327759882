//import { useNavigate,useHistory  } from "react-router-dom";
//import { Navigate } from 'react-router-dom';
//https://stackabuse.com/programmatically-navigate-using-react-router/
const apiRuta = {
    toRutaFirst(form,stTitulo,stPath,objPar){
        form.context.dataUser.removeRuta(-1)
        this.toRutaAdd(form,stTitulo,stPath,objPar)
    },
    toRutaAdd(form,stTitulo,stPath,objPar){
        if (form === undefined) return
        if (form.context !== undefined && form.context.dataUser !==undefined){
            form.context.dataUser.addRuta(stTitulo,stPath,objPar)
        }
         if (form.props.navigate)
             //form.props.navigate({pathname:stPath,state: objPar});
             form.props.navigate(stPath,{state: objPar});

        //const navigate = useNavigate();        navigate({pathname:stPath,state: objPar })
        //history = useHistory();history.push({pathname:stPath            ,state: objPar        })
        // else
        //    history.push({pathname:stPath
        //     ,state: objPar
        // });
    },
    toRutaMenu(form,stTitulo,stPath,objPar){
        //console.log("toRutaMenu",form)
        if (form === undefined) return
        if (form.context !== undefined)  form.context.dataUser.removeRuta(-1)
        this.toRutaAdd(form,stTitulo,stPath,objPar)
    },
    toRutaClear(form){
        if (form === undefined) return
        if (form.context === undefined) return
        form.context.dataUser.removeRuta(-1)
    },
    toRutaPop(form,index){
        if (index < 0) return
        //console.log("toRutaPop",index,form)
        if (form === undefined) return
        if (index < 0) return
        //console.log("toRutaPop",form.context.dataUser)
        form.context.dataUser.removeRuta(index)
        if (form.props.navigate !== undefined)
            form.props.navigate(form.context.dataUser.getRuta(index)
                           ,{state: form.context.dataUser.getObjPar(index)});
    },
    toRutaBack(form){
        //console.log("toRutaBack000",form)
        if (form === undefined) return
        if (form.context === undefined) return
        let indUlt= form.context.dataUser.removeRutaLast()
        //console.log("toRutaBack 00",indUlt,form)
        if (indUlt < 0) return
        
        if (form.props.navigate !== undefined)
            form.props.navigate(form.context.dataUser.getRuta(indUlt)
                                ,{state: form.context.dataUser.getObjPar(indUlt)});

        //console.log("toRutaBack 11",indUlt,form.context.dataUser.getRuta(indUlt),form.context.dataUser.getObjPar(indUlt))
    },
    toUpdateRutaLast(form,objPar){
        if (form.props.navigate !== undefined)
            form.context.dataUser.updateRutaObjParLast (objPar)
    },
    setStateGlb(form){
        if (form.context == undefined) return ""
        return form.context.dataUser.setStateGlb()
    },
    getStateData(form,nombre){
        //console.log("Ruta GetState",form,nombre)
        if (form.context == undefined) return ""
        return form.context.dataUser.getStateData(nombre)
    },
    setStateData(form,nombre,valor){
        //console.log("Ruta SetState",form,nombre,valor)
        if (form.context == undefined) return ""
        form.context.dataUser.setStateData(nombre,valor)
    }
}
export default apiRuta;