import React,{Component} from 'react';
class HBoxButtonCrud extends Component{
	constructor(props) {
     super(props);
     this.data=props;
     this.state = {value: "",}; 
   }
   UNSAFE_componentWillUpdate(nextProps, nextState){
    this.data=nextProps;
  }
  render =()=>{
                    //onChange={this.handleChange("value")} 
	    return (
                  
                <div className="card-tools" stylec={{backgroundColor:'red'}}>
                  <div className="input-group input-group-sm" stylec={{backgroundColor:'yellow'}}>

                    {/*this.data.btSearchClick   ? (<input type="text" name="tx_table_search" id="tx_table_search" className="form-control float-right" placeholder="Search"/>): ("")*/}
                    <div className="input-group-append" stylec={{backgroundColor:'blue'}}>
{this.data.btSearchClick   ? (<button onClick={()=> this.data.btSearchClick()} className="btn btn-default"><i className="fa fa-search"></i></button>): ("")}

{this.data.btLeerClick       ? (<><button onClick={()=> this.data.btLeerClick()}       name="btLeer"       id="btLeer"       type="button" align="right" className="btn  btn-primary" value="1" disabled ><i className="fa fa-save"></i> {this.data.btLeerLabel||'Leer'}</button>&nbsp;</>): ("")}                
{this.data.btActualizarClick ? (<><button onClick={()=> this.data.btActualizarClick()} name="btActualizar" id="btActualizar" type="button" align="right" className="btn  btn-primary" value="1"><i className="fa fa-save"></i>  {this.data.btActualizarLabel||'Actualizar'}</button>&nbsp;</>): ("")}
{this.data.btEliminarClick   ? (<><button onClick={()=> this.data.btEliminarClick()}   name="btEliminar"   id="btEliminar"   type="button" align="right" className="btn  btn-primary" value="1"><i className="fa fa-save"></i>  {this.data.btEliminarLabel||'Eliminar'}</button>&nbsp;</>  ): ("")}
{this.data.btListarClick     ? (<><button onClick={()=> this.data.btListarClick()}     name="btListar"     id="btListar"     type="button" align="right" className="btn  btn-success" value="1"><i className="fa fa-save"></i>  {this.data.btListarLabel||'Listar'}</button>&nbsp;</>       ): ("")}
{this.data.btCancelarClick   ? (<><button onClick={()=> this.data.btCancelarClick()}   name="btCancelar"   id="btCancelar"   type="button" align="right" className="btn  btn-danger"  value="1"><i className="fa fa-save"></i>  {this.data.btCancelarLabel||'Cancelar'}</button>&nbsp;</>   ): ("")}
{this.data.btActivarClick    ? (<><button onClick={()=> this.data.btActivarClick()}    name="btctivar"     id="btActivar"    type="button" align="right" className="btn  btn-danger"  value="1"><i className="fa fa-save"></i>  {this.data.btActivarLabel||'Activar'}</button>&nbsp;</>   ): ("")}
{this.data.bt1Click   ? (<><button onClick={()=> this.data.bt1Click()}   name="bt1"   id="bt1"   type="button" align="right" className={'btn ' + (this.data.bt1Class||'btn-success')}  value="1"><i className={'fa ' + (this.data.bt1Icon||'fa-eye')}></i>  {this.data.bt1Label||''}</button>&nbsp;</>   ): ("")}
{this.data.bt2Click   ? (<><button onClick={()=> this.data.bt2Click()}   name="bt2"   id="bt2"   type="button" align="right" className={'btn ' + (this.data.bt2Class||'btn-success')}><i className={'fa ' + (this.data.bt2Icon||'fa-eye')}></i>  {this.data.bt2Label||''}</button>&nbsp;</>   ): ("")}
{this.data.bt3Click   ? (<><button onClick={()=> this.data.bt3Click()}   name="bt3"   id="bt3"   type="button" align="right" className={'btn ' + (this.data.bt3Class||'btn-success')}><i className={'fa ' + (this.data.bt3Icon||'fa-eye')}></i>  {this.data.bt3Label||''}</button>&nbsp;</>   ): ("")}
{this.data.bt4Click   ? (<><button onClick={()=> this.data.bt4Click()}   name="bt4"   id="bt4"   type="button" align="right" className={'btn ' + (this.data.bt4Class||'btn-success')}><i className={'fa ' + (this.data.bt4Icon||'fa-eye')}></i>  {this.data.bt4Label||''}</button>&nbsp;</> ): ("")}
{this.data.bt5Click   ? (<><button onClick={()=> this.data.bt5Click()}   name="bt5"   id="bt5"   type="button" align="right" className={'btn ' + (this.data.bt5Class||'btn-success')}><i className={'fa ' + (this.data.bt5Icon||'fa-eye')}></i>  {this.data.bt5Label||''}</button>&nbsp;</> ): ("")}
{this.data.bt6Click   ? (<><button onClick={()=> this.data.bt6Click()}   name="bt6"   id="bt6"   type="button" align="right" className={'btn ' + (this.data.bt6Class||'btn-success')}><i className={'fa ' + (this.data.bt6Icon||'fa-eye')}></i>  {this.data.bt5Label||''}</button>&nbsp;</> ): ("")}

                    </div>
                  </div>
                </div>




          
      );
 	}  
 
}    
export default  HBoxButtonCrud;