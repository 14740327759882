import React, { Component } from 'react';
class HBarraTabsChild extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        return (<div className={"tab-pane fade " + this.data.className}
            id={"pills-" + this.data.id}
            role="tabpanel"
            aria-labelledby={"pills-" + this.data.id + "-tab"}
            tabIndex="0">
            {this.props.children}
        </div>
        );
    }

}
export default HBarraTabsChild;