import React, { Component } from 'react';




//import UserContext from 'global/context/user/UserContext';

class HeaderNotif extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        return (
            <li className="nav-item dropdown">
                <a className="nav-link nav-icon-hover" href="void(0)" id="drop2"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="ti ti-bell-ringing"></i>
                    <div className="notification bg-primary rounded-circle"></div>
                </a>
                <div className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2">
                    <div className="d-flex align-items-center justify-content-between py-3 px-7">
                        <h5 className="mb-0 fs-5 fw-semibold">Notifications</h5>
                        <span className="badge text-bg-primary rounded-4 px-3 py-1 lh-sm">5
                            new</span>
                    </div>
                    <div className="message-body" data-simplebar="init">
                        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer"></div>
                            </div>
                            <div className="simplebar-mask">
                                <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                                    <div className="simplebar-content-wrapper" tabIndex="0"
                                        role="region" aria-label="scrollable content"
                                        style={{ height: 'auto', overflow: 'hidden' }}>
                                        <div className="simplebar-content" style={{ padding: '0px' }}>
                                            <a href="void(0)"
                                                className="py-6 px-7 d-flex align-items-center dropdown-item">
                                                <span className="me-3">
                                                    <img src="/FrWork/Avatar/user-1.jpg"
                                                        alt="user" className="rounded-circle"
                                                        width="48" height="48" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle">
                                                    <h6 className="mb-1 fw-semibold lh-base">Roman
                                                        Joined the Team!</h6>
                                                    <span
                                                        className="fs-2 d-block text-body-secondary">Congratulate
                                                        him</span>
                                                </div>
                                            </a>
                                            <a href="void(0)"
                                                className="py-6 px-7 d-flex align-items-center dropdown-item">
                                                <span className="me-3">
                                                    <img src="/FrWork/Avatar/user-2.jpg"
                                                        alt="user" className="rounded-circle"
                                                        width="48" height="48" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle">
                                                    <h6 className="mb-1 fw-semibold lh-base">New
                                                        message</h6>
                                                    <span
                                                        className="fs-2 d-block text-body-secondary">Salma
                                                        sent you new message</span>
                                                </div>
                                            </a>
                                            <a href="void(0)"
                                                className="py-6 px-7 d-flex align-items-center dropdown-item">
                                                <span className="me-3">
                                                    <img src="/FrWork/Avatar/user-3.jpg"
                                                        alt="user" className="rounded-circle"
                                                        width="48" height="48" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle">
                                                    <h6 className="mb-1 fw-semibold lh-base">Bianca
                                                        sent payment</h6>
                                                    <span
                                                        className="fs-2 d-block text-body-secondary">Check
                                                        your earnings</span>
                                                </div>
                                            </a>
                                            <a href="void(0)"
                                                className="py-6 px-7 d-flex align-items-center dropdown-item">
                                                <span className="me-3">
                                                    <img src="/FrWork/Avatar/user-4.jpg"
                                                        alt="user" className="rounded-circle"
                                                        width="48" height="48" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle">
                                                    <h6 className="mb-1 fw-semibold lh-base">Jolly
                                                        completed tasks</h6>
                                                    <span
                                                        className="fs-2 d-block text-body-secondary">Assign
                                                        her new tasks</span>
                                                </div>
                                            </a>
                                            <a href="void(0)"
                                                className="py-6 px-7 d-flex align-items-center dropdown-item">
                                                <span className="me-3">
                                                    <img src="/FrWork/Avatar/user-5.jpg"
                                                        alt="user" className="rounded-circle"
                                                        width="48" height="48" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle">
                                                    <h6 className="mb-1 fw-semibold lh-base">John
                                                        received payment</h6>
                                                    <span
                                                        className="fs-2 d-block text-body-secondary">$230
                                                        deducted from account</span>
                                                </div>
                                            </a>
                                            <a href="void(0)"
                                                className="py-6 px-7 d-flex align-items-center dropdown-item">
                                                <span className="me-3">
                                                    <img src="/FrWork/Avatar/user-1.jpg"
                                                        alt="user" className="rounded-circle"
                                                        width="48" height="48" />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle">
                                                    <h6 className="mb-1 fw-semibold lh-base">Roman
                                                        Joined the Team!</h6>
                                                    <span
                                                        className="fs-2 d-block text-body-secondary">Congratulate
                                                        him</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="simplebar-placeholder" style={{ width: '0px', height: '0px' }}>
                            </div>
                        </div>
                        <div className="simplebar-track simplebar-horizontal"
                            style={{ visibility: 'hidden' }}>
                            <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}>
                            </div>
                        </div>
                        <div className="simplebar-track simplebar-vertical"
                            style={{ visibility: 'hidden' }}>
                            <div className="simplebar-scrollbar"
                                style={{ height: '0px', display: 'none' }}></div>
                        </div>
                    </div>
                    <div className="py-6 px-7 mb-1">
                        <button className="btn btn-outline-primary w-100">See All
                            Notifications</button>
                    </div>

                </div>
            </li>
        )
    }
}
export default HeaderNotif;