import React, { Component } from "react";
import ShowLoading from "global/components/ShowLoading";
import ApiGl from "global/ApiGlobal";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ProfileFollow from "./ProfileFollow";
import ProfileFriends from "./ProfileFriends";
import ProfileGallery from "./ProfileGallery";
import ProfilePost from "./ProfilePost";
import ProfileSetting from "./ProfileSetting";
import ProfileSetDir from "./ProfileSetDir";
import ProfileSetCel from "./ProfileSetCel";
import ProfileShow from "./ProfileShow";
import HBarraTab from "global/components/Page/Tabs/HBarraTab";
import HBarraTabChild from "global/components/Page/Tabs/HBarraTabChild";

//https://freefrontend.com/bootstrap-profiles/
//https://mdbootstrap.com/docs/standard/extended/profiles/
class Profile extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props
    }
    componentDidMount = () => { this.readUser() }
    readUser = () => {
        this.post(ApiGl.profileRead)
            .then(data => { this.stateHarrys(this, data) })

    }
    render() {
        if (this.state.loading) return (<ShowLoading />)
        console.log(this.state.resObj)
        const regData = this.state.resObj.dataObj && this.state.resObj.dataObj.persona
        const regDir = this.state.resObj.dataObj && this.state.resObj.dataObj.regDir
        const regCel = this.state.resObj.dataObj && this.state.resObj.dataObj.regCel
        return (<>
            <div className="card overflow-hidden">
                <div className="card-body p-0">
                    {false && <img src="/FrWork/adModern/assets/images/profile/profilebg.jpg" alt="" className="img-fluid" />}
                    <div className="row align-items-center">
                        <div className="col-lg-4 order-lg-1 order-2">
                            <div className="d-flex align-items-center justify-content-around m-4">
                                <div className="text-center">
                                    <i className="ti ti-file-description fs-6 d-block mb-2"></i>
                                    <h4 className="mb-0 fw-semibold lh-1">938</h4>
                                    <p className="mb-0 fs-4">Posts</p>
                                </div>
                                <div className="text-center">
                                    <i className="ti ti-user-circle fs-6 d-block mb-2"></i>
                                    <h4 className="mb-0 fw-semibold lh-1">3,586</h4>
                                    <p className="mb-0 fs-4">Followers</p>
                                </div>
                                <div className="text-center">
                                    <i className="ti ti-user-check fs-6 d-block mb-2"></i>
                                    <h4 className="mb-0 fw-semibold lh-1">2,659</h4>
                                    <p className="mb-0 fs-4">Following</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-n3 order-lg-2 order-1">
                            <div className="mt-n5">
                                <div className="d-flex align-items-center justify-content-center mb-2">
                                    <div className=" d-flex align-items-center justify-content-center" style={{
                                        width: '110px',
                                        height: '110px'
                                    }}>
                                        <div className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                                            style={{ width: '100px', height: '100px' }}>
                                            <img src="/FrWork/Avatar/user-1.jpg" alt="" className="w-100 h-100" />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <h5 className="fs-5 mb-0 fw-semibold">Jonathan Deo</h5>
                                    <p className="mb-0 fs-4">Designer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 order-last">
                            <ul
                                className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end my-3 mx-4 pe-4 gap-3">
                                <li className="position-relative">
                                    <a className="d-flex align-items-center justify-content-center text-bg-primary p-2 fs-4 rounded-circle"
                                        href="void(0)" width="30" height="30">
                                        <i className="ti ti-brand-facebook"></i>
                                    </a>
                                </li>
                                <li className="position-relative">
                                    <a className="text-bg-secondary d-flex align-items-center justify-content-center p-2 fs-4 rounded-circle"
                                        href="void(0)">
                                        <i className="ti ti-brand-dribbble"></i>
                                    </a>
                                </li>
                                <li className="position-relative">
                                    <a className="text-bg-danger d-flex align-items-center justify-content-center p-2 fs-4 rounded-circle"
                                        href="void(0)">
                                        <i className="ti ti-brand-youtube"></i>
                                    </a>
                                </li>
                                <li><button className="btn btn-primary">Add To Story</button></li>
                            </ul>
                        </div>
                    </div>
                    <HBarraTab tabs={[
                        { name: "setting", label: "Settings", icon: "ti-user-circle" }
                        , { name: "direcc", label: "Direcciones", icon: "ti-user-circle" }
                        , { name: "telef", label: "Teléfonos", icon: "ti-user-circle" }
                        , { name: "profile", label: "Profile", icon: "ti-user-circle" }
                        , { name: "followers", label: "Seguidores", icon: "ti-user-heart" }
                        , { name: "friends", label: "Amigos", icon: "ti-user-circle" }
                        , { name: "galeria", label: "Galería", icon: "ti-photo-plus" }
                        , { name: "post", label: "Post", icon: "ti-photo-plus" }
                    ]} >
                        <HBarraTabChild id="setting" className=" show active"><ProfileSetting obj={regData} /></HBarraTabChild>
                        <HBarraTabChild id="direcc"><ProfileSetDir regDir={regDir} /></HBarraTabChild>
                        <HBarraTabChild id="profile"><ProfileShow obj={regData} /></HBarraTabChild>
                        <HBarraTabChild id="telef"><ProfileSetCel regCel={regCel} /></HBarraTabChild>
                        <HBarraTabChild id="post"><ProfilePost obj={regData} /></HBarraTabChild>
                        <HBarraTabChild id="followers"><ProfileFollow obj={regData} /></HBarraTabChild>
                        <HBarraTabChild id="friends"><ProfileFriends obj={regData} /></HBarraTabChild>
                        <HBarraTabChild id="galeria"><ProfileGallery obj={regData} /></HBarraTabChild>
                    </HBarraTab>
                </div>
            </div>

        </>
        );
    }

    //    {this.fuBretancrud()}
    fuBretancrud() {
        return (<div className="card bg-info-subtle shadow-none position-relative overflow-hidden mb-4">
            <div className="card-body px-4 py-3">
                <div className="row align-items-center">
                    <div className="col-9">
                        <h4 className="fw-semibold mb-8">User Profile</h4>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a className="text-muted text-decoration-none"
                                        href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item" aria-current="page">User Profile</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-3">
                        <div className="text-center mb-n5">
                            <img src="/FrWork/adModern/assets/images/profile/ChatBc.png" alt="" className="img-fluid mb-n4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

}

export default Profile;
