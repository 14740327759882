import React, { Component } from "react";
import Api from "../ApiTurnos";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ShowLoading from "global/components/ShowLoading";
//import ShowError from "global/components/ShowError";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";

class FDiaMesGen extends HFormUser {

    componentDidMount() { this.readTurnosArea() }
    readTurnosArea = () => {
        this.post(Api.turnosArea)
            .then(data => {
                this.stateHarrys(this, data)
                this.setState({
                    dataArea: (data.obj && data.obj.cbArea) || []
                    , dataMes: (data.obj && data.obj.cbMes) || []
                    , data: data
                    , loading: false
                })
            })
    }
    turnosHora = () => {
        const data = { idTurno: document.getElementById("cbTurno").value }
        this.post(Api.turnosHora, data)
            .then(data => {
                this.setState({
                    dataHora: data.obj.cbHor
                    , dataTar: data.obj.cbTar
                    , data: data
                })
            })
    }
    generarApi = () => {
        if (!document.getElementById("cbHora")) {
            alert("Seleccione Horario")
            return
        }
        if (!document.getElementById("cbTar")) {
            alert("Seleccione Tarifa")
            return
        }
        var data = {
            idKey: this.props.location.state.detKey
            , cbTur: "-99"
            , cbHor: document.getElementById("cbHora").value
            , cbTar: document.getElementById("cbTar").value
            , cbMes: document.getElementById("cbMeses").value
        }
        this.setState({ loading: true})
        this.post(Api.horarioGenerar, data)
            .then(data => {
                this.setState({ data: data })
                if (data.ok) apiRuta.toRutaBack(this)
                this.setState({ loading: false})
            })
    }
    // <ShowError
    // msg={this.state.message}
    // err={this.state.error}/>
    //<ShowError  msg={this.state.data.msg}/>
    render() {
        //console.log("data",this.state.data)
        if (this.state.loading) return (<ShowLoading />)
        return (<>
            <ContainerRightEdit
                tituloContainer={this.state.data && ('Fecha : ' +  (this.props.location.state &&  this.props.location.state.detKey.fecha))}
                contexto={this}
                resObj={this.state.resObj}


                bt1Click={this.generarApi}
                bt1Label="Generar Turnos"
                bt1Icon="fa fa-remove"
                bt1Class="btn-success"

            >
                <HBoxForm>
                    <HBoxSelect
                        label="Turnos"
                        id="cbTurno"
                        onChange={this.turnosHora}
                        value={this.props.location &&  this.props.location.state.detKey.horario.length > 0 && this.props.location.state.detKey.horario[0].idhora}
                        option={this.state.dataArea}
                    />
                    <HBoxSelect
                        label="Horario"
                        id="cbHora"
                        title="Hora Trabajada"
                        value={this.props.location &&  this.props.location.state.detKey.horario.length > 0 && this.props.location.state.detKey.horario[0].idhora}
                        option={this.state.dataHora}
                    />
                    <HBoxSelect
                        label="Tarifa"
                        id="cbTar"
                        option={this.state.dataTar}
                    />
                    <HBoxSelect
                        label="Meses a Generar"
                        id="cbMeses"
                        option={this.state.dataMes}
                    />

                </HBoxForm>

            </ContainerRightEdit>
        </>
        );
    }

}
//<HBoxButtonCrud btActualizarClick={() => this.actualizarApi()}/>

export default WithNavigate(FDiaMesGen);